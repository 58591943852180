<template>
    
  <div class="jh-dialog-wrap">
    <div class="jh-dialog-header">
      <h1>이미지 전송</h1>
      <div class="is-right">
          <v-btn class="jh-btn is-icon" title="닫기" @click="$emit('hide')"><i class="jh-icon-close-lg is-white"></i></v-btn>
      </div>
    </div>
    <div class="jh-dialog-body">
        <div class="jh-ui-header">
            <div class="is-left">
                <v-text-field class="jh-form" style="width: 200px;" name="name" placeholder="제목" v-model="BRD_TIT"></v-text-field>
                <v-btn class="jh-btn is-search"  @click="getImageList">조회</v-btn>
            </div>
            <div class="is-right">
              <label class="jh-label">보기정렬</label>
              <v-radio-group class="jh-radio-group" v-model="CONST_BTNCOUNT_GROUP">
                <v-radio v-for="i in CONST_BTNCOUNT.TO" :key="i"  v-if="i >= CONST_BTNCOUNT.FROM" :label=" `${i}열`" :value="i" @click="changeRow(i)"></v-radio>
              </v-radio-group>
            </div>
        </div>

        <div class="jh-list-image" style="height: 602px;">
            <p class="jh-no-data" :class="historyList.length > 0 ? 'is-hidden': ''"><span>현재 데이터가 없습니다.</span></p>
            <ul :class="SelectAlign" v-if="historyList.length > 0">
              <li v-for="(i, index) in historyList" :class="{'is-active' : index === activeIMG}" :index="index" :key="i" @click="getImgInfo(i, index)">
                  <div class="is-image-title" v-html="i.BRD_TIT"></div>
                  <div class="is-image-date" v-html="i.FST_BRD_DT_D"></div>
                  <img :src="i.src">
              </li>
            </ul>
        </div>
        <div class="jh-pager">
        <v-pagination v-model="page" :length="pages" :total-visible="10" @input="updatePage"></v-pagination>
        <span class="jh-counter is-left">총 <em>{{ historyList.length }}</em> 건</span>
        </div>
    </div>
    <div class="jh-dialog-footer">
      <v-btn class="jh-btn is-md is-main" @click="sendImg" >전송</v-btn>
    </div>
  </div>
  <!-- <div class="modal-wrap">
  <div class="d-flex">
    <div class="flex-grow-1">
      <h3>이미지 전송</h3>
      <div class="list-box mt-3 pa-3 white">
        <div class="box-ct d-flex">
          <div class="">
            <div class="form-inp">
              <v-text-field
                class="form-inp"
                name="name" label="제목" outlined hide-details
                v-model="BRD_TIT">
                <template v-slot:label>
                  제목
                  <v-icon color="#EF9191">mdi-circle-small</v-icon>
                </template>
              </v-text-field>
            </div>
          </div>
          <div class="ml-auto align-self-center">
            <v-btn outlined class="btn-etc2 ml-3"  v-on:click="getImageList">조회</v-btn>
          </div>
        </div>
      <template>
        <table style="display: grid;">
          <div class="image_list">

            <v-card
                class="mx-auto"
            >
              <v-container fluid v-if="historyList.length > 0">
                <v-row
                       v-for=" i in rows"
                       :key="i">
                  <v-col
                      v-for="j in cols"
                      :key="j">
                    <v-card v-if="i == 1 && historyList[j-1] != undefined"
                          v-on:click="getImgInfo(1,cols,j)" :dblclick="sendImg"
                        >
                      <v-card-title v-text="historyList[j-1].BRD_TIT"></v-card-title>
                      <v-img
                          :style="{'background': getFileSrc(1,cols,j), 'background-size': 'cover'}"
                          class="white--text align-end"
                          height="100px"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-title v-text="historyList[j-1].FST_BRD_DT_D"></v-card-title>
                      </v-card-actions>
                    </v-card>
                    <v-card v-if="i == 2 && historyList[cols + j-1] != undefined"
                        v-on:click="getImgInfo(2,cols,j)" :dblclick="sendImg"
                      >
                      <v-card-title v-text="historyList[cols + j-1].BRD_TIT"></v-card-title>
                      <v-img
                          :style="{'background': getFileSrc(2,cols,j), 'background-size': 'cover'}"
                          class="white--text align-end"
                          height="100px"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-title v-text="historyList[cols + j-1].FST_BRD_DT_D"></v-card-title>
                      </v-card-actions>
                    </v-card>
                    <v-card v-if="i == 3 && historyList[cols + cols + j-1] != undefined "
                          v-on:click="getImgInfo(3,cols,j)" :dblclick="sendImg"
                        >
                      <v-card-title v-text="historyList[cols + cols + j-1].BRD_TIT"></v-card-title>
                      <v-img
                          :style="{'background': getFileSrc(3,cols,j), 'background-size': 'cover'}"
                          class="white--text align-end"
                          height="100px"
                      >
                      </v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-card-title v-text="historyList[cols + cols + j-1].FST_BRD_DT_D"></v-card-title>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </div>
        </table>
      </template>
      <div class="grid-paging text-center pt-2 mb-5">
        <v-pagination class="pagination mb-2" v-model="page" :length="pages" @input="updatePage"></v-pagination>
      </div>
        <div class="box-ct d-flex">
          <div class="ml-auto align-self-center">
            <v-btn outlined class="btn-etc2 ml-3"  v-on:click="sendImg">전송</v-btn>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div> -->
</template>

<script>
import api from "@/store/apiUtil.js";
import {mixin} from "@/mixin/mixin.js";

import { stompUtil }  from "@/store/stomp-util.js";
import moment from "moment";

export default {
    name: "MENU_M110102P01", //name은 'MENU_' + 파일명 조합
    mixins    : [mixin], // mixin 등록

  components: {
  },
  props: {
    Imgdata: {
      type: Object,
      value:{}
    },
  },
  data() {
    return {

        SelectAlign: 'is-col-5', //CONST_BTNCOUNT_GROUP의 숫자를 is-col- 다음에 표기
        CONST_BTNCOUNT_GROUP: 5,
        CONST_BTNCOUNT  : {"FROM": 3, "TO": 7}, 
      
      URLData         : '',
      ASP_CUST_KEY    : '',
      ASP_NEWCUST_KEY : '',
      selBexBizV      : [],
      gridListDataText: [],

      ASP_CUST_KEY: '',
      BRD_TIT  : '', // 검색할 제목
      BRD_ID:'4',
      PAGES_CNT: 1, // header 현재 보여지는 페이지 기본 1
      ROW_CNT  : 1000, // 한 페이지에 보여줄 게시글의 수
      pageCount: 1,

      TOT_COUNT: '', // 총 게시글 수

      // as is list data
      FST_BRD_DT  : '',
      PATH_TYPE   : '',
      FILE_KEY    : '',
      FILE_NM     : '',
      FST_BRD_DT_D: '',

      //페이징
      page       : 1,
      pageSize   : 10,
      listCount  : 0,
      historyList: [],
      rows       : 2,

      // css 열 바꿈
      articles: [],
      cols    : 5,

      CLK_IMG:{},
      activeIMG: null,

      FST_BRD_DT  : '',
      PATH_TYPE   : '',
      FILE_KEY    : '',
      FILE_NM     : '',
      FST_BRD_DT_D: '',
      IMAGE_TYPE   : '',
      FILE_EXTS    : '',
      FILE_BLOB    : '',
      FILE_PATH    : '',
      SAVE_FILENAME: '',
      viewBtnGrp   : 2
    };
  },
  computed: {
    pages() {
      let _this = this;
      if (_this.pageSize == null || _this.listCount == null) return 0;
      return Math.ceil(_this.listCount / _this.pageSize);
    },
  },
  created() {
        console.log("here11",this.Imgdata);

      this.ASP_CUST_KEY = this.Imgdata.ASP_NEWCUST_KEY;
    this.getImageList();
  },
  watch: {
    Imgdata(){
        console.log("here11",this.Imgdata);
      this.ASP_CUST_KEY = this.Imgdata.ASP_NEWCUST_KEY;
      this.getImageList();
    }
  },
  mounted() {
  },
  methods: {
    
    //이미지 전송


    async getImageList() { // 조회 버튼 클릭

      this.historyList = [];



      let imageListUrl = "/api/chat/setting/image-manage/list";
      let requestData = {
        headers : {},
        sendData: {}
      };
      // header 세팅
      requestData.headers["URL"] = imageListUrl;
      requestData.headers["SERVICE"] = "chat.setting.image-manage";
      requestData.headers["METHOD"] = "list";
      requestData.headers["PAGES_CNT"] = this.PAGES_CNT;
      requestData.headers["ROW_CNT"] = this.ROW_CNT;
      requestData.headers["ASYNC"] = false;
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      // sendData 세팅
      requestData.sendData = {};
      requestData.sendData["ASP_NEWCUST_KEY"] = "001";
      requestData.sendData["BRD_ID"] = this.BRD_ID;
      console.log("sabkjfsabkjfbaskjgbsakjgbsajkgbnaskjgbsakjgbsakjgbaskjgbkjasbgkjasbgkjsa         " + this.BRD_ID);
      requestData.sendData["BRD_TIT"] = this.BRD_TIT;
      requestData.sendData["PAGES_CNT"] = this.PAGES_CNT;
      requestData.sendData["ROW_CNT"] = this.ROW_CNT;
      requestData.sendData["ASP_USER_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      requestData.sendData["ASP_CUST_KEY"] = "001";

      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if (!response.HEADER.ERROR_FLAG) {
        if(! Object.keys(response).length === 0){
          this.setImageList(response);
        }else{
          this.gridListDataText["BRD_ID"] = this.BRD_ID;
          this.setImageList(response);
        }

      }


      let date = new Date();

      let Y = date.getFullYear();
      let M = date.getMonth();
      let D = date.getDate();
      let H = date.getHours();
      let Min = date.getMinutes();
      let S = date.getSeconds();

      H = H < 10 ? ('0' + H) : H;
      Min = Min < 10 ? ('0' + Min) : Min;
      S = S < 10 ? ('0' + S) : S;

      let data = "시간 : " + Y + ' ' + M + ' ' + D + '  ' + H + ':' + Min + ":" + S + "    |     api : getImageList \n"; 

      this.appendLog(data);
    },
    
    async setImageList(response) {
      let header = response.HEADER;
      // let data = response.DATA;
      let data = await this.mixin_imageView('chat', 'images', response.DATA);
      console.log("data");

      console.log(data);
      console.log("data");
      // this.gridListDataText = response.DATA;
      this.articles = Object.assign([], data);

      //페이징
      this.initPage();

    },
    getImgInfo(i, index){
      // console.log("this.historyList      ", this.historyList[i]);
      let strImageUrl = "";
      this.CLK_IMG = i;
      this.activeIMG = index;
      console.log("this.historyList      ", this.CLK_IMG);
    },

    sendImg(){
      
      let IMG_INFO = {message_type : 'IM', SNDRCV_CD: 'SND', type : 'image'
                , image : this.CLK_IMG.FILE_PATH + "/" + this.CLK_IMG.PATH_TYPE + "/" + this.CLK_IMG.SAVE_FILENAME
                , msg : this.CLK_IMG.FILE_PATH + "/" + this.CLK_IMG.PATH_TYPE + "/" + this.CLK_IMG.SAVE_FILENAME
                , CONTENT : this.CLK_IMG.FILE_PATH + "/" + this.CLK_IMG.PATH_TYPE + "/" + this.CLK_IMG.SAVE_FILENAME
                , IMAGE_TALK_PATH : this.CLK_IMG.FILE_PATH + "/" + this.CLK_IMG.PATH_TYPE + "/" + this.CLK_IMG.SAVE_FILENAME
                , FILE_GROUP_KEY : this.CLK_IMG.FILE_GROUP_KEY
                , FILE_KEY : this.CLK_IMG.FILE_KEY
              };

      let CHAT_INFO = {'talk_contact_id' : this.Imgdata.CLK_CHAT
                      , 'talk_sender_key' : this.Imgdata.CLK_SENDER
                      , 'call_typ_cd' : 'TTALK'
                      , 'aspNewCustKey' : this.Imgdata.ASP_NEWCUST_KEY
                      , 'talk_user_key': this.Imgdata.CLK_USER
                      , 'SEND_USER_NM' : this.Imgdata.USER_NM
                      , 'token' : this.$store.state.messages.token
                      , 'userId' : this.$store.getters['userStore/GE_USER_ROLE'].userId
                      };

      let chatmsg = this.$store.getters['messages/GE_MSG_OBJ']({
                    user_key: CHAT_INFO.talk_user_key
                    , sndrcv_cd: 'snd'
                    , type: IMG_INFO.message_type
                    , content: IMG_INFO.msg
                    , talk_contact_id: CHAT_INFO.talk_contact_id
                    , IMAGE_TALK_PATH : IMG_INFO.IMAGE_TALK_PATH != '' ? IMG_INFO.IMAGE_TALK_PATH : ''
                    , FILE_GROUP_KEY : IMG_INFO.FILE_GROUP_KEY != '' ? IMG_INFO.FILE_GROUP_KEY : ''
                    , FILE_KEY : IMG_INFO.FILE_KEY != '' ? IMG_INFO.FILE_KEY : ''
                    , FILE_BLOB : IMG_INFO.FILE_BLOB != '' ? IMG_INFO.FILE_BLOB : ''
                    , FILE_EXTS : IMG_INFO.FILE_EXTS != '' ? IMG_INFO.FILE_EXTS : ''
                    //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
                    , SEND_USER_NM : CHAT_INFO.SEND_USER_NM
                    , SEND_USER_SHORT_NM : '나'
                    , consultEnd : IMG_INFO.consultEnd
                    , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                });

      let msgObj = this.$store.getters['messages/GE_CONTACT_MSG']({ talk_contact_id: CHAT_INFO.talk_contact_id });
      
      
      console.log("this.IMG_INFO      ", IMG_INFO);
      console.log("this.CHAT_INFO      ", CHAT_INFO);
      console.log("this.chatmsg      ", chatmsg);
      console.log("this.msgObj      ", msgObj);

      stompUtil.sendMessage(IMG_INFO, CHAT_INFO, chatmsg, msgObj);
      this.$emit("selImg", "getTalkInfo");
      this.$emit('hide');
    },
  
    // 3, 4, 5, 6, 7열 클릭 시 보여주는 게시글 조절
    changeRow(cols) {
      //histroyList =  한 페이지에 들어가는 articles
      this.cols = cols; //3
      this.pageSize = cols < 6 ? cols * 2 : cols * 3

      this.historyList = this.articles.slice(0, this.pageSize);
      //row 구하기
      let totalLength = this.historyList.length; //6
      if (totalLength % cols > 0) {
        this.rows = Math.ceil(totalLength / cols);
      } else {
        this.rows = totalLength / cols;
      }

      //페이징
      this.initPage();

      //보기정렬 세팅
      this.SelectAlign = 'is-col-'+cols;

    },
    initPage() {
      let _this = this;
      _this.page = 1;
      _this.listCount = _this.articles.length;
      if (_this.listCount < _this.pageSize) {
        _this.historyList = _this.articles;
      } else {
        _this.historyList = _this.articles.slice(0, _this.pageSize);
      }

    },
    
    updatePage(pageIndex) {
      let _this = this;
      let _start = (pageIndex - 1) * _this.pageSize;
      let _end = pageIndex * _this.pageSize;
      _this.historyList = _this.articles.slice(_start, _end);
      _this.page = pageIndex;
    },
  },

 
};
</script>

<style></style>
