import axios from "axios";

import Vue      from 'vue';

export const apiChatPost = new Vue({
    methods: {
        // chatPost(url, params){

        //     Vue.prototype.$http({method:'post',
        //     url : url,
        //     data : JSON.stringify(params),
        //     timeout : 30000,
        //     headers:{"Content-Type":"application/json", "Accept":"application/json"}}).then(ret=>{
        //         console.log("response",response);
        //         return response;
        //     }).catch(function(error){
        //         console.log("response",error);
        //         return error;
        //     });
        // }



        chatPost(url, params){
            console.log("############################################");
            console.log("*url:"+url);
            let data = {
                "url": url,
                "method": "POST",
                "timeout": 0,
                "contentType": "application/json",
                "Accept": "application/json",
                "data": JSON.stringify(params),
            };
            $.ajax(data).done(function (err, response) {
                if (response){
                    console.log("response",response);
                    return response;
                } else {
                    return err;
                }
            });
        }

        // ---------------------------------------------------------------------------------


        // chatPost(url, params){
        //     console.log("############################################");
        //     console.log("*url:"+url);
        //     console.log("*JSON.stringify(params):"+JSON.stringify(params));
        //     let data = {
        //         url: url,
        //         method: "POST",
        //         type: "POST",
        //         async:true,
        //         timeout: 60000,
        //         contentType: "application/json",
        //         dataType: "json",
        //         Accept: "application/json",
        //         // "data": JSON.stringify(params),
        //         data: JSON.stringify(params),
        //     };
        //     $.ajax(data).done(function (err, response) {
        //         if (response){
        //             console.log("response",response);
        //             return response;
        //         } else {
        //             return err;
        //         }
        //     });
        // }
        // ---------------------------------------------------------------------------------

        
    }

})