<template>
  <div class="jh-container is-chat-main">
    <!--채팅목록-->
    <div class="jh-card">
      <div class="jh-card-header">
        <div class="is-left">
          <v-btn class="jh-btn is-icon" @click="getTalkList"><i class="jh-icon-refresh"></i></v-btn>
          <v-btn class="jh-btn is-icon" @click="chatListSortFilter = !chatListSortFilter"><i class="jh-icon-filter"></i></v-btn>
          <!-- <input type="text" class="jh-form is-prma" :style="screenStyle1" :value="this.screenValue" disabled> -->
          <!-- <td class="is-txt-center" :style="screenStyle1"><span class="is-value is-bold">{{screenValue}}</span></td> -->


        </div>
        <div class="is-right">
          <v-btn class="jh-btn is-sm is-round is-main" :disabled="check_record == false" @click="recordOff">녹화 종료</v-btn>
          <label class="jh-label-rec" :class="screenCss">{{screenValue}}</label>
          <v-select
            class="jh-form is-round"
            style="width:125px"
            v-model="USER_NEWSTAT"
            :items="USER_STAT"
            item-text="CD_NM"
            item-value="CD"
            placeholder="선택하세요"
            hide-details
            v-on:change="changeUserStat(true)"
          ></v-select>
        </div>
      </div>
      <div class="jh-card-body is-p-0">
        <v-expand-transition>
          <div class="is-chat-filter" v-show="chatListSortFilter">
            <v-btn-toggle mandatory class="jh-btn-group">
                <v-btn class="jh-btn has-icon" v-on:click="sortClick('0')"><span>상담시간순</span><v-icon x-small>{{sortIcon_0}}</v-icon></v-btn>
                <v-btn class="jh-btn has-icon" v-on:click="sortClick('1')"><span>최근대화순</span><v-icon x-small>{{sortIcon_1}}</v-icon></v-btn>
            </v-btn-toggle>
          </div>
        </v-expand-transition>
        <div class="jh-chat-list">
          <div class="jh-no-data" :class="[noData ? '' : 'is-hidden']">현재 데이터가 없습니다.</div>
          <ul>
            <li v-show="chatListFilter.includes(item.type)" :class="{'is-active' : activeIndex === index }" v-for="(item, index) in chatList" @click.stop="selectCustID(index)">
              <div class="is-left">
                <i class="jh-icon-channel"></i>
              </div>
              <div class="is-center">
                <span class="is-key"  v-if="item.CUSTOMER_NM != ''">{{item.CUSTOMER_NM}}</span>
                <!-- <span class="is-key"  v-if="item.TOT_IS_READ != ''">{{item.TOT_IS_READ}}</span> -->
                <span class="is-key"  v-if="item.CUSTOMER_NM == ''">{{item.TALK_USER_KEY}}</span>
                <!-- <span class="is-ready-time">00:00:00</span> -->
                <span class="is-time">
                  {{ item.TALK_DIST_DT }}
                </span>
                <div class="is-text">{{item.TALK_TITLE}}</div>
              </div>
              <div class="is-right">                
                <label class="is-chat-state" :class="item.stateClass">{{item.TALK_STAT_NM}}</label>
                <!-- <span class="is-time">{{ item.TALK_DIST_DT }}</span> -->
                <span class="is-ready-time"><span :class="item.readClass" v-if="item.TOT_IS_READ != 0">{{item.TOT_IS_READ}}</span>{{item.LAST_REG_DTTM}}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="jh-card-footer is-chat-btn">
        <!-- <v-btn-toggle v-model="toggle_csCtBtn" >
          <v-btn class="cs-ct-btn" @click="FnChatListAll">전체 <span class="cs-cti-btn--cnt">{{ chatList.length }}</span></v-btn>
          <v-btn class="cs-ct-btn" @click="FnChatListIng">상담 <span class="cs-cti-btn--cnt">{{ chatListIng.length }}</span></v-btn>
          <v-btn class="cs-ct-btn" @click="FnChatListDone">종료 <span class="cs-cti-btn--cnt">{{ chatListDone.length }}</span></v-btn>
          <v-btn class="cs-ct-btn" @click="FnChatListAfter">후처리 <span class="cs-cti-btn--cnt">{{ chatListAfter.length }}</span></v-btn>
          <v-btn class="cs-ct-btn" @click="FnChatListWait">상담대기 <span class="cs-cti-btn--cnt">{{ chatListWait.length }}</span></v-btn>
        </v-btn-toggle> -->
        <v-btn type="button" class="jh-chat-list-btn"
            v-for="item in getProcessInfo"
            :key="item.id"
            :class="{'is-active': item.isActive}"
            @click="FnChatListClick(item)">
            <span>{{ item.tab }}</span>
            <span class="is-value">{{ item.badge }}</span>
        </v-btn>
      </div>
    </div>
    <!--//채팅목록-->

    <!--채팅상담-->
    <div class="jh-card is-cnsl">
      <div class="jh-card-header">
        <span class="jh-badge is-blueGray">문의유형</span>
        <v-tooltip content-class="jh-tooltip" bottonm nudge-bottom="150" nudge-right="0" max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <p class="is-text" v-bind="attrs" v-on="on">{{showInqryTyp()}}</p>
          </template>
          <span>{{showInqryTyp()}}</span>
        </v-tooltip>
        
        <div class="is-right"><v-btn class="jh-btn is-icon" @click="getTalkInfo"><i class="jh-icon-refresh"></i></v-btn></div>
      </div>
      <div class="jh-card-body is-p-0">
        <table class="jh-tbl-detail is-chat-info">
          <colgroup>
            <col width="75px">
            <col>
            <col width="75px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th><label>인입채널</label></th>
              <td ><span class="is-value">{{IN_CODE}}</span></td>
              <th><label>시나리오</label></th>
              <td ><span class="is-value">{{BOT_INQRY_CD}}</span></td>              
            </tr>
            <tr>
              <th><label>로그인정보</label></th>
              <td><span class="is-value">{{LOGIN_TYP}}</span></td>
              <th><label>휴대전화</label></th>
              <td><span class="is-value">{{USER_PH_NUM}}</span></td>
            </tr>
          </tbody>
          <!-- <tbody>
            <tr>
              <th><label>인입채널</label></th>
              <td ><span class="is-value">{{IN_CODE}}</span></td>
              <th><label>로그인정보</label></th>
              <td><span class="is-value">{{LOGIN_TYP}}</span></td>
              <th><label>휴대전화</label></th>
              <td><span class="is-value">{{USER_PH_NUM}}</span></td>
            </tr>
          </tbody> -->
        </table>

        <div class="jh-mssg-list-area" id="scroll-target" v-scroll:#scroll-target="onScroll">
          <div class="is-mssg-divider" v-if="historyDate.length > 0">{{historyDate}}</div>
          <div class="jh-mssg-list">
            <template v-for="(item,index) in talkHistory">
              <div :class="item.SNDRCV_CD == 'RCV' ? 'from-left' : 'from-right' " :key="index">
                <div class="is-name" v-if="item.SNDRCV_CD == 'SND'">{{item.SEND_USER_NM}}</div>
                <div class="is-img" v-if="item.TYPE=='IM'">
                  <img v-auth-image="getImageSrc(item)" @click="setViewAllImg(item)"></img>
                </div>
                  <!-- <img v-if="item.TYPE=='IM'" v-auth-image="getImageSrc(item)" @click="setViewAllImg(item)"></img> -->
                <div class="is-mssg" v-if="item.TYPE!='photo' && item.TYPE!='IM'">{{ item.CONTENT }}
                  <!-- <div class="is-view-all" v-if="message.attachment != null && message.attachment != ''" @click="setViewAllMsg(message.org_cont_id)">전체보기<i class="jh-icon-angle-right-xs"></i></div> -->
                </div>
                <div>
                  <v-btn v-if="item.TYPE != 'remove_message' && item.TYPE != 'expired_session' && item.TALK_STAT_CD == '12'" class="jh-btn is-icon" @click="deleteMsg(index, item.TALK_SERIAL_NUMBER)"><i class="jh-icon-close-xs"></i></v-btn>
                  <span class="is-mssg-time" :key="index">{{item.CHAT_DATE}} <button type="button" class="is-ml-5" v-if="item.SNDRCV_CD == 'SND' && item.RESEND == true"  @click="reSendMsg(index, item.TALK_SERIAL_NUMBER)"><i class="jh-icon-refresh is-blue"></i></button></span>
                </div>
              </div>
            </template>
          </div>
          <div class="is-mssg-divider" v-if="chatDone">상담이 종료되었습니다. 상담이력을 저장해 주시기 바랍니다.</div>
          
          <div id="scrollMe"></div>          
          <v-btn class="jh-btn is-to-bottom" v-if="ToBottomButton" @click="toBottomClick"><i class="jh-icon-arrow-down-lg"></i></v-btn>
        </div>

        
        <div class="jh-mssg-control" v-show="!CnslSave">
          <v-btn class="jh-btn" :disabled="END_CNSL == false" @click="clickTemp">임시저장</v-btn>
          <v-btn class="jh-btn is-fill" :disabled="END_CNSL == false" @click="showAlertEndTalk">상담종료</v-btn>
        </div>

        <div class="jh-mssg-frame" v-show="!CnslSave">
          <div class="jh-mssg-form" style="max-height: 230px;">
            <v-textarea 
              class="jh-form is-trns" 
              noResize 
              auto-grow 
              rows="3" 
              row-height="25" 
              placeholder="상담내용을 입력하세요." 
              v-model.trim="CHAT_MSG" 
              v-on:keyup.enter.exact="sendMsg"
              v-on:keyup.ctrl="findCommand('ctrl', $event)"
              v-on:keyup.alt="findCommand('alt', $event)"
              v-on:keyup.shift="findCommand('shift', $event)"
            >
            </v-textarea>
            <div class="jh-txt-counter is-txt-right"><strong :class="chatMsgtLen > 1000 ? 'is-txt-red': ''">{{chatMsgtLen}}</strong> / 1000 byte</div>

          </div>
          <div class="jh-mssg-form-footer">
            <div class="is-left">
              <v-btn class="jh-btn is-icon" title="이미지선택" @click="showDialog('P101101')"><i class="jh-icon-chat-image"></i></v-btn>
              <v-btn class="jh-btn is-icon" :title="[mssgStop ? '메시지재개' : '메시지중단']" @click="SystemMsgSkip"><i :class="[mssgStop ? 'jh-icon-chat-play' : 'jh-icon-chat-stop']"></i></v-btn>
              <v-btn class="jh-btn is-icon" title="종료메시지전송" @click="sendEndLink"><i class="jh-icon-chat-complete"></i></v-btn>
              <v-btn class="jh-btn is-icon" title="상담전달" @click="clickTransfer"><i class="jh-icon-chat-transfer"></i></v-btn>
            </div>
            <div class="is-right"><v-btn class="jh-btn is-main" @click="sendMsg">전송</v-btn></div>
          </div>
        </div>

        <!--임시저장-->
        <div class="is-detail-form" :class="[chatTemp ? 'is-active' : '']">
          <div class="jh-ui-header">
            <h2>임시저장</h2>
          </div>
            <div class="jh-cols jh-align-middle is-space-5 is-mb-5">
              <label class="jh-label">MY메뉴</label>
              <v-select class="jh-form"  v-model="myMenu" :title="myMenu" :items="repeatList" @change="changeCallType" ></v-select>
              <v-tooltip content-class="jh-tooltip" v-if="repeatList.length > 0" bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn class="jh-btn is-sm is-main is-num" v-bind="attrs" v-on="on" @click="setCnslTyCd(0)">1</v-btn>
                  </template>
                  <span v-if="repeatList.length > 0">{{repeatList[0].text}}</span>
              </v-tooltip>
              <v-tooltip content-class="jh-tooltip" v-if="repeatList.length > 1" bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn class="jh-btn is-sm is-main is-num" v-bind="attrs" v-on="on" @click="setCnslTyCd(1)">2</v-btn>
                  </template>
                  <span v-if="repeatList.length > 1">{{repeatList[1].text}}</span>
              </v-tooltip>
              <v-tooltip content-class="jh-tooltip" v-if="repeatList.length > 2" bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn class="jh-btn is-sm is-main is-num" v-bind="attrs" v-on="on" @click="setCnslTyCd(2)">3</v-btn>
                  </template>
                  <span v-if="repeatList.length > 2">{{repeatList[2].text}}</span>
              </v-tooltip>
              <v-tooltip content-class="jh-tooltip" v-if="repeatList.length > 3" bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn class="jh-btn is-sm is-main is-num" v-bind="attrs" v-on="on" @click="setCnslTyCd(3)">4</v-btn>
                  </template>
                  <span v-if="repeatList.length > 3">{{repeatList[3].text}}</span>
              </v-tooltip>
              <v-tooltip content-class="jh-tooltip" v-if="repeatList.length > 4" bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn class="jh-btn is-sm is-main is-num" v-bind="attrs" v-on="on" @click="setCnslTyCd(4)">5</v-btn>
                  </template>
                  <span v-if="repeatList.length > 4">{{repeatList[4].text}}</span>
              </v-tooltip>
              <v-btn class="jh-btn has-icon-only is-main" @click="openPopup('P100602')"><i class="jh-icon-setting is-blue"></i></v-btn>
          </div>

          <table class="jh-tbl-detail">
            <colgroup>
              <col width="80px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                  <th><label class="is-required">상담결과</label></th>
                  <td><v-select class="jh-form"  :items="CHT_RESULT" item-value="CD" item-text="CD_NM" v-model="CHT_NEWRESULT" ></v-select></td>
                  <!-- <td><v-select class="jh-form"  :items="dropItemsResult" v-model="CHT_NEWRESULT" item-text="title"></v-select></td> -->
                  <!-- <td><span class="is-value">{{CHT_NEWRESULT}}</span></td> -->
              </tr>
              <tr>
                  <th><label class="is-required">상담유형</label></th>
                  <td> 
                      <div class="jh-cols">
                        <v-select class="jh-form" placeholder="상담유형1" required
                        :items="CNSL_TY1" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY1" v-on:change="changeMdfyCouselTyeKey('001', CNSL_NEWTY1, '1', false)"></v-select>
                        <v-select class="jh-form" placeholder="상담유형2" required
                        :items="CNSL_TY2" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY2" v-on:change="changeMdfyCouselTyeKey('001', CNSL_NEWTY2, '2', false)"></v-select>
                      </div>
                      <div class="jh-cols">
                        <v-select class="jh-form" placeholder="상담유형3" required
                        :items="CNSL_TY3" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY3"></v-select>
                        <!-- <v-select class="jh-form" placeholder="상담유형4"
                        :items="CNSL_TY4" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY4" v-on:change="chnCnsl4()"></v-select> -->
                      </div>
                  </td>
              </tr>
              <!-- <tr>
                  <th><label class="is-required">상담제목</label></th>
                  <td><v-text-field class="jh-form" placeholder="상담제목" name="name"  id="id" v-model="CHT_TIT"></v-text-field></td>
              </tr> -->
              <tr>
                  <th><label class="is-required">상담내용</label></th>
                  <td><v-textarea class="jh-form" noResize rows="5" placeholder="상담내용을 입력하세요." v-model="CHT_CON"></v-textarea></td>
              </tr>
            </tbody>
          </table>
          <div class="jh-btn-area">
            <v-btn class="jh-btn" @click="clickTempCancel">취소</v-btn>
            <v-btn class="jh-btn is-main" @click="confirmSave(CHT_NEWRESULT,'상담을 임시저장하시겠습니까?','temp');">저장</v-btn>
          </div>
        </div>
        <!--//임시저장-->

        <!--상담종료-->
        <div class="is-detail-form" :class="[chatDone ? 'is-active' : '']">
          <div class="jh-ui-header">
            <h2>상담종료</h2>
          </div>
          <div class="jh-cols jh-align-middle is-space-5 is-mb-5">
              <label class="jh-label">MY메뉴</label>
              <v-select class="jh-form"  v-model="myMenu" :title="myMenu" :items="repeatList" @change="changeCallType" ></v-select>
              <v-tooltip content-class="jh-tooltip" v-if="repeatList.length > 0" bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn class="jh-btn is-sm is-main is-num" v-bind="attrs" v-on="on" @click="setCnslTyCd(0)">1</v-btn>
                  </template>
                  <span v-if="repeatList.length > 0">{{repeatList[0].text}}</span>
              </v-tooltip>
              <v-tooltip content-class="jh-tooltip" v-if="repeatList.length > 1" bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn class="jh-btn is-sm is-main is-num" v-bind="attrs" v-on="on" @click="setCnslTyCd(1)">2</v-btn>
                  </template>
                  <span v-if="repeatList.length > 1">{{repeatList[1].text}}</span>
              </v-tooltip>
              <v-tooltip content-class="jh-tooltip" v-if="repeatList.length > 2" bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn class="jh-btn is-sm is-main is-num" v-bind="attrs" v-on="on" @click="setCnslTyCd(2)">3</v-btn>
                  </template>
                  <span v-if="repeatList.length > 2">{{repeatList[2].text}}</span>
              </v-tooltip>
              <v-tooltip content-class="jh-tooltip" v-if="repeatList.length > 3" bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn class="jh-btn is-sm is-main is-num" v-bind="attrs" v-on="on" @click="setCnslTyCd(3)">4</v-btn>
                  </template>
                  <span v-if="repeatList.length > 3">{{repeatList[3].text}}</span>
              </v-tooltip>
              <v-tooltip content-class="jh-tooltip" v-if="repeatList.length > 4" bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn class="jh-btn is-sm is-main is-num" v-bind="attrs" v-on="on" @click="setCnslTyCd(4)">5</v-btn>
                  </template>
                  <span v-if="repeatList.length > 4">{{repeatList[4].text}}</span>
              </v-tooltip>
              <v-btn class="jh-btn has-icon-only is-main" @click="openPopup('P100602')"><i class="jh-icon-setting is-blue"></i></v-btn>
          </div>

          <table class="jh-tbl-detail">
            <colgroup>
              <col width="80px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                  <th><label class="is-required">상담결과</label></th>
                  <td><v-select class="jh-form"  :items="CHT_RESULT" item-value="CD" item-text="CD_NM" v-model="CHT_NEWRESULT" ></v-select></td>
                  <!-- <td><span class="is-value">{{CHT_NEWRESULT}}</span></td> -->
              </tr>
              <!-- <tr>
                  <th><label class="is-required">상담유형</label></th>
                  <td> 
                      <div class="jh-cols">
                        <v-select class="jh-form" placeholder="상담유형1" required
                        :items="CNSL_TY1" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY1" v-on:change="chnCnsl1()"></v-select>
                        <v-select class="jh-form" placeholder="상담유형2" required
                        :items="CNSL_TY2" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY2" v-on:change="chnCnsl2()"></v-select>
                      </div>
                      <div class="jh-cols">
                        <v-select class="jh-form" placeholder="상담유형3" required
                        :items="CNSL_TY3" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY3" v-on:change="chnCnsl3()"></v-select>
                      </div>
                  </td>
              </tr> -->
              <tr>
                  <th><label class="is-required">상담유형</label></th>
                  <td> 
                      <div class="jh-cols">
                        <v-select class="jh-form" placeholder="상담유형1" required
                        :items="CNSL_TY1" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY1" v-on:change="changeMdfyCouselTyeKey('001', CNSL_NEWTY1, '1', false)"></v-select>
                        <v-select class="jh-form" placeholder="상담유형2" required
                        :items="CNSL_TY2" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY2" v-on:change="changeMdfyCouselTyeKey('001', CNSL_NEWTY2, '2', false)"></v-select>
                      </div>
                      <div class="jh-cols">
                        <v-select class="jh-form" placeholder="상담유형3" required
                        :items="CNSL_TY3" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY3"></v-select>
                        <!-- <v-select class="jh-form" placeholder="상담유형4"
                        :items="CNSL_TY4" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY4" v-on:change="chnCnsl4()"></v-select> -->
                      </div>
                  </td>
              </tr>              
              <!-- <tr>
                  <th><label class="is-required">상담제목</label></th>
                  <td><v-text-field class="jh-form" placeholder="상담제목" name="name"  id="id" v-model="CHT_TIT"></v-text-field></td>
              </tr> -->
              <tr>
                  <th><label class="is-required">상담내용</label></th>
                  <td><v-textarea class="jh-form" noResize rows="5" placeholder="상담내용을 입력하세요." v-model="CHT_CON"></v-textarea></td>
              </tr>
            </tbody>
          </table>
          <div class="jh-btn-area">
            <!-- <v-btn class="jh-btn" @click="clickDoneCancel">취소</v-btn> -->
            <v-btn class="jh-btn is-main" @click="confirmSave(CHT_NEWRESULT,'상담이력을 저장하시겠습니까?')">저장</v-btn>
          </div>
        </div>
        <!--//상담종료-->

        <!--상담전달-->
        <div class="is-detail-form" :class="[chatTrans ? 'is-active' : '']">
          <div class="jh-ui-header">
            <h2>상담전달</h2>
          </div>
          <table class="jh-tbl-detail">
            <colgroup>
              <col width="80px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                  <th><label class="is-required">상담사선택</label></th>
                  <td>
                    <!-- <v-select
                      v-model="USER_NEWID"
                      :items="USER_ID"
                      no-data-text="일치하는 데이터가 없습니다."
                      item-text="USER_ID"
                      item-value="USER_ID"
                    >
                      <template v-slot:label>
                        상담사선택
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select> -->
                    <v-autocomplete
                      class="jh-form"
                      v-model="USER_NEWID"
                      :items="USER_ID"
                      no-data-text="일치하는 데이터가 없습니다."
                      item-text="USER_ID"
                      item-value="USER_ID"
                      placeholder="상담사선택"
                    >
                      <template v-slot:selection="data">
                        <span class="auto-value">{{ data.item.USER_ID }}</span>
                        <span class="auto-value">{{ data.item.USER_NICK }}</span>
                        <span class="auto-group">{{ data.item.ATTR_DIV_NM_A }}</span>
                      </template>
                      <template v-slot:item="data">
                        <template>
                        <span class="auto-value">{{ data.item.USER_ID }}</span>
                        <span class="auto-value">{{ data.item.USER_NICK }}</span>
                        <span class="auto-group">{{ data.item.ATTR_DIV_NM_A }}</span>
                        </template>
                      </template>
                    </v-autocomplete>
                  </td>
              </tr>
              <!-- <tr>
                  <th><label class="is-required">상담유형</label></th>
                  <td> 
                      <div class="jh-cols">
                        <v-select class="jh-form" placeholder="상담유형1"
                        :items="CNSL_TY1" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY1" v-on:change="chnCnsl1()"></v-select>
                        <v-select class="jh-form" placeholder="상담유형2"
                        :items="CNSL_TY2" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY2" v-on:change="chnCnsl2()"></v-select>
                      </div>
                      <div class="jh-cols">
                        <v-select class="jh-form" placeholder="상담유형3"
                        :items="CNSL_TY3" item-value="CD" item-text="CD_NM" v-model="CNSL_NEWTY3" v-on:change="chnCnsl3()"></v-select>

                      </div>
                  </td>
              </tr> -->
              <!-- <tr>
                  <th><label class="is-required">상담제목</label></th>
                  <td><v-text-field class="jh-form" placeholder="상담제목" name="name"  id="id" v-model="CHT_TIT"></v-text-field></td>
              </tr> -->
              <!-- <tr>
                  <th><label class="is-required">상담내용</label></th>
                  <td><v-textarea class="jh-form" noResize rows="5" placeholder="상담내용을 입력하세요." v-model="CHT_CON"></v-textarea></td>
              </tr> -->
            </tbody>
          </table>
          <div class="jh-btn-area">
            <v-btn class="jh-btn" @click="clickTransferCancel">취소</v-btn>
            <v-btn class="jh-btn is-main" @click="confirmTrans">전달</v-btn>
          </div>
        </div>
        <!--//상담전달-->
      </div>
    </div>
    <!--//채팅상담-->

    <div class="is-vrt-2">
      <!--고객정보-->
      <div class="jh-card is-mb-10" style="height: 277px;min-height: 277px;" v-if="custInfo">
        <div class="jh-card-header has-tab">
          <v-tabs v-model="tab1" slider-size="4">
            <v-tab key="tab1_1">고객정보</v-tab>
          </v-tabs>
          <p class="jh-tab-button is-right">
            <v-checkbox class="jh-check is-mr-15" v-model="CHK_BL" label="주의고객"></v-checkbox>
            <v-btn class="jh-btn is-main" @click="showAlertReg">저장</v-btn>
          </p>
        </div>
        <div class="jh-card-body is-p-5">
          <table class="jh-tbl-detail">
            <colgroup>
              <col width="65px">
              <col>
              <col width="65px">
              <col width="140px">
            </colgroup>
            <tbody>
              <tr>
                  <th><label>고객성향</label></th>
                  <td class="is-txt-center"><span class="is-value is-bold"></span></td>
                  <th><label>문제행동</label></th>
                  <td>
                    <!--콜상담메인과 동일하게 처리해주세요-->
                    <!-- <input type="text" class="jh-form is-prma" style="background-color: white; color: #fff;" value="국내" disabled>
                    <input type="text" class="jh-form is-prma" style="background-color: black; color: #fff" value="해주" disabled>
                    <input type="text" class="jh-form is-prma" style="backgroundColor: yellow; color: #000" value="해선" disabled> -->
                    <input type="text" v-show="prbmStyle === true" class="jh-form is-prma" :style="prbmStyle1" value="국내" disabled>
                    <input type="text" v-show="prbmStyle === true" class="jh-form is-prma" :style="prbmStyle2" value="해주" disabled>
                    <input type="text" v-show="prbmStyle === true" class="jh-form is-prma" :style="prbmStyle3" value="해선" disabled>
                  </td>
              </tr>
            </tbody>
          </table>
          <table class="jh-tbl-detail is-mt-5">
            <colgroup>
              <col width="65px">
              <col>
              <col width="65px">
              <col width="140px">
            </colgroup>
            <tbody>
              <tr>
                  <th><label>계좌번호</label></th>
                  <td>
                    <div class="jh-align-middle jh-align-space">
                      <!-- <v-select class="jh-form" :items="items"></v-select> -->
                      <!-- <v-select 
                        :class="[acn_style_check ? 'jh-acn-form' : 'jh-form']"</v-select> -->

                      <!-- <v-select 
                        class='jh-form'
                        :class="[acn_style_check ? 'is-txt-red' : '']"
                        v-model="selectNewAcntNo" 
                        :items="AcntPrdtDtlNmAddAcntNo"
                        item-text="CD_NM" 
                        item-value="CD"
                        no-data-text="인증필요"     
                        :style="accnStyle"                     
                        v-on:change="sintongSearch()"
                      >
                      </v-select> -->
                      <v-select 
                        class='jh-form'
                        :class="[acn_style_check ? 'is-txt-red' : '']"
                        v-model="selectNewAcntNo" 
                        :items="AcntPrdtDtlNmAddAcntNo"
                        item-text="CD_NM" 
                        item-value="CD"
                        no-data-text="인증필요"     
                        :style="accnStyle"                     
                        v-on:change="sintongSearch()"
                        v-on:click="sintongSearch()"
                      >
                      <template #item="{item}">
                        <span :style="{color:item.color}">{{item.CD_NM}}</span>
                      </template>
                      </v-select>
                    </div>                    
                  </td>
                  <th><label>고객명</label></th>
                  <td><span class="is-value is-bold" style="font-size: 14px;">{{USER_NM}}</span></td>
              </tr>
              <tr>
                  <th rowspan="2"><label>주민번호</label></th>
                  <td class="is-bg-light-red">                    
                    <div class="jh-align-middle jh-align-space">
                      <span class="is-value">{{TEMP_SRCH_CL_CID}}</span>
                    </div>                    
                  </td>
                  <th><label>계좌비번</label></th>
                  <!-- <td class="is-bg-light-yellow">                     -->
                  <td :class="acntClassNm">                    
                    <div class="jh-align-middle jh-align-space">
                      <span  :style="accnPwStyle" class="is-value" >{{PW_YN}}</span>
                    </div>                    
                  </td>
              </tr>
              <tr>
                  <td>
                    <div class="jh-cols is-space-0">
                        <span class="is-value is-bold">{{AGE}}</span>
                        <span class="is-value is-bold">{{SEX}}</span>
                        <span class="is-value is-bold is-txt-red">{{isMinor}}</span>
                        <span class="is-value is-bold is-txt-green">{{isForeigner}}</span>
                    </div>
                  </td>
                  <th><label>휴대전화</label></th>
                  <td><span class="is-value">{{MOVE_TNO}}</span></td>
              </tr>
              <tr>
                  <th><label>고객번호</label></th>
                  <td><span class="is-value">{{CLNT_NO}}</span></td>
                  <th><label>고객등급</label></th>
                  <td><span class="is-value">{{CUS_LEVEL}}</span></td>
              </tr>
              <tr>
                  <th><label>고객메모</label></th>
                  <!-- <td colspan="3"><v-textarea class="jh-form" noResize rows="3" placeholder="메모내용을 입력하세요." v-model="USER_MEMO">{{USER_MEMO}}</v-textarea></td> -->
                  <td colspan="3"><v-textarea class="jh-form" noResize rows="3" placeholder="메모내용을 입력하세요." v-model="USER_MEMO"></v-textarea></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--//고객정보-->

      <!--스크립트/링크-->
      <div class="jh-card is-auto" >        
        <div class="jh-card-header has-tab" >
          <v-tabs v-model="tab2" slider-size="4" >
            <v-tab key="tab2_1">스크립트</v-tab>
            <v-tab key="tab2_2">링크</v-tab>
          </v-tabs>
          <p class="jh-tab-button is-right">
            <v-btn class="jh-btn is-icon" :title="[custInfo ? '확대' : '축소']" @click="custInfo=!custInfo"><i :class="[custInfo ? 'jh-icon-transfer-up' : 'jh-icon-transfer-down']"></i></v-btn>
          </p>
        </div>
        <div class="jh-card-body is-p-5" >
          <v-tabs-items v-model="tab2">
            <!--스크립트-->
            <v-tab-item key="tab2_1">
              <div class="tab-contnets is-script">
                <div class="jh-search-group">
                  <!-- <v-select class="jh-form is-md is-trns" style="max-width: 110px;" placeholder="선택하세요"
                  v-model="CNSL_NEWDIV"
                  :items="CNSL_DIV"
                  item-text="title"></v-select> -->
                  <v-text-field class="jh-form is-md is-trns" placeholder="검색어를 입력하세요" @keyup.enter="getChatScript" v-model="SEARCH_CONT"></v-text-field>
                  <v-btn class="jh-btn is-icon" @click="getChatScript"><i class="jh-icon-search is-blue"></i></v-btn>
                </div>
                <div class="jh-script-detail" :class="[scriptDetail ? 'is-active' : '', custInfo ? '' : 'is-expand']">
                  <div class="jh-script-detail-toolbar">
                      <div class="is-left">
                        <button type="button" class="jh-btn is-icon" title="단축키설정" @click="ShortKey=!ShortKey"><i class="jh-icon-chat-key"></i></button>
                        <button type="button" class="jh-btn is-icon" title="새로붙이기" @click="putScript('new')"><i class="jh-icon-chat-push-new"></i></button>
                        <button type="button" class="jh-btn is-icon" title="이어붙이기" @click="putScript('add')"><i class="jh-icon-chat-push"></i></button>
                        <!-- <button type="button" class="jh-btn is-icon" title="수정" @click="scriptFormClick"><i class="jh-icon-edit"></i></button>
                        <button type="button" class="jh-btn is-icon" title="삭제"><i class="jh-icon-trash"></i></button> -->
                      </div>  
                      <div class="is-right">
                        <button type="button" class="jh-btn is-trns is-right" @click="[scriptDetail=!scriptDetail, ShortKey=false]"><i class="jh-icon-close-xs"></i>닫기</button>
                      </div>
                  </div>
                  <div class="jh-script-detail-body">
                    <div class="is-shortkey" v-if="ShortKey">
                      <div class="jh-ui-header">
                        <h3>단축키설정</h3>
                        <div class="is-right">
                          <v-btn class="jh-btn" @click="clearShortForm()">취소</v-btn>
                          <v-btn class="jh-btn is-fill" @click="registShortKey()">저장</v-btn>
                        </div>
                      </div>
                      <table class="jh-tbl-detail">
                        <colgroup>
                          <col width="80px">
                          <col>
                        </colgroup>
                        <tbody>
                          <tr>
                            <th><label>새로붙이기</label></th>
                            <td>
                              <div style="display:flex">
                                <v-text-field 
                                    class="jh-form" 
                                    clearable 
                                    placeholder="단축키를 입력하세요"
                                    v-on:keyup.ctrl="checkCommand('ctrl','NEW', $event)"
                                    v-on:keyup.alt="checkCommand('alt','NEW', $event)"
                                    v-on:keyup.shift="checkCommand('shift','NEW', $event)"
                                    v-model="NEW_COMMAND"
                                  >
                                  </v-text-field>
                                  <button type="button" class="jh-btn" style="width:60px" @click="NEW_COMMAND=''">초기화</button>
                              </div>
                              <div :class="newCommandDesc != '' ? '' : 'is-hidden'">{{NEW_COMMAND}}{{newCommandDesc}}</div>                                 
                            </td>
                          </tr>
                          <tr>
                            <th><label>이어붙이기</label></th>
                            <td>
                              <div style="display:flex">
                                <v-text-field 
                                    class="jh-form" 
                                    clearable 
                                    placeholder="단축키를 입력하세요"
                                    v-on:keyup.ctrl="checkCommand('ctrl','ADD', $event)"
                                    v-on:keyup.alt="checkCommand('alt','ADD', $event)"
                                    v-on:keyup.shift="checkCommand('shift','ADD', $event)"
                                    v-model="ADD_COMMAND"
                                  >
                                  </v-text-field>
                                  <button type="button" class="jh-btn" style="width:60px" @click="ADD_COMMAND=''">초기화</button>
                              </div>
                              <div :class="addCommandDesc != '' ? '' : 'is-hidden'">{{ADD_COMMAND}}{{addCommandDesc}}</div> 
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h1>{{script_tit}} <label :class="NEW_SHORT_KEY != '' ? '' : 'is-hidden'">{{NEW_SHORT_KEY}}</label>
                     <label :class="ADD_SHORT_KEY != '' ? '' : 'is-hidden'">{{ADD_SHORT_KEY}}</label></h1>
                    <p @mouseup="selectTextScript">{{script_rmk}}</p>
                  </div>
                </div>
                <data-tables
                v-model="ScriptData"
                dataTableClass=""      
                :data-table-options="dataTableOptions"
                @click:row="rowClick"
                :paginationOptions="paginationOptions"
                >
                  <!-- <template v-slot:item.SCRIPT_TIT="{ item }"><div class="is-ellips" :title="item.SCRIPT_TIT">{{ item.SCRIPT_TIT }}</div></template> -->
                  <!-- <template v-slot:item.SCRIPT_TIT="{ item }"><div >{{ item.SCRIPT_TIT }}</div></template> -->
                </data-tables>

              </div>
            </v-tab-item>
            <!--//스크립트-->

            <!--링크-->
            <v-tab-item key="tab2_2">
              <div class="tab-contnets">
                <div class="jh-search-group is-mb-5">
                  <v-text-field class="jh-form is-md is-trns" placeholder="검색어를 입력하세요" v-model="SEARCH_LINK"></v-text-field>
                  <v-btn class="jh-btn is-icon" @click="getLink"><i class="jh-icon-search is-blue"></i></v-btn>
                </div>
                <data-tables
                v-model="LinkData"
                dataTableClass=""
                :data-table-options="dataTableOptions2"
                :paginationOptions="paginationOptions2"
                @click:row="rowClick2"

                >
                  <template v-slot:item.send="{item}">
                    <button type="button" class="jh-btn is-icon" @click="sendLink(item)"><i class="jh-icon-chat-push"></i></button>
                  </template>
                </data-tables>
              </div>
            </v-tab-item>
            <!--//링크-->
          </v-tabs-items>
        </div>
      </div>
      <!--//스크립트/링크-->
    </div>

    <!--상담이력-->
    <div class="jh-card">
      <div class="jh-card-header has-tab">
        <v-tabs v-model="tab3" slider-size="4">
          <v-tab key="tab3_1">상담이력</v-tab>
        </v-tabs>
        <p class="jh-tab-button is-right">
          <v-btn class="jh-btn is-icon" v-on:click="getTalkHist"><i class="jh-icon-refresh"></i></v-btn>
        </p>
      </div>
      <div class="jh-card-body is-p-5">
        
        <v-tabs-items v-model="tab3">
          <v-tab-item key="tab3_1">
            <div class="tab-contnets"> 
              <data-tables
              ref="dataTable3"
              v-model="chatHistory"
              dataTableClass=""
              :data-table-options="dataTableOptions3"
              :paginationOptions="paginationOptions3"
              @click:row="rowHistoryClick"
              @update:expanded="onUpdateExpanded"
              >
                <template v-slot:item.view="{item}">
                  <button type="button" class="jh-btn is-icon" @click.stop="openP101102(item)"><i class="jh-icon-comment"></i></button>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="is-p-0">
                    <ul class="jh-list-detail">
                      <!-- <li><label>상담유형</label><p>{{showCnslTyp(item)}}</p></li> -->
                      <li class="jh-cols">
                        <div>
                            <label>상담사ID</label><p>{{ item.REG_ID }}</p>
                        </div>
                        <div>
                            <label>상담사명</label><p>{{ item.USER_NM }}</p>
                        </div>
                      </li>
                      <li class="jh-cols">
                        <div>
                            <label>처리상태</label><p>{{ item.EXEC_RST_CD }}</p>
                        </div>
                        <div>
                            <label>접수일시 </label><p>{{ item.TALK_DIST_DT }}</p>
                        </div>
                      </li>
                      <li class="jh-cols">
                        <div>
                            <label>대기시간 </label><p>{{ item.TALK_READY_TIME }}</p>
                        </div>
                        <div>
                            <label>총소요시간</label><p>{{ item.TALK_TOTAL_TIME }}</p>
                        </div>
                      </li>
                      <li class="jh-cols">
                        <div>
                            <label>상담시작일시</label><p>{{ item.TALK_START_DT }}</p>
                        </div>
                        <div>
                            <label>상담종료일시</label><p>{{ item.TALK_END_DT }}</p>
                        </div>
                      </li>
                      <li><label>상담내용</label><p>{{ item.HST_CONTEXT }}</p></li>
                    </ul>
                  </td>
                </template>
              </data-tables>
            </div>
          </v-tab-item>
        </v-tabs-items> 
      </div>
    </div>
    <!--//상담이력-->      

    <!-- 장문 전체보기 -->
    <v-dialog v-model="dialogTotalMsg" max-width="500" content-class="jh-dialog-wrap has-body-scroll">            
      <div class="jh-dialog-header">
        <h1>장문대화 전체보기</h1>
        <div class="is-right">
          <button type="button" class="jh-btn is-icon" title="닫기" @click="closeDialogTotalMsg"><i class="jh-icon-close-lg is-white"></i></button>
        </div>
      </div>
      <div class="jh-dialog-body"><div class="jh-mssg-all">{{msg_all}}</div></div>
    </v-dialog>

    <!-- 이미지 전체보기 -->
    <v-dialog v-model="dialogTotalImage" content-class="jh-dialog-wrap has-body-scroll has-img">                
      <div class="jh-dialog-header">
        <h1>이미지 보기</h1>
        <div class="is-right">
          <button type="button" class="jh-btn is-icon" title="닫기" @click="closeDialogTotalImage"><i class="jh-icon-close-lg is-white"></i></button>
        </div>
      </div>
      <div class="jh-dialog-body"><img :src="img_src"></div>                
    </v-dialog>

    <!-- 이미지 전송 -->
    <v-dialog width="1600" v-model="DialogP101101">
      <dialog-P101101
          headerTitle="이미지 전송"
          @hide="hideDialog('P101101')"
          :Imgdata="Imgdata"
          @input="Imgdata = $event"
          @selImg="getTalkInfo(),hideDialog('P101101')"
      ></dialog-P101101>
      <template v-slot:body></template>
    </v-dialog>
      <!-- 즐겨쓰는상담소 모달 -->
      <v-dialog max-width="700" persistent v-if="DialogP100602" v-model="DialogP100602" content-class="square-modal">
        <dialog-P100602
          ref="DialogP100602ref"
          headerTitle="REPEAT 즐겨찾기 설정"
          :params=DialogParamP100602
          @hide="hideDialog('P100602')"
          @submit="submitDialog('P100602')"
        ></dialog-P100602>
      </v-dialog>      
  </div>
</template>

<script>
import { apiCall } from "@/store/callUtil.js";

import { mixin } from "@/mixin/mixin.js";
import api from "../../store/apiUtil.js";
import apiChat from "../../store/apiChatParamUtil.js";
import DialogP101101 from "./P101101.vue";
import DialogP100602 from "@/views/page/P100602.vue"
import iconv from "iconv-lite";
import he from "he";

import dataTables from "@/components/DataTables";
import moment from "moment";
import Vue from "vue";
import axios from "axios";
import VueAuthImage from 'vue-auth-image';
import { apiChatPost } from "@/store/chatUtil.js";

import { mapGetters } from "vuex"

import { eventBus } from "@/store/eventBus.js";           //Event Bus 
import { stompUtil }  from "@/store/stomp-util.js";

import chatStore from "@/store/modules/chatStore.js";

Vue.use(VueAuthImage);

const RDWP_SERVER_URL = 'https://172.17.10.162:16650/chatscreenstation';

  export default {
    name: "MENU_E010102", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
      DialogP101101,
      dataTables,
      DialogP100602,  // 즐겨쓰는 상당유형 팝업

    },
    data() {
      return {
        ORI_IN_CODE:'',
        ORI_LOGIN_TYPE:'',
        ORI_USER_PH_NUM:'',
        acntClassNm: 'jh-form',
        historyDate: '',
        acn_style_check: false,
        click_index : 99,
        check_record: false,
        sinTongItem:{
            ACCOUNT   : '' // 계좌번호        
           ,ACCOUNT_PW: '' // 계좌비밀번호
           ,BANK_PW   : '' // 은행비밀번호
           ,CLNT_NO   : '' // 고객ID
           ,CL_CID    : '' // 실명확인번호
           ,ENC_TYPE    : '' // 암호화 구분
        },       
        PW_YN: "",

        ROW_DATA:{},
        END_CNSL: false,    
        prbmStyle: false,
        BOT_INQRY_CD: "",
        DialogP100602: false, //즐겨쓰는 상당유형
        DialogParamP100602: {}, //즐겨쓰는상담소
        IN_CODE : '',
        LOGIN_TYP : '',
        USER_PH_NUM : '',

        myMenuData: ['선택', '마이메뉴1', '마이메뉴2', '마이메뉴3마이메뉴3마이메뉴3마이메뉴3마이메뉴3마이메뉴3'],
        myMenu: '선택',


        repeatList: [
          {text: ''}
        ],

    // items: ['201972416-01 해외선물', '201972416-01 종합매매', '201972416-01 비과세 해외주식', '201972416-01'],


      ifCustInfo: {
          SRCH_ACCOUNT:'',
          SRCH_CL_CID:''
      }, //고객 정보 객체

      custList: [],

        DialogP101101: false,

        menu2: false,
        menu5: false,

        
        dialogTotalMsg: false,
        dialogTotalImage: false,
        msg_all: '',

        chatHistoryTextsize: 100,
        dropItemTextsize: [{
            title: '100%',
            size: 100
          },
          {
            title: '120%',
            size: 120
          },
          {
            title: '140%',
            size: 140
          },
        ],
        dropItemsCompany: [{
            title: 'A 업체명'
          },

        ],
        dropItemsResult: [{
            title: '완료'
          },
          // {
          //   title: '미처리'
          // },
          // {
          //   title: '처리중'
          // },
        ],
        USER_STAT: [
          { CD_NM: '채팅OFF', CD: 'CHATOFF' },
        ],
        USER_NEWSTAT: 'CHATOFF',

        TALK_CHN: [
          { CD_NM: '카카오톡', CD:'KAKAO' },
        ],
        TALK_NEWCHN:'KAKAO',

        dropItemsCategory: [
          { title: '이관'},
          { title: '예약'},
          { title: '콜백'},
          { title: '켐페인'},
        ],
        callCalendar: false,
        chatCalendar: false,
        chatCalendar2: false,
        timeStep: '00:00',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        chatmainCsEdit: true,
        chatmainChatEdit: true,
        chatmainCsPhone: [
          {
            title: '010-0000-0000',
          },
          {
            title: '010-1234-0000',
          },
        ],
        csDetailSearch: false,
        csDetailSearch2: false,
        dates: [
          (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        ],
        //고객상담이력
        chatHistory: [],
        BEFORE_USER_STAT:'',
        CNSL_TY:[
          {CD: "", CD_NM: "전체"},
        ],
        CNSL_NEWTY:'',

        TALK_STAT:[
          {CD: '',CD_NM: '전체'}
        ],
        TALK_NEWSTAT:'',

        // 상담현황
        chatList: [],
        toggle_csCtBtn: 0,
        chatFilter: false,
        //chatListFilter: 'all',
        chatListFilter: ['all'],
        csStatList: true,
        csStatChat: false,
        // 채팅 상담창
        talkHistory: [],
        missingTalkHistory: [],
        scroll: 0,
        scrollTarget: null,
        chatDone: false,
        chatTemp: false,
        chatTrans: false,
        chatCallback: false,
        chatFontsize: false,

        CUST:this.fullCust(),

        ChatListIng:'',
        ChatListDone:'',
        ChatListAfter:'',
        ChatListWait:'',
        
        CLK_CUST:'',
        CLK_CHAT:'',
        CLK_USER:'',
        CLK_SENDER:'',
        CLK_MSGSKIP:'',
        CLK_STAT:'',

        CHT_RESULT:[],
        CHT_NEWRESULT:'',

        USER_NM: '',
        // MOVE_TNO:'',
        CHK_BL:false,
        USER_MEMO:'',

        talkHistList:[],

        CNSL_DIV: [
          {title: "전체", value: ""},
          {title: "대분류", value: "1"},
          {title: "중분류", value: "2"},
          {title: "소분류", value: "3"},
        ],

        CNSL_NEWDIV: '',
        SEARCH_CONT:'',

        // page: 1,
        // pageCount: 30,
        // itemsPerPage: 5,
        // totalVisible: 10,
        gridScriptHeader: [
          {
            text: "NO",
            align: "center",
            width: "30px",
            value: "INDEX",
          },
          {
            text: "분류",
            align: "center",
            width: "50px",
            value: "LVL_NO_NM",
          },
          {
            text: "스크립트명",
            align: "center",
            width: "100px",
            value: "SCRIPT_TIT",
          },
        ],
        gridScriptBody: [],

        SCP_DETTY_NM:'',
        SCP_DET_TIT:'',
        SCP_DET_CON:'',

        Imgdata:{},

        USER_ID: [],
        USER_NEWID: '',

        CNSL_TY1:[],
        CNSL_NEWTY1:'',
        CNSL_TY2:[],
        CNSL_NEWTY2:'',
        CNSL_TY3:[],
        CNSL_NEWTY3:'',
        CNSL_TY4:[],
        CNSL_NEWTY4:'',
        CHT_TIT:'',
        CHT_CON:'',

        CHAT_MSG:'',
        CNSL_RESULT:'',

        EXEC_RST_CD:'',
        CALL_TYP_CD:'',

        dialogTotalImage : false,

        img_src : '',    
        
        acnStyle1: {
            color: 'black'
        },
        acnStyle2: {
            color: 'red'
        },
        screenValue: '스크린녹화OFF',
        screenStyle1: {
            backgroundColor:'white',
            color: 'black'
        },
        screenCss: '',

        accnPwStyle: {
            color: '#fff'
        },

        accnStyle: {
            backgroundColor:'white',
            color: '#fff'
        },
        prbmStyle1: {
            backgroundColor:'white',
            color: '#fff'
        },
        prbmStyle2: {
            backgroundColor:'white',
            color: '#fff'
        },
        prbmStyle3: {
            backgroundColor:'white',
            color: '#fff'
        },
        AcntPrdtDtlNm:'',
        AcntNo:'',
        SRCH_CL_CID:'',
        TEMP_SRCH_CL_CID:'',
        AGE:'',
        SEX:'',
        isMinor:'',
        isForeigner:'',
        CLNT_NO:'',
        CUS_LEVEL:'',
        MOVE_TNO:'',
        AcntPrdtDtlNmAddAcntNo:[],
        selectNewAcntNo:"",
        //추가  
        //채팅목록Sort
        chatListSortFilter: false,
        sortIcon_0: 'mdi-arrow-up',      
        sortIcon_1: 'mdi-arrow-up',      
        activeIndex: -1,
        //채팅목록 하단 버튼
        CahtListBtn: null,
        CahtListBtn: [
            { id: 'chat_list_01', text: '상담대기', value: '5', isActive: true },
            { id: 'chat_list_02', text: '상담중', value: '5', isActive: true },
            { id: 'chat_list_04', text: '후처리', value: '5', isActive: true },
            { id: 'chat_list_03', text: '종료', value: '5', isActive: true },
        ],

        //상담저장관련         
        CnslSave: false,
        mssgStop: false,
        //아래로버튼
        ToBottomButton: false,   
        //고객정보     
        custInfo: true,
        //스크립트탭
        tab2: 'tab2_1',
        scriptDetail: false,
        ShortKey: false,
        ScriptHeaders: [
            { text: 'NO', value: 'index', align: 'center', width: '50px' },
            // { text: '유형', value: 'type', align: 'center', width: '60px' },
            { text: '스크립트명', value: 'SCRIPT_TIT'},
            // { text: '수정일', value: 'BEGIN_DATE', align: 'center', width: '80px' },
        ],    
        singleSelect: true,
        ScriptList: [],
        ScriptData: [],

        dataTableOptions: {
          fixedHeader: true,
          hideDefaultFooter: true,
          itemKey: 'index',
          height: '100%',
          items: [],
          itemsPerPage: 50,
          noDataText: "조회된 결과가 없습니다.",
          page: 1,
          singleSelect: true,
          // itemClass: 'isActiveRow',
        },

       
        paginationOptions: {
            totalVisible: 5
        },

        LinkHeaders: [
            { text: 'NO', value: 'index', align: 'center', width: '50px' },
            { text: '링크명', value: 'BTN_NM'},
            { text: '전송', value: 'send', align: 'center', width: '50px', sortable: false },
        ],  
        LinkData: [],  
        dataTableOptions2: {
          fixedHeader: true,
          hideDefaultFooter: true,
          itemKey: 'index',
          height: '100%',
          items: [],
          itemsPerPage: 50,
          noDataText: "조회된 결과가 없습니다.",
          page: 1,
          singleSelect: true,
          itemClass: 'isActiveRow',
          isSelected: false
        },

        

      // dataTableOptions: {
      //     fixedHeader: true,
      //     hideDefaultFooter: true,
      //     hideDefaultHeader: true,
      //     height: '575px',
      //     itemKey: 'index',
      //     items: [],
      //     itemsPerPage: 50,
      //     noDataText: "조회된 결과가 없습니다.",
      //     page: 1,
      //     singleSelect: true,
      //     itemClass: 'isActiveRow',
      // },
      
      
        paginationOptions2: {
            totalVisible: 5
        },

        HistoryHeaders: [
            { text: 'NO', value: 'index', align: 'center', width: '30px', sortable: false },
            { text: '채팅', value: 'view', align: 'center', width: '40px', sortable: false },
            { text: '상담유형', value: 'CAT_CNSL_TYP'},
            // { text: '상담제목', value: 'HST_CONTEXT'},
            { text: '상담사', value: 'USER_NM', align: 'center', width: '60px', sortable: false },
        ],  
        HistoryData: [
          {subject: '상담제목', name: '홍길동'},
          {subject: '상담제목', name: '홍길동'},
          {subject: '상담제목', name: '홍길동'},
          {subject: '상담제목', name: '홍길동'},
          {subject: '상담제목', name: '홍길동'},
        ],
        dataTableOptions3: {
          fixedHeader: true,
          hideDefaultFooter: true,
          itemKey: 'index',
          items: undefined,
          itemsPerPage: 50,
          noDataText: "조회된 결과가 없습니다.",
          page: 1,
          singleSelect: true,
          singleExpand: true,
        },
        paginationOptions3: {
            totalVisible: 5
        },
        tab1: '',
        tab2: '',
        tab3: '',
        noData: false,

        sortBy: "TALK_DIST_DT",
        decending: "DESC",

        script_rmk: "",
        script_tit: "",
        ADD_COMMAND: "",
        NEW_COMMAND: "",
        add_command_desc: "",
        new_command_desc: "",
        scriptId: "",
        ADD_SHORT_KEY: "",
        NEW_SHORT_KEY: "",
        SEARCH_LINK: "",
        LinkList: [],
        historySaveType: "",
      }
    },
    methods: {
      dissssssssssss(item){
        this.disconnect();      
      },
    //상담유형ChangeEvent
    async changeMdfyCouselTyeKey(asp_cust_key, spst_coundel_typ_cd, data_type, toggleBool) {
      if (data_type === "1") {
        this.CNSL_TY2 = [{ CD_NM: "전체", CD: "" }];
        this.CNSL_TY3 = [{ CD_NM: "전체", CD: "" }];
        this.CNSL_TY2 = await this.mixin_counsel_type_code_div_get(
          "",
          asp_cust_key,
          "C",
          spst_coundel_typ_cd,
          ""
        );
        console.log("CNSL_TY2 ", this.CNSL_TY2)
        this.CNSL_NEWTY2 = toggleBool ? this.CNSL_NEWTY2 : this.CNSL_TY2[0].CD;
        this.CNSL_NEWTY3 = toggleBool ? this.CNSL_NEWTY3 : "";
        this.changeMdfyCouselTyeKey('001', this.CNSL_NEWTY2, '2', toggleBool);
      } else if (data_type === "2") {
        this.CNSL_TY3 = [{ CD_NM: "전체", CD: "" }];
        this.CNSL_TY3 = await this.mixin_counsel_type_code_div_get(
          "",
          asp_cust_key,
          "C",
          spst_coundel_typ_cd,
          ""
        );
        this.CNSL_NEWTY3 = toggleBool ? this.CNSL_NEWTY3 : this.CNSL_TY3[0].CD;
      }
    },


      
      sendLink(item){
        console.log("sendLink!!");
        console.log(item);
        console.log("this.LOGIN_TYP  ", this.LOGIN_TYP);
        console.log("item.index  ", item.index);
        console.log(this.LOGIN_TYP == " - " );

        if(item.index == 2){
          if(this.LOGIN_TYP == " - " || this.LOGIN_TYP == "시세전용로그인" || this.CLNT_NO == ""){
            this.common_alert('미로그인 고객입니다!<br> 로그인 인증 후 계좌인증 진행하세요.', 'error');

            return;
          }
        }

        // if(item.UNTACT_ID == "20221209140214341LNKyQ0VA"){
        //   if(this.CLNT_NO == ""){
        //     console.log("this.clntno " , this.CLNT_NO)

        //     this.common_alert('미로그인 고객입니다.<br> 로그인 인증 후 계좌인증 진행하세요.', 'error');

        //     return;
        //   }
        // }
        if(this.CLK_CHAT != undefined && this.CLK_CHAT != null){
          var strMsg = "고객님께 [" + item.BTN_NM + "] 링크를 전송하였습니다.";
          var MSG_INFO = {'msg' : strMsg, meesage_type : 'LI_UNTACT', SNDRCV_CD : 'SND' , type : 'LI_UNTACT', UNTACT_ID : item.UNTACT_ID};

          let CHAT_INFO = {'talk_contact_id' : this.CLK_CHAT
                          , 'talk_sender_key' : this.CLK_SENDER
                          , 'call_typ_cd' : this.CALL_TYP_CD
                          , 'aspNewCustKey' : this.CUST
                          , 'talk_user_key': this.CLK_USER
                          , 'SEND_USER_NM' : this.USER_NM
                          , 'token' : this.$store.state.messages.token
                          , 'userId' : this.$store.getters['userStore/GE_USER_ROLE'].userId
                          };

          let chatmsg = this.$store.getters['chatStore/getMsgObj',{
                        user_key: CHAT_INFO.talk_user_key
                        , sndrcv_cd: 'snd'
                        , type: MSG_INFO.message_type
                        , content: MSG_INFO.msg
                        , talk_contact_id: CHAT_INFO.talk_contact_id
                        , IMAGE_TALK_PATH : MSG_INFO.IMAGE_TALK_PATH != '' ? MSG_INFO.IMAGE_TALK_PATH : ''
                        , FILE_GROUP_KEY : MSG_INFO.FILE_GROUP_KEY != '' ? MSG_INFO.FILE_GROUP_KEY : ''
                        , FILE_KEY : MSG_INFO.FILE_KEY != '' ? MSG_INFO.FILE_KEY : ''
                        , FILE_BLOB : MSG_INFO.FILE_BLOB != '' ? MSG_INFO.FILE_BLOB : ''
                        , FILE_EXTS : MSG_INFO.FILE_EXTS != '' ? MSG_INFO.FILE_EXTS : ''
                        //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
                        , SEND_USER_NM : CHAT_INFO.SEND_USER_NM
                        , SEND_USER_SHORT_NM : '나'
                        , consultEnd : MSG_INFO.consultEnd
                        , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                    }];

          let msgObj = this.$store.getters['chatStore/getContactMsg', { talk_contact_id: CHAT_INFO.talk_contact_id }];

          stompUtil.sendMessage(MSG_INFO,CHAT_INFO, chatmsg, msgObj);
        }
      },
      getLink(){
        api.post("/api/chat/setting/untactUrl/list",
        {
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_NEWCUST_KEY: this.CUST,
          BTN_NM : this.SEARCH_LINK,
          USE_YN : "Y",
          action:'api/chat/setting/untactUrl/list"',
          method:'post',    
        },
        {
          head: {
            "SERVICE" : "chat.setting.untactUrl",
            "METHOD" : "list",
            "TYPE" : "BIZ_SERVICE",
            "ASYNC": true,
            "PAGES_CNT" : "1",
            "ROW_CNT" : "50",               
          }
        }).then((response) => {
          let retObj = Object.values(response.data);

          console.log(retObj);
          // this.LinkData = response.data.DATA;
          // for(let i=0;i<this.LinkData.length;i++){
          //   this.LinkData[i]["index"] = i+1;
            
          // }
          // console.log("this.LinkData",this.LinkData);

          // this.dataTableOptions2.items = this.LinkData;

          this.dataTableOptions2.items = response.data.DATA.map(
            (item, index) => ({
            ...item,
            index: index + 1
          }));

          next(resultStr);
        }).catch((err) => {
        })     

      },
    openPopup(popNm, evtNm) {
      switch(popNm) {
        case 'P100602':  //사용자근태
          this.DialogParamP100602 = {}
          this.DialogParamP100602['ASP_NEWCUST_KEY'] = this.CUST
          break
      }
      this.showRepeatDialog(popNm)
    },
    showRepeatDialog(type) {
      this[`Dialog${type}`] = true
    },
    openP100602(){
      this.$openWindow('P100602','P100602','800','800');
    },
    async getRepeatList() {      
        let params = {
          USE_YN: 'Y',
          USER_ID: this.$store.getters["userStore/GE_USER_ID"],
          ROW_CNT: 10,
        };

        let URLData = '/api/chat/main/repeat-cmb/list';
        let HEADER_SERVICE = 'chat.main.repeat-cmb';
        let HEADER_METHOD = 'regist';
        let HEADER_TYPE = 'BIZ_SERVICE';
        await api
        .post(URLData, params, {
        head: {
          SERVICE: HEADER_SERVICE,
          METHOD: HEADER_METHOD,
          TYPE: HEADER_TYPE,
          ASYNC: false,
        },
        })
        .then((response) => {
          let resData = [];
          let data = {ID:"", value:"", text: '선택/선택/선택'}
          resData.push(data);
          for(let d of response.data.DATA) {
            for(let k of Object.keys(d)) {
              if(k === 'CONTENT') d[k] = this.restoreXSS(d[k].replace(/<BR>/gi, "\n"));
              else d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k]
            }
            resData.push(d)
          }
  
          this.repeatList = resData;
          this.myMenu = "";
          
          
          // this.repeatList = response.data.DATA;

          



        })
        .catch((err) => {
          this.common_alert('시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.', 'error');
          console.log(err);
        });
    },

        
    setCnslTyCd(num) {
              console.log("this.repeatList  ", this.repeatList)

      let cnslTy = this.repeatList[num].value;
      this.CHT_CON = this.repeatList[num].CONTENT;
      this.myMenu = cnslTy;
      this.changeCallType(cnslTy);
    },
    async changeCallType(cnslTy){
      console.log("cnslTy ", cnslTy)
      let cnslTyL = (cnslTy.length>=2?cnslTy.substr(0, 2):'');
      let cnslTyM = (cnslTy.length>=4?cnslTy.substr(0, 4):'');
      let cnslTyS = (cnslTy.length==6?cnslTy:'');

      this.CNSL_TY1 = await this.mixin_counsel_type_code_div_get(
        "",
        this.CUST,
        "C",
        "*",
        "선택"
      );

      this.CNSL_TY2 =  await this.mixin_counsel_type_code_div_get(
        "",
        this.CUST,
        "C",
        cnslTyL,
        "선택"
      );

      if(cnslTy.length == 6) {
        this.CNSL_TY3 = await this.mixin_counsel_type_code_div_get(
          "",
          this.CUST,
          "C",
          cnslTyM,
          "선택"
        );
      }

      this.CNSL_NEWTY1  = cnslTyL;
      this.CNSL_NEWTY2 = cnslTyM;
      this.CNSL_NEWTY3  = cnslTyS;
    },


    
    // 메시지 삭제
    deleteMsg(idx, talkSerialNumber){

        apiChat.post("/api/chat/main/chtt-mssage/delete",
        {
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_NEWCUST_KEY: this.CUST,
          TALK_CONTACT_ID: this.CLK_CHAT,
          TALK_SERIAL_NUMBER: talkSerialNumber,
          MSG_CONTENT: "삭제된 메시지입니다.",
          TYPE: "remove_message",
        },
        {
          head: {
            "SERVICE" : "chat.chtt-mssage",
            "METHOD" : "delete",
            "TYPE" : "BIZ_SERVICE",
          }
        }).then((response) => {
          let retObj = Object.values(response.data.data);

          this.talkHistory[idx].CONTENT = "삭제된 메시지입니다.";
          this.talkHistory[idx].TYPE = "remove_message";

        }).catch((err) => {
          alert(err);
        })          
    },

      // showDialog(menu) {
      //   let dataObj = {ASP_NEWCUST_KEY: this.CUST 
      //                 , CLK_CHAT:this.CLK_CHAT
      //                 , CLK_SENDER: this.CLK_SENDER
      //                 , CLK_CUST: this.CLK_CUST
      //                 , CLK_USER: this.CLK_USER
      //                 , USER_NM: this.USER_NM
      //                 };
      //   this.Imgdata = dataObj;
      //   console.log(this.Imgdata);
      //   this[`Dialog${menu}`] = true;
      // },
      
      





    sendImg(idx){
      
      let IMG_INFO = {message_type : 'IM', SNDRCV_CD: 'SND', type : 'image'
                , image : this.talkHistory[idx].FILE_PATH + "/" + this.talkHistory[idx].PATH_TYPE + "/" + this.talkHistory[idx].SAVE_FILENAME
                , msg : this.talkHistory[idx].FILE_PATH + "/" + this.talkHistory[idx].PATH_TYPE + "/" + this.talkHistory[idx].SAVE_FILENAME
                , CONTENT : this.talkHistory[idx].FILE_PATH + "/" + this.talkHistory[idx].PATH_TYPE + "/" + this.talkHistory[idx].SAVE_FILENAME
                , IMAGE_TALK_PATH : this.talkHistory[idx].FILE_PATH + "/" + this.talkHistory[idx].PATH_TYPE + "/" + this.talkHistory[idx].SAVE_FILENAME
                , FILE_GROUP_KEY : this.talkHistory[idx].FILE_GROUP_KEY
                , FILE_KEY : this.talkHistory[idx].FILE_KEY
              };

      let CHAT_INFO = {'talk_contact_id' : this.CLK_CHAT
                      , 'talk_sender_key' : this.CLK_SENDER
                      , 'call_typ_cd' : 'TTALK'
                      , 'aspNewCustKey' : this.CUST
                      , 'talk_user_key': this.CLK_USER
                      , 'SEND_USER_NM' : this.USER_NM
                      , 'token' : this.$store.state.messages.token
                      , 'userId' : this.$store.getters['userStore/GE_USER_ROLE'].userId
                      };

      let chatmsg = this.$store.getters['messages/GE_MSG_OBJ']({
                    user_key: CHAT_INFO.talk_user_key
                    , sndrcv_cd: 'snd'
                    , type: IMG_INFO.message_type
                    , content: IMG_INFO.msg
                    , talk_contact_id: CHAT_INFO.talk_contact_id
                    , IMAGE_TALK_PATH : IMG_INFO.IMAGE_TALK_PATH != '' ? IMG_INFO.IMAGE_TALK_PATH : ''
                    , FILE_GROUP_KEY : IMG_INFO.FILE_GROUP_KEY != '' ? IMG_INFO.FILE_GROUP_KEY : ''
                    , FILE_KEY : IMG_INFO.FILE_KEY != '' ? IMG_INFO.FILE_KEY : ''
                    , FILE_BLOB : IMG_INFO.FILE_BLOB != '' ? IMG_INFO.FILE_BLOB : ''
                    , FILE_EXTS : IMG_INFO.FILE_EXTS != '' ? IMG_INFO.FILE_EXTS : ''
                    //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
                    , SEND_USER_NM : CHAT_INFO.SEND_USER_NM
                    , SEND_USER_SHORT_NM : '나'
                    , consultEnd : IMG_INFO.consultEnd
                    , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                });

      let msgObj = this.$store.getters['messages/GE_CONTACT_MSG']({ talk_contact_id: CHAT_INFO.talk_contact_id });
      
      
      console.log("this.IMG_INFO      ", IMG_INFO);
      console.log("this.CHAT_INFO      ", CHAT_INFO);
      console.log("this.chatmsg      ", chatmsg);
      console.log("this.msgObj      ", msgObj);

      stompUtil.sendMessage(IMG_INFO, CHAT_INFO, chatmsg, msgObj);

      this.getTalkList();
      this.getTalkInfo();      
    },


    // 메시지 재전송
    reSendMsg(idx, talkSerialNumber){

      if(this.talkHistory[idx].TYPE == "IM"){
        this.sendImg(idx)

      }else{
        this.CHAT_MSG = this.talkHistory[idx].CONTENT
        this.sendMsg()
      }


        apiChat.post("/api/chat/main/chtt-mssage/resend",
        {
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_NEWCUST_KEY: this.CUST,
          TALK_CONTACT_ID: this.CLK_CHAT,
          TALK_SERIAL_NUMBER: talkSerialNumber,
          MSG_CONTENT: "재전송 된 메시지입니다.",
          TYPE: "resend_message",
        },
        {
          head: {
            "SERVICE" : "chat.chtt-mssage",
            "METHOD" : "resend",
            "TYPE" : "BIZ_SERVICE",
          }
        }).then((response) => {
          let retObj = Object.values(response.data.data);

          this.talkHistory[idx].CONTENT = "재전송 된 메시지입니다.";
          this.talkHistory[idx].TYPE = "resend_message";

        }).catch((err) => {
          alert(err);
        })          
    },    
    
    showInqryTyp(){
      var inqryArr = "";

      if(this.inqryFullNm != "" && this.inqryFullNm != null && this.inqryFullNm != undefined){
        inqryArr = this.inqryFullNm.replaceAll("/"," > ");
      }

      return inqryArr;
    },

    showCnslTyp(item){
      var cnslText = "";
      if(item.CNSL_TYP4 != null && item.CNSL_TYP4 != ""){
        cnslText = item.CNSL_TYP + " > " + item.CNSL_TYP2 + " > " + item.CNSL_TYP3 + " > " + item.CNSL_TYP4;
      }else if(item.CNSL_TYP3 != null && item.CNSL_TYP3 != ""){
        cnslText = item.CNSL_TYP + " > " + item.CNSL_TYP2 + " > " + item.CNSL_TYP3;
      }else if(item.CNSL_TYP2 != null && item.CNSL_TYP2 != ""){
        cnslText = item.CNSL_TYP + " > " + item.CNSL_TYP2;
      }

      return cnslText;
    },
    showAlertEndTalk(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '상담을 종료하시겠습니까?',
        iconClass: 'svg-chk-lg',
        type: 'confirm',
        callYes: this.callYesEndTalk,
        callNo: this.callNoEndTalk,
      });
    },

    callYesEndTalk(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      this.END_CNSL= false
      this.clickDone();
      this.endTalk();
      this.getTalkList();
    },
    callNoEndTalk(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    endTalk(){
      apiChat.post("api/chat/main/end-mssage/inqire",   //api url입력
      {
        ASP_NEWCUST_KEY: this.CUST,
        system_id: '20180403040958193MSG34895',
        ASP_CUST_KEY: this.CUST,
        USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        action:'api/chat/main/end-mssage/inqire',
        method:'post'
      },
      {head: {
      }})
      .then((response) => {
        let MSG_INFO = {'type' : 'system'
          , message_type : 'TX'
          , SNDRCV_CD: 'SND'
          , type : 'message'
          , consultEnd: 'Y'
          , msg : response.data.data
        };

        let CHAT_INFO = {'talk_contact_id' : this.CLK_CHAT
                        , 'talk_sender_key' : this.CLK_SENDER
                        , 'call_typ_cd' : this.CALL_TYP_CD
                        , 'aspNewCustKey' : this.CUST
                        , 'talk_user_key': this.CLK_USER
                        , 'SEND_USER_NM' : this.USER_NM
                        , 'token' : this.$store.state.messages.token
                        , 'userId' : this.$store.getters['userStore/GE_USER_ROLE'].userId
                        };

        let chatmsg = this.$store.getters['chatStore/getMsgObj',{
                      user_key: CHAT_INFO.talk_user_key
                      , sndrcv_cd: 'snd'
                      , type: MSG_INFO.message_type
                      , content: MSG_INFO.msg
                      , talk_contact_id: CHAT_INFO.talk_contact_id
                      , IMAGE_TALK_PATH : MSG_INFO.IMAGE_TALK_PATH != '' ? MSG_INFO.IMAGE_TALK_PATH : ''
                      , FILE_GROUP_KEY : MSG_INFO.FILE_GROUP_KEY != '' ? MSG_INFO.FILE_GROUP_KEY : ''
                      , FILE_KEY : MSG_INFO.FILE_KEY != '' ? MSG_INFO.FILE_KEY : ''
                      , FILE_BLOB : MSG_INFO.FILE_BLOB != '' ? MSG_INFO.FILE_BLOB : ''
                      , FILE_EXTS : MSG_INFO.FILE_EXTS != '' ? MSG_INFO.FILE_EXTS : ''
                      //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
                      , SEND_USER_NM : CHAT_INFO.SEND_USER_NM
                      , SEND_USER_SHORT_NM : '나'
                      , consultEnd : MSG_INFO.consultEnd
                      , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                  }];

        let msgObj = this.$store.getters['chatStore/getContactMsg', { talk_contact_id: CHAT_INFO.talk_contact_id }];

        stompUtil.sendMessage(MSG_INFO,CHAT_INFO,chatmsg,msgObj);
      })
      .catch((err) => {
        alert(err);
      })
    },

    // 스크립트 입력창 닫기 
    closeScriptForm() {
        this.is_use_command = false;
    },
    saveScriptForm() {
        //eventBus.closeScriptForm();
    },
    downloadExcel: function(history){
      var filename = this.CLK_USER + "_채팅내역";
      this.mixin_chat_exportExcel(history, filename);
    },
    // 스크립트 드래그 감지 함수
    selectTextScript: function(){
      var selectionObj = window.getSelection();
      // this.selectedText = selectionObj.toString();
    },
    // 스크립트 버튼
    putScript: function(type){
      if(type === "new"){
        this.CHAT_MSG = (this.selectedText == null || this.selectedText =="" ? this.script_rmk : this.selectedText);
      }else{
        if(this.CHAT_MSG != ""){
          this.CHAT_MSG += "\n" + (this.selectedText == null || this.selectedText =="" ? this.script_rmk : this.selectedText);
        }else{
          this.CHAT_MSG += (this.selectedText == null || this.selectedText =="" ? this.script_rmk : this.selectedText);
        }
      }
    },

    findCommand:function(type, e){
      console.log("e key           ", e.key);
      if(type == "shift" && e.key == "Enter"){
      console.log("type",type)
      } else{
        var shortKey = '';

        shortKey = type + "+" + e.key;
        console.log("Short Key::" + e.key);

        apiChat.post("/api/chat/main/script-shrten-key-dplct-ceck/inqire",
        {
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_NEWCUST_KEY: this.CUST,
          SHORT_KEY: shortKey,
        },
        {
          head: {
            "SERVICE" : "chat.main.script-shrten-key-dplct-ceck",
            "METHOD" : "inqire",
            "TYPE" : "BIZ_SERVICE",
          }
        }).then((response) => {
          let retObj = Object.values(response.data.data);

          if(retObj[0].SHORT_KEY != null && retObj[0].SHORT_KEY != ""){
            console.log("Short Key(value)::" + retObj[0].SCRIPT_RMK);

            if(retObj[0].ACTION_TYPE === "NEW"){
              this.CHAT_MSG = retObj[0].SCRIPT_RMK;
            }else{
              if(this.CHAT_MSG != ""){
                this.CHAT_MSG += "\n" + retObj[0].SCRIPT_RMK;
              }else{
                this.CHAT_MSG += retObj[0].SCRIPT_RMK;
              }
            }
            
          }
          next(resultStr);
        }).catch((err) => {
        })          
      }
    },

    checkCommand:function(keyType, type, e){
      if(type == 'ADD'){
        this.ADD_COMMAND = keyType + "+" + e.key;
        this.existCommand('ADD',this.ADD_COMMAND, (result) => {this.add_command_desc = result;});
      }else if(type == 'NEW'){
        this.NEW_COMMAND = keyType + "+" + e.key;
        this.existCommand('NEW',this.NEW_COMMAND, (result) => {this.new_command_desc = result;});          
      }
    },

    existCommand:function(actionType, value, next){
      var resultStr = '';

      apiChat.post("/api/chat/main/script-shrten-key-dplct-ceck/inqire",
      {
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.CUST,
        USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_NEWCUST_KEY: this.CUST,
        SHORT_KEY: value,
      },
      {
        head: {
          "SERVICE" : "chat.main.script-shrten-key-dplct-ceck",
          "METHOD" : "inqire",
          "TYPE" : "BIZ_SERVICE",
        }
      }).then((response) => {
        let retObj = Object.values(response.data.data);

        if(retObj != null && retObj.length > 0){
          resultStr = "중복 단축키 존재. 기존 단축키는 삭제 됩니다." + " / " + retObj[0].SCRIPT_TIT;
        }else{
          resultStr = "중복없음. 등록가능.";
        }
        next(resultStr);
      }).catch((err) => {
        alert(err);
      })
    },

    // 단축키 등록
    registShortKey: function(){
      var commands = [];

      if(this.ADD_COMMAND != null && this.ADD_COMMAND != ""){
        commands.push({SHORT_KEY : this.ADD_COMMAND , ACTION_TYPE : 'ADD'});
      }

      if(this.NEW_COMMAND != null && this.NEW_COMMAND != ""){
        commands.push({SHORT_KEY : this.NEW_COMMAND , ACTION_TYPE : 'NEW'});
      }

      apiChat.post("/api/chat/main/script/short/regist",
      {
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.CUST,
        USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_NEWCUST_KEY: this.CUST,
        SCRIPT_ID:this.scriptId,
        commands: commands,
      },
      {
        head: {
          "SERVICE" : "chat.main.script.short",
          "METHOD" : "regist",
          "TYPE" : "BIZ_SERVICE",
        }
      }).then((response) => {
        this.clearShortForm();
        this.success();
      }).catch((err) => {
        alert(err);
      })
    },

    clearShortForm: function(){
      this.ShortKey=false;
      this.ADD_COMMAND = "";
      this.NEW_COMMAND = "";
    },

    // 단축키 설정 버튼 클릭
    scriptFormClick: function(){
        this.is_use_command = !this.is_use_command;
    },
    // 스크립트 상세 닫기
    closeDetail: function(){
      this.scriptActive = false;
      this.scriptTableHeight = 270;
    },
    /**
     * 이미지 전체 보기 
     */
    setViewAllImg: function(message) {
      this.img_src  = this.getImageSrc(message);
      this.dialogTotalImage = true;
    },  


    getImageSrc: function(message)  {
        var strImageUrl = "";
        console.log("message  ", message);

        const token = window.sessionStorage.getItem("token");
        var authHeader = "bearer " + token;
        axios.defaults.headers.common['Authorization'] = authHeader;

        strImageUrl = `/api/file/chat/images/${message.FILE_GROUP_KEY}/${message.FILE_KEY}`;
        console.log("strImageUrl  ", strImageUrl);
        return strImageUrl;


    },

    /**
     * 이미지 전체보기 창 닫기 
     */
    closeDialogTotalImage : function() {
        this.dialogTotalImage = false;
    },   

      //일시 모달
      formatDate(date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${year}/${month}/${day}`
      },
      startDate(e) {
        this.dates[0] = e;
      },
      endDate(e) {
        this.dates[1] = e;
      },
      allowedStep: (m) => m % 10 === 0,
      chatmainCsCall(e) {
        console.log('전화번호1', e);
      },
      // chatmainCsToggle(item) {
      //   this.getTalkHist(item.ID,!this.chatHistory[item.ID-1].EXPAND);
      //   this.getTalkHistList(item.ID-1,item.JOBBY_CNSL_HST_ID);
      // },

      initChatListCount(){       
        this.chatListFilter.push('ing');
        //this.chatListFilter.push('done');
        this.chatListFilter.push('after');
        this.chatListFilter.push('wait');
      },

      FnChatListClick(item) {
        item.isActive = !item.isActive;
        
        switch(item.id){
          case "ready":
            this.FnChatListWait();
            break;
           case "talking":
             this.FnChatListIng();
            break;             
          case "post":
            this.FnChatListAfter();
            break;          
          case "end":
            this.FnChatListDone();
            break;          
        }
      },  

      // 상담현황
      FnChatListAll() {
        this.chatListFilter = 'all'
      },
      FnChatListIng() {
        //this.chatListFilter = 'ing'

        if(this.chatListFilter.includes('ing')){
          this.chatListFilter.splice(this.chatListFilter.indexOf('ing'),1);
        }else{
          this.chatListFilter.push('ing');
        }
      },
      FnChatListDone() {
        //this.chatListFilter = 'done'
        if(this.chatListFilter.includes('done')){
          this.chatListFilter.splice(this.chatListFilter.indexOf('done'),1);
        }else{
          this.chatListFilter.push('done');
        }          
      },
      FnChatListAfter() {
        //this.chatListFilter = 'after'
        if(this.chatListFilter.includes('after')){
          this.chatListFilter.splice(this.chatListFilter.indexOf('after'),1);
        }else{
          this.chatListFilter.push('after');
        }             
      },
      FnChatListWait() {
        //this.chatListFilter = 'wait'

        console.log("chatList::::");
        console.log(this.chatList);


        if(this.chatListFilter.includes('wait')){
          this.chatListFilter.splice(this.chatListFilter.indexOf('wait'),1);
        }else{
          this.chatListFilter.push('wait');
        }              
        console.log("this.chatListFilter   :   ",this.chatListFilter);

        // this.chatListFilter
      },
      // 채팅상담 맨위로
      onScroll(e) {
        this.scroll = e.target.scrollTop;
      },
      scrlToTop() {
        this.scrollTarget = document.querySelector('.layout-chatmain--chat-history-box');

        if (this.scrollTarget) {
          this.scrollTarget.scrollTop = 0;
        }
      },
      FnChatHistoryTextsize(e) {
        this.chatHistoryTextsize = e;
        this.chatFontsize = false;
      },

      //상담원 전체 고객사 키
      fullCust(){
        let CUST = ''
        for(let i=0;i<this.$store.getters['userStore/GE_USER_ROLE'].company.length;i++){
          if(i == 0){
            CUST = this.$store.getters['userStore/GE_USER_ROLE'].company[i].CD
          } else {
            CUST = CUST + ',' + this.$store.getters['userStore/GE_USER_ROLE'].company[i].CD
          }
        }

        return CUST;
      },

      chatOnNO(){
        if(this.BEFORE_USER_STAT == ''){
          this.USER_NEWSTAT = "CHATOFF"
        }else{
          this.USER_NEWSTAT = this.BEFORE_USER_STAT;
        }
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
      },   

    //상담원 상태 변경
    changeUserStat: async function (showMsg){

      let ctiInfo = this.$store.getters["callStore/GE_CTI_INFO"];


      // showAlertReg(){
      //   this.$store.commit("alertStore/openAlertDialog", {
      //     alertDialogToggle: true,
      //     msg: '고객정보를 저장하시겠습니까?',
      //     iconClass: 'svg-chk-lg',
      //     type: 'confirm',
      //     callYes: this.callYes1,
      //     callNo: this.callNo1,
      //   })
      // },

      if(this.USER_NEWSTAT == "CHATOFF"){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: '채팅 OFF하면 녹화중지 됩니다.<br>미 완료된 채팅 건은 꼭 상담완료 해 주세요!<br><br>상담대기고객은 반드시 다른 상담원에게 전달해주세요.<br>정말 채팅 종료 하시겠습니까?',
          iconClass: 'svg-chk-lg',
          type: 'confirm',
          callYes: this.chatOffYes,
          callNo: this.chatOffNO,
        })
      }else if(ctiInfo.CTI_MODE && this.USER_NEWSTAT == "CHATON"){
  
          this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: '채팅ON을 하시려면 CTI OFF 해주세요',
            iconClass: "jh-alert-icon is-warning",
            type: "confirmAplyWork",
            callYes: this.chatOnNO
          })            
      }else{        
        this.BEFORE_USER_STAT = this.USER_NEWSTAT;
        // if(this.USER_NEWSTAT == "CHATON"){

        //   let URLData = RDWP_SERVER_URL;
        //   let params = {
            
        //       station: this.$store.getters['userStore/GE_USER_ROLE'].IPCC_NO,
        //       type: "start"
            
        //   };

        //   console.log("JSON.stringify  ", JSON.stringify(params))

        //   try {

        //     await apiChatPost.chatPost(URLData, params)
        //     this.screenValue = "스크린녹화ON"
        //     this.screenStyle1.color = "RED"

        //   } catch(apiEx) {
        //     console.log("녹화 시작 중 에러 발생!! - "+apiEx);
        //     // appendLog("rdwtCloseCall", apiEx);
        //   }  

        // }

        apiChat.post("api/chat/main/cnslr-sttus/regist",   //api url입력
        {
          USER_STATUS_CD: this.USER_NEWSTAT,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_NEWCUST_KEY: this.CUST,
          FLAG: false
        },
        {head: {
          "SERVICE" : "setting.holiday.manage",
          "METHOD" : "list",
          "TYPE" : "BIZ_SERVICE",
          "ROW_CNT" : "200",
          "PAGES_CNT" : "1",
        }})
        .then((response) => {
          if(showMsg) {

            let count     = response.data.header.COUNT;
            let errorFlag = response.data.header.ERROR_FLAG;

            if (count != null && errorFlag == false) {
                if(count >= 0) {
                    this.$store.commit("authStore/MU_CHAT_MODE", false);

                    console.log("this.$store.getters[chatStore/GE_CHAT_MODE] 111111111111111111", this.$store.getters["authStore/GE_CHAT_MODE"])
                    this.showAlertComplete( '상태가 변경 되었습니다.')
                    //console.log("this.dropItemsUserStatCd", this.dropItemsUserStatCd);
                    // if(this.dropItemsUserStatCd == 'CHATON'){
                    //   this.setTalkEnv(); // timeout 정보
                    //   this.connect(); // ready websocket 연결
                    // }
                    if(this.screenStyle1.color == "RED"){
                      this.check_record = true;
                    }else{
                      this.check_record = false;
                    }

                  if(this.USER_NEWSTAT == "CHATON"){
                    this.check_record = false
                    this.$store.commit("authStore/MU_CHAT_MODE", true);
                                        console.log("this.$store.getters[chatStore/GE_CHAT_MODE] 222222222222222222222", this.$store.getters["authStore/GE_CHAT_MODE"])

                  } 
                  if(this.USER_NEWSTAT == "CHATON" && this.check_record == false){
                    console.log("recoidOn")
                    this.$store.commit("authStore/MU_CHAT_MODE", true);
                    this.recordOn();
                  
                  }

                    

                } else {
                  this.showAlertWarn( '상태가 실패 되었습니다.');
                }
            }
          }
        })
        .catch((err) => {
          alert(err);
        })
      }
    },

    async recordOn(){

          let URLData = RDWP_SERVER_URL;

          let params = {
              // station: "1191",
              station: this.$store.getters['userStore/GE_USER_ROLE'].IPCC_NO,
              type: "start"
          };


          try {

            await apiChatPost.chatPost(URLData, params)
            this.screenValue = "스크린녹화ON"
            this.screenStyle1.color = "RED"
            this.screenCss = "is-active"
            this.check_record = false;

          } catch(apiEx) {
            console.log("녹화 시작 중 에러 발생!! - "+apiEx);
          }  
    },

    // chatoff [확인] 클릭
    chatOffYes() {
      console.log("chatOffYes::: " + this.EXEC_RST_CD);
      this.BEFORE_USER_STAT = this.USER_NEWSTAT;

      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      this.changeUserStatChatOff(false); // TemPP
      // if(this.EXEC_RST_CD == '3' || this.EXEC_RST_CD == '4'){
      //   this.saveChat(this.EXEC_RST_CD);
      // } else {
      //   this.saveChatTemp();
      // }
    },

    async recordOff(){
      let URLData = RDWP_SERVER_URL;
      let params = {
          // station: "1191",
          station: this.$store.getters['userStore/GE_USER_ROLE'].IPCC_NO,
          type: "end"
      };      
      try {
        await apiChatPost.chatPost(URLData, params)
        this.screenValue = "스크린녹화OFF"
        this.screenStyle1.color = "black"
        this.screenCss = ""
        this.check_record = false;
      } catch(apiEx) {
        console.log("녹화 종료 중 에러 발생!! - "+apiEx);
        // appendLog("rdwtCloseCall", apiEx);
      }  
    },

    async changeUserStatChatOff(flag){


          let URLData = RDWP_SERVER_URL;
          let params = {
              station: this.$store.getters['userStore/GE_USER_ROLE'].IPCC_NO,
              type: "end"
          };

          try {
            await apiChatPost.chatPost(URLData, params)
            this.screenValue = "스크린녹화OFF"
            this.screenStyle1.color = "black"
            this.screenCss = ""
            this.check_record = false;

          } catch(apiEx) {
            console.log("녹화 종료 중 에러 발생!! - "+apiEx);
            // appendLog("rdwtCloseCall", apiEx);
          }  

          if(flag == true){
            this.USER_NEWSTAT = 'CHATOFF'
          }

        apiChat.post("api/chat/main/cnslr-sttus/regist",   //api url입력
        {
          USER_STATUS_CD: this.USER_NEWSTAT,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_NEWCUST_KEY: this.CUST,
          FLAG: flag
        },
        {head: {
          "SERVICE" : "setting.holiday.manage",
          "METHOD" : "list",
          "TYPE" : "BIZ_SERVICE",
          "ROW_CNT" : "200",
          "PAGES_CNT" : "1",
        }})
        .then((response) => {

            let count     = response.data.header.COUNT;
            let errorFlag = response.data.header.ERROR_FLAG;

            if (count != null && errorFlag == false) {
                if(count >= 0) {
                    if(flag == true){
                      this.showAlertComplete( '자동 채팅 상태 off 되었습니다')
                    }else{
                      this.showAlertComplete( '상태가 변경 되었습니다.')
                      this.$store.commit("authStore/MU_CHAT_MODE", false);
                    console.log("this.$store.getters[chatStore/GE_CHAT_MODE] 3333333333333333333333", this.$store.getters["authStore/GE_CHAT_MODE"])
                    }
                    //console.log("this.dropItemsUserStatCd", this.dropItemsUserStatCd);
                    // if(this.dropItemsUserStatCd == 'CHATON'){
                    //   this.setTalkEnv(); // timeout 정보
                    //   this.connect(); // ready websocket 연결
                    // }
                } else {
                  this.showAlertWarn( '상태가 실패 되었습니다.');
                }
            }
        })
        .catch((err) => {
          alert(err);
        })
      
    },

      //상담 목록 조회
      getTalkList(index, CUR_USER){

        apiChat.post("api/chat/main/cnslt/inqire",   //api url입력
        {
          TYPE: 'ready;talking;callback;post;end',
          SORT_BY: this.sortBy,
          DECENDING: this.decending,
          TALK_USER_KEY: '',
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
        },
        {head: {
        }})
        .then((response) => {
          console.log("결과>>>>>>>>>>>",response.data.data);
          this.chatList = response.data.data
          console.log("결과this.chatList>>>>>>>>>>>",this.chatList);
          console.log("index>>>>>>>>>>>",index);
          
          let today = moment();
          let tempIndex = 0;
          let indexCheck = false
          
          if(this.chatList.length == 0){
            this.noData = true;
          }else{
            this.noData = false;
          }
          for (var i = 0; i < this.chatList.length; i++) {
            this.chatList[i]["index"] = i + 1;
              if(this.chatList[i].TYPE == "system"){
                this.chatList[i].TALK_TITLE = this.chatList[i].TALK_TITLE.replaceAll("\\", "");
              }else{
                this.chatList[i].TALK_TITLE = this.chatList[i].TALK_TITLE.replaceAll("\\\"", "\"");
                this.chatList[i].TALK_TITLE = this.chatList[i].TALK_TITLE.replaceAll("\\n", "\n");
              }                   
              if(this.chatList[i].TYPE =="expired_noresponse" ){
                this.chatList[i].TALK_TITLE = this.chatList[i].TALK_TITLE.replaceAll("\\", "");
                this.chatList[i].TALK_TITLE = this.chatList[i].TALK_TITLE.replaceAll("\\n", "\n");
              }
              if(this.chatList[i].TYPE =="system_noresponse" ){
                this.chatList[i].TALK_TITLE = this.chatList[i].TALK_TITLE.replaceAll("\\", "");
                this.chatList[i].TALK_TITLE = this.chatList[i].TALK_TITLE.replaceAll("\\n", "\n");
              }              

              console.log("this.chatList[i].TALK_USER_KEY ", this.chatList[i].TALK_USER_KEY )
              console.log("this.chatList[i].this.CLK_USER ", this.CLK_USER )

            if(this.chatList[i].TALK_STAT_CD == "12"){
              if(this.chatList[i].UNREAD_CNT > 0 && this.chatList[i].TALK_USER_KEY != this.CLK_USER){
                this.chatList[i].TOT_IS_READ = this.chatList[i].UNREAD_CNT
                this.chatList[i].readClass = "jh-badge is-number is-red"
              }else{
                this.chatList[i].readClass = ""
              }

              var temp = moment(this.chatList[i].LAST_REG_DTTM.substr(11, 8), 'HH:mm:ss' )

              var hour = moment.duration(today.diff(temp)).hours();
              var min = moment.duration(today.diff(temp)).minutes()
              var sec = moment.duration(today.diff(temp)).seconds()

              if(hour < 10) hour = "0" + hour
              if(min < 10) min = "0" + min
              if(sec < 10) sec = "0" + sec 

              // this.chatList[i].LAST_REG_DTTM = moment.duration(today.diff(temp)).hours() + ":" + moment.duration(today.diff(temp)).minutes() + ":" + moment.duration(today.diff(temp)).seconds()
              this.chatList[i].LAST_REG_DTTM = hour + ":" + min + ":" + sec
        
              this.chatList[i].stateClass= 'is-bg-org';
              this.chatList[i]["type"] = 'ing';
              if(this.chatList[i].CUSTOMER_ID === CUR_USER){
                tempIndex = i
                indexCheck = true;
              }
              console.log("this.chatList[i].CUSTOMER_ID  ", this.chatList[i].CUSTOMER_ID)
              console.log("CUR_USER  ", CUR_USER)
              console.log("tempIndex  ", tempIndex)
              console.log("i  ", i)
            }else if(this.chatList[i].TALK_STAT_CD == "21"){
              this.chatList[i].LAST_REG_DTTM = "00:00:00"
              this.chatList[i].stateClass= 'is-bg-purple';              
              this.chatList[i]["type"] = 'done';
            }else if(this.chatList[i].TALK_STAT_CD == "20"){
              this.chatList[i].LAST_REG_DTTM = "00:00:00"
              this.chatList[i].stateClass= 'is-bg-gray';
              this.chatList[i]["type"] = 'after';
              
            }else if(this.chatList[i].TALK_STAT_CD == "11" || this.chatList[i].TALK_STAT_CD == "13"){
              var temp = moment(this.chatList[i].LAST_REG_DTTM.substr(11, 8), 'HH:mm:ss' )

              var hour = moment.duration(today.diff(temp)).hours();
              var min = moment.duration(today.diff(temp)).minutes()
              var sec = moment.duration(today.diff(temp)).seconds()

              if(hour < 10) hour = "0" + hour
              if(min < 10) min = "0" + min
              if(sec < 10) sec = "0" + sec 

              // this.chatList[i].LAST_REG_DTTM = moment.duration(today.diff(temp)).hours() + ":" + moment.duration(today.diff(temp)).minutes() + ":" + moment.duration(today.diff(temp)).seconds()
              this.chatList[i].LAST_REG_DTTM = hour + ":" + min + ":" + sec
        
              this.chatList[i].stateClass= 'is-bg-yellow';
              this.chatList[i]["type"] = 'wait';
            }
          }
            // if(index != undefined && index != ""){
            if(index >= 0){
              console.log("this.chatList[index][type]  :  ", this.chatList[index]["type"])
              if(this.chatList[index]["type"] == 'done' || this.chatList[index]["type"] == 'after'){
                indexCheck = false;
                console.log(indexCheck)
              }
            }

            console.log("indexCheck : ", indexCheck)
            

            if(tempIndex == 0 && indexCheck == false){
              this.activeIndex = index
            }else if(indexCheck == false){
              this.activeIndex = index
            }else{
              this.activeIndex = tempIndex
            }
              console.log("this.activeIndex  ", this.activeIndex)

            this.$store.dispatch("messages/AC_INIT_TALK_LIST", response.data.data).then(() => {
            this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", 
              {selected_stat : this.selected_stat, userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.CUST});
          });

        })
        .catch((err) => {
          console.log("결과<<<<<",err);
          alert(err);
        })
      },

      successLogin(TALK_USER_KEY) {
        this.common_alert(TALK_USER_KEY + ` 고객께서 로그인 인증을 완료하였습니다.`, 'info');
      }, 

      failAcn(CUSTOMER_NM, ERROR_CNT) {
        var CNT = 5 - ERROR_CNT;
        console.log(" 고객 계좌비밀번호 오류입니다. : ", CNT)
        this.common_alert(CUSTOMER_NM + ` 고객 계좌비밀번호 오류입니다.(5회중 ` + CNT + `회남았습니다.)`, 'info');
      },

      successAcn(TALK_USER_KEY) {
        this.common_alert(TALK_USER_KEY + ` 고객께서 계좌번호 인증을 완료하였습니다.`, 'info');
      },
      
      //고객id 조회
      selectCustID(index){
        this.CHAT_MSG = ""
        this.click_index = index

        if(this.chatList[index].IF_KEY != '' && this.chatList[index].IF_KEY != undefined){

          apiChat.post("api/chat/main/cstmr-id/inqire/"+this.chatList[index].TALK_USER_KEY,   //api url입력
          // apiChat.post("api/chat/main/cstmr-acn/inqire/2_cgjN1E8Wy",   //api url입력
          {
            ASP_NEWCUST_KEY: this.CUST,
            ASP_CUST_KEY: this.CUST,
            IF_KEY:       this.chatList[index].IF_KEY,
            action:'api/chat/main/cstmr-id/inqire/'+this.chatList[index].TALK_USER_KEY,
            method:'post'
          },
          {head: {
          }})
          .then((response) => {
            console.log("고객id 조회>>>><<<<",response.data.data);

            if(response.data.data.length > 0){
              if(response.data.data[0].CUST_NO != '' && response.data.data[0].CUST_NO != undefined){
                console.log("selectCustIDselectCustID 111111111111111111111111111")
                this.ifCustInfo.CLNT_NO = response.data.data[0].CUST_NO;
                this.CLNT_NO = response.data.data[0].CUST_NO;
                this.selectCustInfo(index, this.ifCustInfo.CLNT_NO);
              }else{
                console.log("selectCustIDselectCustID 22222222222222222222222222")

                this.prbmStyle = false
                this.USER_NM = ""
                this.SRCH_CL_CID = ""
                this.TEMP_SRCH_CL_CID = ""
                this.PW_YN
                this.AcntPrdtDtlNmAddAcntNo = []
                this.AGE = ""
                this.SEX = ""
                this.CLNT_NO = ""
                this.isMinor = ""
                this.isForeigner = ""         
                this.CUS_LEVEL = ""
                this.MOVE_TNO = ""    
                this.USER_MEMO = ""
                this.CHK_BL = false   

                this.acntClassNm = 'jh-form';
                this.acn_style_check = false;
                this.PW_YN = '';         

                this.clickChat(index,false)
              }
            }else{
                console.log("selectCustIDselectCustID 33333333333333333333333333333")

                this.prbmStyle = false
                this.USER_NM = ""
                this.SRCH_CL_CID = ""
                this.TEMP_SRCH_CL_CID = ""
                this.PW_YN
                this.AcntPrdtDtlNmAddAcntNo = []
                this.AGE = ""
                this.SEX = ""
                this.CLNT_NO = ""
                this.isMinor = ""
                this.isForeigner = ""         
                this.CUS_LEVEL = ""
                this.MOVE_TNO = ""          
                this.USER_MEMO = ""
                this.CHK_BL = false    
                this.acntClassNm = 'jh-form';
                this.acn_style_check = false;
                this.PW_YN = '';                             
              this.clickChat(index,false)
            }



          })
          .catch((err) => {
            alert(err);
          })
        }else{
                console.log("selectCustIDselectCustID 444444444444444444444444444444444")

                this.prbmStyle = false
                this.USER_NM = ""
                this.SRCH_CL_CID = ""
                this.TEMP_SRCH_CL_CID = ""
                this.PW_YN
                this.AcntPrdtDtlNmAddAcntNo = []
                this.AGE = ""
                this.SEX = ""
                this.CLNT_NO = ""
                this.isMinor = ""
                this.isForeigner = ""         
                this.CUS_LEVEL = ""
                this.MOVE_TNO = ""    
                this.USER_MEMO = ""
                this.CHK_BL = false 
                this.acntClassNm = 'jh-form';
                this.acn_style_check = false;
                this.PW_YN = '';                                  
          this.clickChat(index,false)
        }

      },

  async selectCustInfo(index, CLNT_NO) {
      //eventBus.acctDtlSrch(this.ifCustInfo.CLNT_NO);
    
    let params = {
      ACC_KEY: '001',
      ASP_CUST_KEY: '001',
      SRCH_TYPE: 'clnt_no',
    };


    //고객번호
      if(this.mixin_isEmpty(this.ifCustInfo.CLNT_NO) ) {
          this.common_alert(`고객번호를 입력해주세요.`, 'error')
          return false
      }

      if(this.ifCustInfo.CLNT_NO.replaceAll("-","").length < 8) {
          this.common_alert(`고객번호를 확인해주세요.`, 'error')
          return false
      }
    this.ifCustInfo.CLNT_NO = CLNT_NO
    params['SRCH_TYPE'] = 'clnt_no';
    params['CLNT_NO'] = this.ifCustInfo.CLNT_NO
    params['IF_KEY'] = this.chatList[index].IF_KEY

    let URLData = '/api/chat/main/selectCustInfo/inqire';
    let HEADER_SERVICE = 'chat.main.selectCustInfo';
    let HEADER_METHOD = 'inqire';
    let HEADER_TYPE = 'BIZ_SERVICE';

    await api
      .post(URLData, params,
      {
      head: {
        SERVICE: HEADER_SERVICE,
        METHOD: HEADER_METHOD,
        TYPE: HEADER_TYPE,
        ASYNC: false,
      },
      })
      .then((response) => {
          //this.common_alert("성공적으로 조회 했습니다.");
          console.log(">>>>>>>>>>>>>>>>");
          let responseData = JSON.parse(JSON.stringify(response.data.custInfo[0]));
          let resAccData = JSON.parse(JSON.stringify(response.data.DATA));
          let flag = false;
          this.USER_NM = responseData.CNM;
          this.TEMP_SRCH_CL_CID = responseData.SRCH_CL_CID.substr(0, 6).concat("-").concat(responseData.SRCH_CL_CID.substr(6, 1)).concat("******");
          this.SRCH_CL_CID = responseData.SRCH_CL_CID;
          this.AcntPrdtDtlNmAddAcntNo = []
          this.AGE = responseData.AGE
          this.SEX = responseData.SEX
          // this.CLNT_NO = responseData.CLNT_NO
          if(responseData.isMinor){
            this.isMinor = "미성년자"
          }
          if(responseData.isForeigner){
            this.isForeigner = "외국인"         
          }

          this.CUS_LEVEL = responseData.CUS_LEVEL
   
          this.MOVE_TNO = responseData.MOVE_TNO.substr(0, 3).concat("-").concat(responseData.MOVE_TNO.substr(3, 4)).concat("-").concat(responseData.MOVE_TNO.substr(7, 4));       
          this.prbmStyle = true
          console.log("if result^^^^^       " + JSON.stringify(resAccData));
          console.log("if responseData^^^^^       " + JSON.stringify(responseData));
          // console.log("resAccData[0].has(AcntNo)      " + resAccData[0].has("AcntNo"));
          console.log("resAccData[0].has(AcntNo)      " + resAccData[0].hasOwnProperty("AcntNo"));

          if(resAccData[0].hasOwnProperty("AcntNo")){
            for(let i = 0; i < response.data.DATA.length; i ++){
              let tmpAcnt = {};

              console.log("resAccData[i].AcntNo  ", resAccData[i].AcntNo)
              
              // if(resAccData[i].AcntNo.substr(0,1) == 2){
              //   console.log("acn == ", resAccData[i].AcntNo.substr(0,1))
              //   tmpAcnt.CD_NM = resAccData[i].AcntNo.substr(0,3).concat("****").concat(resAccData[i].AcntNo.substr(7,2)).concat("-").concat(resAccData[i].AcntNo.substr(9,2)).concat(resAccData[i].AcntPrdtDtlNm)
              // }else{
              //   tmpAcnt.CD_NM = resAccData[i].AcntNo.substr(0,3).concat("-").concat(resAccData[i].AcntNo.substr(3,2)).concat("-").concat(resAccData[i].AcntNo.substr(5,2).concat("****")).concat(resAccData[i].AcntPrdtDtlNm)
              // }

              tmpAcnt.CD_NM = this.mixin_accNoFormat(resAccData[i].AcntNo)
              if(resAccData[i].AcntHanglAlias.replaceAll(" ", "") != ""){
                // tmpAcnt.CD_NM = resAccData[i].AcntNo.substr(0,9).concat("-").concat(resAccData[i].AcntNo.substr(9,2)).concat(" ").concat(resAccData[i].AcntHanglAlias)
                tmpAcnt.CD_NM = this.mixin_accNoFormat(resAccData[i].AcntNo).concat(" ").concat(resAccData[i].AcntHanglAlias)
              }else{
                // tmpAcnt.CD_NM = resAccData[i].AcntNo.substr(0,9).concat("-").concat(resAccData[i].AcntNo.substr(9,2)).concat(" ").concat(resAccData[i].AcntPrdtDtlNm)
                tmpAcnt.CD_NM = this.mixin_accNoFormat(resAccData[i].AcntNo).concat(" ").concat(resAccData[i].AcntPrdtDtlNm)
              }

              tmpAcnt.CD = resAccData[i].AcntNo
              tmpAcnt.color = "black";

              if(!(resAccData[i].AcntStatCode == "03" || resAccData[i].AcntStatCode == "11")){
                this.AcntPrdtDtlNmAddAcntNo.push(tmpAcnt);
              }
            }
            

            if(this.AcntPrdtDtlNmAddAcntNo.length > 0){
              this.selectNewAcntNo = this.AcntPrdtDtlNmAddAcntNo[0].CD
            }

            console.log("if AcntPrdtDtlNmAddAcntNo^^^^^       " + JSON.stringify(this.AcntPrdtDtlNmAddAcntNo));
          }



          if(responseData.prbmCd1 === 'red' || responseData.prbmCd1 === 'RED') {
              this.prbmStyle1.backgroundcolor = "red"
          } else if(responseData.prbmCd1 === 'black' || responseData.prbmCd1 === 'BLACK') {
              this.prbmStyle1.backgroundcolor = "black"
          } else if(responseData.prbmCd1 === 'yellow' ||responseData.prbmCd1 === 'YELLOW') {
              this.prbmStyle1.backgroundColor= "#fae719"
              this.prbmStyle1.color= "#000"
          }

          if(responseData.prbmCd2 === 'red' || responseData.prbmCd2 === 'RED') {
              this.prbmStyle2.backgroundcolor = "red"
          } else if(responseData.prbmCd2 === 'black' || responseData.prbmCd2 === 'BLACK') {
              this.prbmStyle2.backgroundcolor = "black"
          } else if(responseData.prbmCd2 === 'yellow' ||responseData.prbmCd2 === 'YELLOW') {
              this.prbmStyle2.backgroundColor= "#fae719"
              this.prbmStyle2.color= "#000"

          }

          if(responseData.prbmCd3 === 'red' || responseData.prbmCd3 === 'RED') {
              this.prbmStyle3.backgroundcolor = "red"
          } else if(responseData.prbmCd3 === 'black' || responseData.prbmCd3 === 'BLACK') {
              this.prbmStyle3.backgroundColor = "black"
          } else if(responseData.prbmCd3 === 'yellow' ||responseData.prbmCd3 === 'YELLOW') {
              this.prbmStyle3.backgroundColor= "#fae719"
              this.prbmStyle3.color= "#000"
          }

          if(response.data.DATA.length > 0){
            flag = true;
          }

          this.clickChat(index, flag);

      })
      .catch((err) => {
          this.common_alert('원장 데이터 수신 불가. [IT 담당자 문의요망]', 'error');
          console.log(err);
      });

      //console.log('custInfo =' + JSON.stringify(this.ifCustInfo));
      
      //eventBus.acctDtlSrch(this.ifCustInfo.CLNT_NO);
  },
  // async appendLog(data){

  //               console.log("log");
  //               console.log("log");
  //               console.log("log");
  //               console.log("log");

  //   this.$store.commit("logStore/MU_LOG", data);
  // },


      //고객정보 조회
      getUserAcn(){
        apiChat.post("api/chat/main/cstmr-acn/inqire/"+this.CLK_USER,   //api url입력
        // apiChat.post("api/chat/main/cstmr-acn/inqire/2_cgjN1E8Wy",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CLK_CUST,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cstmr-acn/inqire/'+this.CLK_USER,
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("사용자 계좌 정보>>>><<<<",response.data.data);
          if(response.data.data.length == 0){
            this.acntClassNm = 'jh-form';

            this.acn_style_check = false;
            this.PW_YN = '';
          } 
          for(let i = 0; i < response.data.data.length; i++){
            for(let j = 0; j < this.AcntPrdtDtlNmAddAcntNo.length; j++){
              this.acntClassNm = 'jh-form is-bg-light-yellow';
              this.PW_YN = '****';
              this.accnPwStyle.color = "black";
              // this.accnStyle.color = "black";



              if(this.AcntPrdtDtlNmAddAcntNo[j].CD.substr(0,11) == response.data.data[i].ACN_NO){

                let temp = [];
                temp = this.AcntPrdtDtlNmAddAcntNo[j];
                let temp2 = [];
                temp2 = this.AcntPrdtDtlNmAddAcntNo[i]
                this.AcntPrdtDtlNmAddAcntNo[j] = temp2

                this.AcntPrdtDtlNmAddAcntNo[i] = temp

                this.AcntPrdtDtlNmAddAcntNo[i].color = "red"

                this.AcntPrdtDtlNmAddAcntNo[i].ACN_PW = response.data.data[i].ACN_PW
                this.selectNewAcntNo = this.AcntPrdtDtlNmAddAcntNo[i].CD
                          this.acn_style_check = true;

              }

            }

          }
          console.log("this.AcntPrdtDtlNmAddAcntNo  ", this.AcntPrdtDtlNmAddAcntNo)
          // if(this.AcntPrdtDtlNmAddAcntNo.length > 0){
          //   this.selectNewAcntNo = this.AcntPrdtDtlNmAddAcntNo[0].CD
          // }
          // if(response.data.data.length > 0){
          //   // this.selectNewAcntNo = this.AcntPrdtDtlNmAddAcntNo[this.AcntPrdtDtlNmAddAcntNo.length-1].CD
          //   this.selectNewAcntNo = response.data.data[response.data.data.length - 1].ACN_NO
          // }
  
        })
        .catch((err) => {
          alert(err);
        })
      },
      updateIsReadTalkAll(index){
        var TCI = "";

        if(index == "Y"){
          TCI = this.CLK_CHAT
        }else{
          TCI = this.chatList[index].TALK_CONTACT_ID
        }

        apiChat.post("api/chat/main/all-mssage-redng/process/"+TCI,   //api url입력
        // apiChat.post("api/chat/main/cstmr-acn/inqire/2_cgjN1E8Wy",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CLK_CUST,
          ASP_CUST_KEY: this.CUST,
          IS_READ: 1,
          action:"api/chat/main/all-mssage-redng/process/"+TCI,
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("update success")
        })
        .catch((err) => {
          alert(err);
        })
      },

      //상담 클릭 시 이벤트
      clickChat(index, flag){
        this.chatTrans = false;

        this.CHT_CON = ""
        if(this.chatList[index].SYSMSG_SKIP_YN == "N"){
          this.mssgStop = false;
        }else{
          this.mssgStop = true;
        }
        if(this.chatList[index].TALK_STAT_CD == "12" || this.chatList[index].TALK_STAT_CD == "11" || this.chatList[index].TALK_STAT_CD == "13" || this.chatList[index].TALK_STAT_CD == "14"){
          this.END_CNSL = true
        }else{
          this.END_CNSL = false
        }

        if(this.chatList[index].TALK_STAT_CD == "12"){
          this.updateIsReadTalkAll(index);
        }

        this.CANT_CUST = true;
        this.inqryFullNm = this.chatList[index].TALK_INQRY_FULL_NM;
        if(this.chatList[index].TALK_STAT_CD == "20"){
          this.CANT_MODI = true;
          //this.chatDone = true;
          this.clickDone();
        }else if(this.chatList[index].TALK_STAT_CD == "21"){
          console.log("this.CnslSave  ", this.CnslSave)
          this.chatDone = false;

          this.CnslSave = true;
        } else {
          //this.CANT_MODI = false;
          this.chatDone = false;
          this.chatTemp = false;
          this.clickDoneCancel();
        }        
        this.CLK_CUST=this.chatList[index].ASP_CUST_KEY;
        this.CLK_CHAT=this.chatList[index].TALK_CONTACT_ID;

 
console.log("this.CLK_USER  1111111    ", this.CLK_USER)
console.log("this.CLK_USER  1111111    ", this.CLK_USER)
        this.CLK_USER=this.chatList[index].TALK_USER_KEY;
        this.CLK_SENDER=this.chatList[index].ASP_SENDER_KEY;
        this.CLK_STAT=this.chatList[index].TALK_STAT_CD;
        this.CALL_TYP_CD = this.chatList[index].CALL_TYP_CD;
        this.CLK_MSGSKIP = this.chatList[index].SYSMSG_SKIP_YN;        
        console.log("this.CLK_MSGSKIP   ", this.CLK_MSGSKIP)
        this.CNSL_TY1 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];

         
        // if(this.chatList[index].TALK_STAT_CD == "11"){
          this.getTalkList(index, this.CLK_USER);
        // }
        this.getCnslTY("1", "*");
        // this.getCnslTY("2", this.CNSL_NEWTY1);
        // this.getCnslTY("3", this.CNSL_NEWTY2);

        this.getTalkInfo();
        this.getTalkMsg();
        if(this.CLNT_NO != "" && this.CLNT_NO != "99999999999"){
          this.getTalkHist();
        }else{
          this.getTalkHist("Y");
        }
        this.getUserInfo(flag, index, this.CLK_USER);
        this.getChatScript();
        this.getLink();
        this.getRepeatList();
        this.getUserAcn();

        console.log("flag   ", flag)

        // if(flag == true){
        //   this.regiUserInfo(flag, index, this.CLK_USER)
        // }else if(this.chatList[index].TALK_STAT_CD == "11"){
        //   this.getTalkList(index, this.CLK_USER);
        // }

        // 상담결과 세팅
        if(this.TALK_STAT_CD != "11" && this.TALK_STAT_CD != undefined){
          this.getHistoryDetail();
          console.log("CD::::::" + this.TALK_STAT_CD);
          console.log("HistoryDetail Load!!");
        }                  

        this.$forceUpdate();   

        //추가
        this.toBottomClick ();
        this.activeIndex = index;
      },

      getHistoryDetail(){
        apiChat.post("api/chat/main/result/detail",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CUST,
          TALK_CONTACT_ID: this.TALK_STAT_CD!="13"?this.CLK_CHAT:this.TRANS_TALK_CONTACT_ID,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/result/detail',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("상담결과 정보<<<<<",response.data.data);
          this.CNSL_NEWTY1 = response.data.data[0].CNSL_TYP_CD;
          this.chnCnsl1();
          this.CNSL_NEWTY2 = response.data.data[0].CNSL_TYP_CD_2;
          this.chnCnsl2();
          this.CNSL_NEWTY3 = response.data.data[0].CNSL_TYP_CD_3;
          this.chnCnsl3();
          this.CNSL_NEWTY4 = response.data.data[0].CNSL_TYP_CD_4;
          this.CHT_NEWRESULT = response.data.data[0].EXEC_RST_CD;
          this.CHT_TIT = response.data.data[0].TALK_ANS;
          this.CHT_CON = response.data.data[0].TALK_QST;
        })
        .catch((err) => {
          alert(err);
        })        
      },        

      //상담 정보 조회
      getTalkMsg(){
        apiChat.post("api/chat/main/cnslt-info/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CLK_CUST,
          TALK_CONTACT_ID: this.CLK_CHAT,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cnslt-info/inqire',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("채팅방 정보<<<<<",response.data.data);
          //if(response.data.data[0].SYSMSG_SKIP_YN != '' && response.data.data[0].SYSMSG_SKIP_YN != undefined){
          //  this.CLK_MSGSKIP = response.data.data[0].SYSMSG_SKIP_YN;
          //} else {
          //  this.CLK_MSGSKIP = 'N';
          //}
          //this.CNSL_NEWTY = response.data.data[0].CNSL_TYP_CD;
          //this.CNSL_NEWTY2 = response.data.data[0].CNSL_TYP_CD_2;
          //this.CNSL_NEWTY3 = response.data.data[0].CNSL_TYP_CD_3;
          //this.CNSL_NEWTY4 = response.data.data[0].CNSL_TYP_CD_4;
        })
        .catch((err) => {
          alert(err);
        })
      },

      async getMissingTalkInfo(){
        console.log("getTtalkTalkInfo")

        await apiChat.post("api/chat/missing/cnslt-cn/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CUST,
          TALK_CONTACT_ID: this.CLK_CHAT,
          TALK_USER_KEY: this.CLK_USER,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cnslt-cn/inqire',
          method:'post'
        },
        {head: {
          "ASYNC": true,
          
        }})
        .then((response) => {
          this.missingTalkHistory = response.data.data;
          console.log("미싱채팅정보>>>>>>>>",response.data.data);

          for(var i = 0; i < this.talkHistory.length; i ++){
            for(var j = 0; j < this.missingTalkHistory.length; j ++){
              if(this.talkHistory[i].TALK_SERIAL_NUMBER == this.missingTalkHistory[j].TALK_SERIAL_NUMBER){
                this.talkHistory[i].RESEND = true
              }
            }          
          }

          console.log("resend this.talkHistory : ",this.talkHistory)
          

          return true
        }).catch((err) => {
          alert(err);
        })

      },

      //상담 상세 정보
      async getTalkInfo(){
          var checkMissingList = false;

        await apiChat.post("api/chat/main/cnslt-cn/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CUST,
          TALK_CONTACT_ID: this.CLK_CHAT,
          TALK_USER_KEY: this.CLK_USER,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cnslt-cn/inqire',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("채팅정보>>>>>>>>",response.data.data);
          this.talkHistory = response.data.data;
          if(response.data.data != undefined){
            for(var i = 0; i < this.talkHistory.length; i ++){
              if(this.talkHistory[i].USER_ID == "system"){
                this.talkHistory[i].CONTENT = response.data.data[i].CONTENT.replaceAll("\\", "");
              }else{
                this.talkHistory[i].CONTENT = response.data.data[i].CONTENT.replaceAll("\\\"", "\"");

                this.talkHistory[i].CONTENT = response.data.data[i].CONTENT.replaceAll("\\n", "\n");
              }
              if(this.talkHistory[i].TYPE =="system_noresponse" ){
                this.talkHistory[i].CONTENT = response.data.data[i].CONTENT.replaceAll("\\", "");
                this.talkHistory[i].CONTENT = response.data.data[i].CONTENT.replaceAll("\\n", "\n");
              }

              //  if(this.talkHistory[i].USER_ID == ""){
              //    this.talkHistory[i].USER_ID = "system"
              //  }

              if(this.talkHistory[i].CONTENT == '채팅 상담을 연결합니다.'){
                checkMissingList = true;

                console.log("checkMissingList   : ", checkMissingList)
              }                          

            }


            this.CLK_CHAT = response.data.data[0].TALK_CONTACT_ID;

            var dateTime = new Date(this.talkHistory[0].IT_PROCESSING);
            this.historyDate = dateTime.getFullYear() + "년 " + (dateTime.getMonth() + 1) + "월 " + dateTime.getDate() + "일";                   

            if(response.data.data[0].IN_CODE == 'PH'){
              this.IN_CODE = 'PC.홈페이지'
            }else if(response.data.data[0].IN_CODE == 'MH'){
              this.IN_CODE = 'M.홈페이지'
            }else if(response.data.data[0].IN_CODE == 'HTS'){
              this.IN_CODE = 'HTS'
            }else if(response.data.data[0].IN_CODE == 'MTS'){
              this.IN_CODE = 'MTS-ON'
            }else if(response.data.data[0].IN_CODE == 'ARS'){
              this.IN_CODE = 'ARS'
            }else{
              this.IN_CODE = "카카오톡";
            }

            
            // this.IN_CODE = response.data.data[0].IN_CODE;

            if(response.data.data[0].BOT_INQRY_CD == null || response.data.data[0].BOT_INQRY_CD == ""){

              this.BOT_INQRY_CD = "";

            }else{
              this.BOT_INQRY_CD = response.data.data[0].BOT_INQRY_CD;

            }
            if(response.data.data[0].LOGIN_TYP == null || response.data.data[0].LOGIN_TYP == ""){
              this.LOGIN_TYP = " - "
            }else if(response.data.data[0].LOGIN_TYP == "0" || response.data.data[0].LOGIN_TYP == "N" || response.data.data[0].LOGIN_TYP == "2"){
              this.LOGIN_TYP = "공동인증서";
            }else if(response.data.data[0].LOGIN_TYP == "1" || response.data.data[0].LOGIN_TYP == "Y"){
              this.LOGIN_TYP = "시세전용로그인";
            }else if(response.data.data[0].LOGIN_TYP == "B"){
              this.LOGIN_TYP = "바이오인증";
            }else if(response.data.data[0].LOGIN_TYP == "P"){
              this.LOGIN_TYP = "간편비밀번호";
            }else if(response.data.data[0].LOGIN_TYP == "T"){
              this.LOGIN_TYP = "패턴";
            }else if(response.data.data[0].LOGIN_TYP == "F"){
              this.LOGIN_TYP = "지문";
            }
            
            this.ORI_IN_CODE = response.data.data[0].IN_CODE
            this.ORI_LOGIN_TYPE = response.data.data[0].LOGIN_TYP
            this.ORI_USER_PH_NUM = response.data.data[0].USER_PH_NUM
            // else{
            //   this.LOGIN_TYP = response.data.data[0].LOGIN_TYP;

            // }
            this.USER_PH_NUM = response.data.data[0].USER_PH_NUM.substr(0, 3).concat("-").concat(response.data.data[0].USER_PH_NUM.substr(3, 4)).concat("-").concat(response.data.data[0].USER_PH_NUM.substr(7, 4));

            
          }


          for (var i = 0; i < this.chatList.length; i++) {
            //this.talkHistory[i]["index"] = i + 1;
          }
        })
        .catch((err) => {
          alert(err);
        })

        if(checkMissingList == true){
          await this.getMissingTalkInfo()
        }                      
      },

      //상담 이력 조회
      getTalkHist(val){
        let DET_SRC = ''
        console.log("this.CLNT_NO  ", this.CLNT_NO)

        // if(this.CLNT_NO == ""){
        //   return false
        // }
        console.log("this.CLNT_NO  ", this.CLNT_NO)

        if(this.CLNT_NO == "" && this.CLK_USER == ""){
          return ;
        }

        if(val == "Y"){
          this.CLNT_NO = "99999999999"
        }
        apiChat.post("api/chat/main/cnslt-hist/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CLK_CUST,
          TALK_USER_KEY: this.CLK_USER,
          CUST_NO: this.CLNT_NO,
          CNSL_DIV:'07',
          CSTMR_TELNO:'',
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cnslt-hist/inqire',
          method:'post',
          DET_SRC: DET_SRC,
        },
        {head: {
        }})
        .then((response) => {
          console.log("상담이력<<<<>>>>",response.data.data);
          let LIST = this.chatHistory;
          this.chatHistory = response.data.data;

          for(let i = 0; i < response.data.data.length; i ++){
            response.data.data[i].CAT_CNSL_TYP = this.showCnslTyp(response.data.data[i])
            console.log("response.data.data[i]  ", response.data.data[i]);

          }

          this.dataTableOptions3.headers = this.HistoryHeaders;
          this.dataTableOptions3.items = this.rowNumHistory;

          console.log("HistoryResult::::");
          console.log(this.dataTableOptions3);
          console.log(this.dataTableOptions3.items);

          // for(let i=0;i<response.data.data.length;i++){
          //   if(n == '' || n == null){
          //     this.chatHistory[i]["EXPAND"] = false;
          //   } else{
          //     this.chatHistory[i]["EXPAND"] = LIST[i].EXPAND;
          //     this.chatHistory[n-1]["EXPAND"] = tf;
          //   }
          //   this.chatHistory[i]["ID"] = i+1;
          //   if(response.data.data[i].HST_TYPE == 'kakao'){
          //     this.chatHistory[i]["ICON"] = 'svg-chat-icon-kakao';
          //   } else if(response.data.data[i].HST_TYPE == 'line'){
          //     this.chatHistory[i]["ICON"] = 'svg-chat-icon-kakao';
          //   } else if(response.data.data[i].HST_TYPE == 'wechat'){
          //     this.chatHistory[i]["ICON"] = 'svg-chat-icon-wechat';
          //   } else if(response.data.data[i].HST_TYPE == 'ttalk'){
          //     this.chatHistory[i]["ICON"] = 'svg-chat-icon-ttalk';
          //   }

          //   if(this.chatHistory[i]["PROC_CODE_NM"] == '완결' || this.chatHistory[i]["PROC_CODE_NM"] == '전달'){
          //     this.chatHistory[i]["PROC_CODE"] = 'done';
          //   } else if(this.chatHistory[i]["PROC_CODE_NM"] == '미결'){
          //     this.chatHistory[i]["PROC_CODE"] = 'undone';
          //   } else if(this.chatHistory[i]["PROC_CODE_NM"] == '콜백'){
          //     this.chatHistory[i]["PROC_CODE"] = 'ing';
          //   }    
          // }
        })
        .catch((err) => {
          alert(err);
        })
      },
      
      openP101102(item){
        console.log("popup");
        console.log(item);
        this.getTalkHistList(item.JOBBY_CNSL_HST_ID);
        this.$openWindow('P101102','P101102','500','800');
      },

      //고객정보 조회
      getUserInfo(flag, index, CUR_USER){
        apiChat.post("api/chat/main/cstmr-info/inqire/"+this.CLK_USER,   //api url입력
        {
          ASP_NEWCUST_KEY: this.CLK_CUST,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          CUST_NO: this.CLNT_NO,
          CUSTOMER_ID: '',
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cstmr-info/inqire/'+this.CLK_USER,
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("사용자 정보>>>><<<<",response.data.data);
          // this.USER_NM = response.data.data[0].CUSTOMER_NM
          if(response.data.data.length>0){
              console.log("1111111111111111111111111111111111a");
              console.log("response.data.data[0].MEMO   ", response.data.data[0].MEMO)
            if(response.data.data[0].MEMO ==  ""){
              console.log("사용자 safsanflsanglknsa");
              
              this.USER_MEMO = "";
            }else{
              console.log("21521521521521");

              this.USER_MEMO = response.data.data[0].MEMO
            }
            
            if(response.data.data[0].ATENT_CUSTOMER == 'Y'){
              this.CHK_BL = true;
            }else{
              this.CHK_BL = false;
            }

            if(flag == true){
              this.regiUserInfo(flag, index, CUR_USER)
            }else if(this.chatList[index].TALK_STAT_CD == "11"){
              this.getTalkList(index, CUR_USER);
            }

          }
          // this.MOVE_TNO = response.data.data[0].CUSTOMER_PHONE_NO

            
        })
        .catch((err) => {
          alert(err);
        })
      },

      //스크립트 조회
      getChatScript(){
        let DATE = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
        let ST_DATE = moment(DATE).subtract(1, "year").format("YYYY-MM-DD");
        let END_DATE = DATE;
        api.post("api/chat/script/inqire/list",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CUST,
          USE_TY: 'CHAT',
          START_DATE: ST_DATE,
          END_DATE: END_DATE,
          SEARCH_CONT: this.SEARCH_CONT,
          CNSL_DIV:this.CNSL_NEWDIV,
          REQ_TY: '',
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/script/inqire/list',
          method:'post'
        },
        {head: {
          "SERVICE": "chat.script.inqire.list",
          "METHOD": "list",
          "TYPE": "BIZ_SERVICE",
          "ASYNC": true,
          "PAGES_CNT": 1,
          "ROW_CNT": 100,
        }})
        .then((response) => {
          console.log("스크립트>>>><<<<",response.data.DATA);
          // this.ScriptData = response.data.DATA;
          // for(let i=0;i<this.ScriptData.length;i++){
          //   this.ScriptData[i]["index"] = i+1;
          // }
          for(var i = 0; i < response.data.DATA.length; i++){
            response.data.DATA[i].SCRIPT_RMK = response.data.DATA[i].SCRIPT_RMK.replaceAll("\\r", "");
            response.data.DATA[i].SCRIPT_RMK = response.data.DATA[i].SCRIPT_RMK.replaceAll("&lt;", "<");
            response.data.DATA[i].SCRIPT_RMK = response.data.DATA[i].SCRIPT_RMK.replaceAll("&gt;", ">");
            response.data.DATA[i].SCRIPT_TIT = response.data.DATA[i].SCRIPT_TIT.replaceAll("\\r", "");
            response.data.DATA[i].SCRIPT_TIT = response.data.DATA[i].SCRIPT_TIT.replaceAll("&lt;", "<");
            response.data.DATA[i].SCRIPT_TIT = response.data.DATA[i].SCRIPT_TIT.replaceAll("&gt;", ">");            
          }

          // this.dataTableOptions.items = this.rowNumScript;

          this.dataTableOptions.items = response.data.DATA.map(
            (item, index) => ({
            ...item,
            index: index + 1
          }));
        })
        .catch((err) => {
          alert(err);
        })
      },

      // 스크립트 로우 클릭
      async setScriptDetail(item){
        console.log("here",item);

        this.SCP_ID = item.SCRIPT_ID;

        //this.ASP_SCPCUST_KEY = item.ASP_CUST_KEY;
        //this.SCP_DETTY_NM = item.SCRIPT_NM_1 + ' > ' + item.SCRIPT_NM_2 + ' > ' + item.SCRIPT_NM_3;
        //this.SCP_DET_TIT = item.SCRIPT_TIT;

        await api.post("api/phone/script/inqire/detail",
        {
          ASP_NEWCUST_KEY: this.CUST,
          SCRIPT_ID: item.SCRIPT_ID,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "/api/phone/script/inqire/detail",
          "METHOD" : "detail",
          "TYPE" : "BIZ_SERVICE",
          "ROW_CNT" : 100,
          "PAGES_CNT" : 1,
        }})
        .then((response) => {
          console.log("스크립트 로우 클릭 >>><<<, ", response.data);
          response.data.DATA[0].SCRIPT_TIT = response.data.DATA[0].SCRIPT_TIT.replaceAll("\\r", "");
          response.data.DATA[0].SCRIPT_TIT = response.data.DATA[0].SCRIPT_TIT.replaceAll("&lt;", "<");
          response.data.DATA[0].SCRIPT_TIT = response.data.DATA[0].SCRIPT_TIT.replaceAll("&gt;", ">");

          response.data.DATA[0].SCRIPT_RMK = response.data.DATA[0].SCRIPT_RMK.replaceAll("\\r", "");
          response.data.DATA[0].SCRIPT_RMK = response.data.DATA[0].SCRIPT_RMK.replaceAll("&lt;", "<");
          response.data.DATA[0].SCRIPT_RMK = response.data.DATA[0].SCRIPT_RMK.replaceAll("&gt;", ">");

          response.data.DATA[0].ADD_SHORT_KEY = response.data.DATA[0].ADD_SHORT_KEY.replaceAll("\\r", "");
          response.data.DATA[0].ADD_SHORT_KEY = response.data.DATA[0].ADD_SHORT_KEY.replaceAll("&lt;", "<");
          response.data.DATA[0].ADD_SHORT_KEY = response.data.DATA[0].ADD_SHORT_KEY.replaceAll("&gt;", ">");          

          response.data.DATA[0].NEW_SHORT_KEY = response.data.DATA[0].NEW_SHORT_KEY.replaceAll("\\r", "");
          response.data.DATA[0].NEW_SHORT_KEY = response.data.DATA[0].NEW_SHORT_KEY.replaceAll("&lt;", "<");
          response.data.DATA[0].NEW_SHORT_KEY = response.data.DATA[0].NEW_SHORT_KEY.replaceAll("&gt;", ">");

          this.script_tit = response.data.DATA[0].SCRIPT_TIT;
          this.script_rmk = response.data.DATA[0].SCRIPT_RMK;
          this.ADD_SHORT_KEY = response.data.DATA[0].ADD_SHORT_KEY;
          this.NEW_SHORT_KEY = response.data.DATA[0].NEW_SHORT_KEY;     
          
          console.log("this.script_rmk  ", this.script_rmk)

          //this.SCP_DET_CON = response.data.DATA[0].SCRIPT_RMK;
        })
        .catch((err) => {
          alert(err);
        });
      },

      isActiveRow_SCP(item){
        const activeClass = item.SCRIPT_ID === this.SCP_ID ? 'active' : '';
        return activeClass;
      },

      //상담이력 조회
      getTalkHistList(key){

        apiChat.post("api/chat/main/cnslt-hist/chat-inqire/"+key,   //api url입력
        {
          ASP_NEWCUST_KEY: this.CUST,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cnslt-hist/chat-inqire/'+key,
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("상담이력<<<<>>>>",response.data.data);
          localStorage['CHAT_DETAIL_LIST'] = JSON.stringify(response.data.data);

          console.log("ChatList:::");
          console.log(JSON.parse(localStorage['CHAT_DETAIL_LIST']));
          //this.$forceUpdate();
        })
        .catch((err) => {
          alert(err);
        })
      },

      //고객정보 저장
      regiUserInfo(flag, index, CUR_USER){
        console.log("this.USER_MEMO : ", this.USER_MEMO)
        console.log("this.CHK_BL : ", this.CHK_BL)
        let temp_memo = this.USER_MEMO
        let BL = '';
        if (this.CHK_BL == true){
          BL = 'Y'
        } else if(this.CHK_BL == false){
          BL = 'N'
        }
        apiChat.post("api/chat/main/cstmr-info/regist/"+this.CLK_USER,   //api url입력
        {
          FLAG: flag,
          // ASP_NEWCUST_KEY: this.CLK_CUST,
          ASP_SENDER_KEY: this.CLK_SENDER,
          CUSTOMER_ID: this.CLK_USER,

          CUST_NO: this.CLNT_NO ,
          CUS_LEVEL: this.CUS_LEVEL,

          CUSTOMER_NM: this.USER_NM,
          CUSTOMER_PHONE_NO: this.MOVE_TNO.replace(/\-/g,''),
          ATENT_CUSTOMER: BL,
          MEMO: temp_memo,
          TALK_USER_KEY: this.CLK_USER,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          // ASP_USER_NM: this.$store.getters['userStore/GE_USER_ROLE'].userNm,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cstmr-info/regist/'+this.CLK_USER,
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("사용자 정보>>>><<<<",response.data.data);
          if(flag == false){
            this.success();
          }else{
            this.getTalkList(index, CUR_USER);
          }
        })
        .catch((err) => {
          alert(err);
        })
      },

      //고객정보 저장 확인
      showAlertReg(){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: '고객정보를 저장하시겠습니까?',
          iconClass: 'svg-chk-lg',
          type: 'confirm',
          callYes: this.callYes1,
          callNo: this.callNo1,
        })
      },

      //고객정보 저장 [확인] 클릭
      callYes1() {
        this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
        });
        let flag = false
        this.regiUserInfo(flag);
      },

      //고객정보저장 [취소]클릭
      callNo1(){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
      },

      chatOffNO(){
        this.USER_NEWSTAT = this.BEFORE_USER_STAT;
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
      },
   
      //성공메시지
      success(){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: '정상처리 되었습니다.',
          iconClass: 'svg-done-lg',
          type: 'default'
        })
      },

      showDialog(menu) {
        let dataObj = {ASP_NEWCUST_KEY: this.CUST 
                      , CLK_CHAT:this.CLK_CHAT
                      , CLK_SENDER: this.CLK_SENDER
                      , CLK_CUST: this.CLK_CUST
                      , CLK_USER: this.CLK_USER
                      , USER_NM: this.USER_NM
                      };
        this.Imgdata = dataObj;
        console.log(this.Imgdata);
        this[`Dialog${menu}`] = true;
      },

      hideDialog(menu) {
        this[`Dialog${menu}`] = false;
      },
      /** 경고메시지 */
      showAlertWarn(e){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: e,
          iconClass: 'svg-error-lg',
          type: 'default'
        })
      },
      /** 완료 메세지 */
      showAlertComplete(e){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: e,
          iconClass: 'svg-done-lg',
          type: 'default'
        })
      },
      //시스템 메시지 발송
      SystemMsgSkip(){
        
        this.CLK_MSGSKIP = this.chatList[this.click_index].SYSMSG_SKIP_YN;        

        console.log("message skip yn   ", this.CLK_MSGSKIP)
        let MSGSKIP_YN = '';
        if(this.CLK_MSGSKIP == 'Y'){
          MSGSKIP_YN = 'N';
        } else if(this.CLK_MSGSKIP == 'N'){
          MSGSKIP_YN = 'Y';
        }
        apiChat.post("api/chat/main/un-rspns-mssage-sndng-setup/process",   //api url입력
        {
          TALK_CONTACT_ID: this.CLK_CHAT,
          SYSMSG_SKIP_YN: MSGSKIP_YN,
          ASP_NEWCUST_KEY: this.CUST,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/un-rspns-mssage-sndng-setup/process',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("시스템메시지 발송<<<<>>>>",response.data.data);
          if(MSGSKIP_YN == 'N'){
            this.successSysMsg('시스템 메시지가 발송됩니다.');
            this.CLK_MSGSKIP = 'Y';
            this.mssgStop = false;
            this.getTalkList();
          } else if(MSGSKIP_YN == 'Y'){
            this.successSysMsg('시스템 메시지 발송이 중지되었습니다.');
            this.CLK_MSGSKIP = 'N';
            this.mssgStop = true;
            this.getTalkList();
          }

        })
        .catch((err) => {
          alert(err);
        })
      },

      //시스템 메시지 발송 성공
      successSysMsg(successMsg){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: successMsg,
          iconClass: 'svg-done-lg',
          type: 'default'
        })
      },

      //종료링크 보내기
      sendEndLink(){
        let strMsg = "고객님께 종료 버튼을 전송하였습니다.";
        var MSG_INFO = {'msg' : strMsg, message_type : 'LI', SNDRCV_CD: 'SND', type : 'chatClose'};

        let CHAT_INFO = {'talk_contact_id' : this.CLK_CHAT
                        , 'talk_sender_key' : this.CLK_SENDER
                        , 'call_typ_cd' : this.CALL_TYP_CD
                        , 'aspNewCustKey' : this.CUST
                        , 'talk_user_key': this.CLK_USER
                        , 'SEND_USER_NM' : this.USER_NM
                        , 'token' : this.$store.state.messages.token
                        , 'userId' : this.$store.getters['userStore/GE_USER_ROLE'].userId
                        };

        let chatmsg = this.$store.getters['chatStore/getMsgObj',{
                      user_key: CHAT_INFO.talk_user_key
                      , sndrcv_cd: 'snd'
                      , type: MSG_INFO.message_type
                      , content: MSG_INFO.msg
                      , talk_contact_id: CHAT_INFO.talk_contact_id
                      , IMAGE_TALK_PATH : MSG_INFO.IMAGE_TALK_PATH != '' ? MSG_INFO.IMAGE_TALK_PATH : ''
                      , FILE_GROUP_KEY : MSG_INFO.FILE_GROUP_KEY != '' ? MSG_INFO.FILE_GROUP_KEY : ''
                      , FILE_KEY : MSG_INFO.FILE_KEY != '' ? MSG_INFO.FILE_KEY : ''
                      , FILE_BLOB : MSG_INFO.FILE_BLOB != '' ? MSG_INFO.FILE_BLOB : ''
                      , FILE_EXTS : MSG_INFO.FILE_EXTS != '' ? MSG_INFO.FILE_EXTS : ''
                      //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
                      , SEND_USER_NM : CHAT_INFO.SEND_USER_NM
                      , SEND_USER_SHORT_NM : '나'
                      , consultEnd : MSG_INFO.consultEnd
                      , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                  }];

        let msgObj = this.$store.getters['chatStore/getContactMsg', { talk_contact_id: CHAT_INFO.talk_contact_id }];
        console.log("chatmsg", chatmsg);
        console.log("msgObj", msgObj);

        stompUtil.sendMessage(MSG_INFO,CHAT_INFO,chatmsg,msgObj);
      },

      //전달 시 채팅상담 가능 상담사 목록 조회
      getUserSelect(){
        apiChat.post("api/chat/common-vue/chaton-agents/list",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CUST,
          KEYWORD: '',
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/common-vue/chaton-agents/list',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("사용자 선택박스 생성<<<<>>>>",response.data.data);
          this.USER_ID = response.data.data;
        })
        .catch((err) => {
          alert(err);
        })
      },

      //전달 확인
      confirmTrans(){
        const that = this;
        const filterItem = this.USER_ID.filter(obj => {
          return obj.USER_ID == that.USER_NEWID
        })
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: `${filterItem[0].USER_NICK}(${filterItem[0].USER_ID})님께 채팅을 전달하시겠습니까?`,
          iconClass: 'svg-chk-lg',
          type: 'confirm',
          callYes: this.chatTransToUser,
          callNo: this.callNo1,
        });   
      },

      //채팅 전달
      chatTransToUser(){
        this.talkHistory=[]; 
        apiChat.post("api/stomp/chat/agent/forwarding",   //api url입력
        {
          CNSL_TYP_CD: this.CNSL_NEWTY1,
          CNSL_TYP_CD_2: this.CNSL_NEWTY2,
          CNSL_TYP_CD_3: this.CNSL_NEWTY3,
          CNSL_TYP_CD_4: this.CNSL_NEWTY4,
          EXEC_RST_CD: '3',
          TALK_ANS: this.CHT_TIT,
          TALK_QST: this.CHT_CON,
          TALK_CONTACT_ID: this.CLK_CHAT,
          agent_id: this.USER_NEWID,
          ASP_NEWCUST_KEY: this.CUST,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          USER_NM: this.$store.getters['userStore/GE_USER_NAME'],
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,

          BOT_INQRY_CD: this.BOT_INQRY_CD
          ,USER_PH_NUM: this.ORI_USER_PH_NUM
          ,IF_KEY: this.chatList[this.click_index].IF_KEY
          ,LOGIN_TYP: this.ORI_LOGIN_TYPE
          ,IN_CODE: this.ORI_IN_CODE
          ,PERS_INF_AGG_YN: this.chatList[this.click_index].PERS_INF_AGG_YN
          ,PERS_INF_AGG_DTTM: this.chatList[this.click_index].PERS_INF_AGG_DTTM,


          action:'api/stomp/chat/agent/forwarding',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });

                this.prbmStyle = false
                this.USER_NM = ""
                this.SRCH_CL_CID = ""
                this.TEMP_SRCH_CL_CID = ""
                this.PW_YN
                this.AcntPrdtDtlNmAddAcntNo = []
                this.AGE = ""
                this.SEX = ""
                this.CLNT_NO = ""
                this.isMinor = ""
                this.isForeigner = ""         
                this.CUS_LEVEL = ""
                this.MOVE_TNO = ""    
                this.USER_MEMO = ""
                this.CHK_BL = false   
                this.CLK_CHAT = ""

                this.CLK_USER = ""               

                this.acntClassNm = 'jh-form';
                this.acn_style_check = false;
                this.PW_YN = '';       
                this.talkHistory=[]; 



          this.getTalkList();
        })
        .catch((err) => {
          alert(err);
        });
             
        this.chatTrans = false;
        this.CnslSave = true;
        this.toBottomClick ();
      },
      sintongSearch(){
        console.log("selectNewAcntNo ",this.selectNewAcntNo)
        console.log("this.ifCustInfo.CLNT_NO  ",this.ifCustInfo.CLNT_NO )
        console.log("this.SRCH_CL_CID ",this.SRCH_CL_CID)
        // console.log("this.item.ACN_PW ",this.item.ACN_PW)
        console.log("this.AcntPrdtDtlNmAddAcntNo.ACN_PW ",this.AcntPrdtDtlNmAddAcntNo)
        var TEMP_ACN_PW = "";
        for(let i = 0; i < this.AcntPrdtDtlNmAddAcntNo.length; i++){

        console.log("selectNewAcntNo ",this.selectNewAcntNo)  
        console.log("this.AcntPrdtDtlNmAddAcntNo[i].ACN_PW ",this.AcntPrdtDtlNmAddAcntNo[i].ACN_PW)
        console.log("this.AcntPrdtDtlNmAddAcntNo[i].CD.substr(0,11) ",this.AcntPrdtDtlNmAddAcntNo[i].CD.substr(0,11))
        console.log("this.AcntPrdtDtlNmAddAcntNo[i].CD.substr(0,11) == this.selectNewAcntNo ",this.AcntPrdtDtlNmAddAcntNo[i].CD.substr(0,11) == this.selectNewAcntNo.substr(0,11))


          // if(this.AcntPrdtDtlNmAddAcntNo[j].CD.substr(0,11) == response.data.data[i].ACN_NO){

          if(this.AcntPrdtDtlNmAddAcntNo[i].CD.substr(0,11) === this.selectNewAcntNo.substr(0,11) && this.AcntPrdtDtlNmAddAcntNo[i].ACN_PW != undefined){
              TEMP_ACN_PW = this.AcntPrdtDtlNmAddAcntNo[i].ACN_PW

        console.log("TEMP_ACN_PW ",TEMP_ACN_PW)

          }
        }


        if(TEMP_ACN_PW == null || TEMP_ACN_PW == ""){
          this.accnPwStyle.color = "black";
          this.accnStyle.color = "black";
          this.PW_YN = ""
          this.acntClassNm = 'jh-form';
          this.acn_style_check = false;
          this.sinTongItem.ACCOUNT = this.selectNewAcntNo.substr(0,11) // 계좌번호   
          this.sinTongItem.CLNT_NO = this.ifCustInfo.CLNT_NO    // 고객ID
          this.sinTongItem.CL_CID  = this.SRCH_CL_CID// 실명확인번호
          this.sinTongItem.ACCOUNT_PW  = ""// 계좌 비밀번호
          this.sinTongItem.ENC_TYPE  = "CHAT"// 암호화 구분
             
          this.mixin_sintongSearch(this.sinTongItem);  //신통연동조회
          return;
        }else{

          let geSintongConn  = this.$store.getters["callStore/GE_SINTONG_CLEAR"];
          this.PW_YN = "****";
          this.acntClassNm = 'jh-form is-bg-light-yellow';
          this.accnPwStyle.color = "black";
          this.accnStyle.color = "black";
          this.acn_style_check = true;

          /* 신통연동처리 */
          if(!geSintongConn) {

              this.sinTongItem.ACCOUNT = this.selectNewAcntNo.substr(0,11) // 계좌번호   
              this.sinTongItem.CLNT_NO = this.ifCustInfo.CLNT_NO    // 고객ID
              this.sinTongItem.CL_CID  = this.SRCH_CL_CID// 실명확인번호
              this.sinTongItem.ACCOUNT_PW  = TEMP_ACN_PW// 계좌 비밀번호
              this.sinTongItem.ENC_TYPE  = "CHAT"// 암호화 구분

              this.mixin_sintongSearch(this.sinTongItem);  //신통연동조회
          }          
        }


        // if(geSintongClear){
        //   let item = {
        //     ACCOUNT   : this.selectNewAcntNo// 계좌번호        
        //     ACCOUNT_PW: // 계좌비밀번호
        //     BANK_PW   : // 은행비밀번호
        //     CLNT_NO   : // 고객ID
        //     CL_CID    : // 실명확인번호
        //   }  
        // }
      },  
      //상담유형1
      chnCnsl1() {
        this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.getCnslTY("2", this.CNSL_NEWTY1);
      },
      //상담유형2
      chnCnsl2() {
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.getCnslTY("3", this.CNSL_NEWTY2);
      },
      //상담유형3
      chnCnsl3() {
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.getCnslTY("4", this.CNSL_NEWTY3);
      },

      //사용자 상담유형 콤보박스 생성
      getCnslTY(num, cnsl) {
      if (num == "1") {
        this.CNSL_TY1 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY1 = "";
        this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY2 = "";
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY3 = "";
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = "*";
      } else if (num == "2") {
        this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY2 = "";
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY3 = "";
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = cnsl;
      } else if (num == "3") {
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY3 = "";
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = cnsl;
      } else if (num == "4") {
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = cnsl;
      }
      api
        .post(
          "api/setting/consulttype/manage/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.CUST,
            SPST_CNSL_TYP_CD: cnsl,
            CNSL_DIV_CD: "C",
            USE_YN: "Y",
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "setting.consulttype.manage",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          console.log(response.data.DATA);
          if (num == "1") {
            this.CNSL_TY1 = [...this.CNSL_TY1, ...response.data.DATA];
          } else if (num == "2") {
            if (this.CNSL_NEWTY1 == "") {
              this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY2 = "";
              this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY3 = "";
              this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY4 = "";
            } else {
              this.CNSL_TY2 = [...this.CNSL_TY2, ...response.data.DATA];
            }
          } else if (num == "3") {
            if (this.CNSL_NEWTY2 == "") {
              this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY3 = "";
              this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY4 = "";
            } else {
              this.CNSL_TY3 = [...this.CNSL_TY3, ...response.data.DATA];
            }
          } else if (num == "4") {
            if (this.CNSL_NEWTY3 == "") {
              this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY4 = "";
            } else {
              this.CNSL_TY4 = [...this.CNSL_TY4, ...response.data.DATA];
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
      },

      //상담유형 초기화
      initCnsl(){
        this.CNSL_NEWTY1 = '';
        this.CNSL_NEWTY2 = '';
        this.CNSL_NEWTY3 = '';
        this.CNSL_NEWTY4 = '';
      },

      //메시지 전송
      sendMsg(){
        if(this.chatMsgtLen > 1000){
          this.common_alert(`내용은 1,000Byte를 초과할 수 없습니다.\n현재 ${this.chatMsgtLen}Byte 입력하셨습니다.`, "noti");
          return;
        }

        // let msgcopy = '';

        
        // if(RE_CHAT_MSG != ''&& RE_CHAT_MSG != null && RE_CHAT_MSG != undefined){
          let msgcopy = this.CHAT_MSG.replace(/(?:\r|\r)/g, "");

        // }else{
        //   msgcopy = RE_CHAT_MSG.replace(/(?:\r|\r)/g, "");
        // }

        // let msgcopy = this.CHAT_MSG


        if (msgcopy.replace(/(?:\r\n|\r|\n)/g, '').length === 0) {
          return false;
        }
        console.log("msgcopy    ", msgcopy)

        let MSG_INFO = {'msg' : msgcopy, message_type : 'TX', SNDRCV_CD: 'SND', type : 'message'}
        let CHAT_INFO = {'talk_contact_id' : this.CLK_CHAT
                        , 'talk_sender_key' : this.CLK_SENDER
                        , 'call_typ_cd' : this.CALL_TYP_CD
                        , 'aspNewCustKey' : this.CUST
                        , 'talk_user_key': this.CLK_USER
                        , 'SEND_USER_NM' : this.USER_NM
                        , 'token' : this.$store.state.messages.token
                        , 'userId' : this.$store.getters['userStore/GE_USER_ROLE'].userId
                        };

        let chatmsg = this.$store.getters['messages/GE_MSG_OBJ',{
                      user_key: CHAT_INFO.talk_user_key
                      , sndrcv_cd: 'snd'
                      , type: MSG_INFO.message_type
                      , content: MSG_INFO.msg
                      , talk_contact_id: CHAT_INFO.talk_contact_id
                      , IMAGE_TALK_PATH : MSG_INFO.IMAGE_TALK_PATH != '' ? MSG_INFO.IMAGE_TALK_PATH : ''
                      , FILE_GROUP_KEY : MSG_INFO.FILE_GROUP_KEY != '' ? MSG_INFO.FILE_GROUP_KEY : ''
                      , FILE_KEY : MSG_INFO.FILE_KEY != '' ? MSG_INFO.FILE_KEY : ''
                      , FILE_BLOB : MSG_INFO.FILE_BLOB != '' ? MSG_INFO.FILE_BLOB : ''
                      , FILE_EXTS : MSG_INFO.FILE_EXTS != '' ? MSG_INFO.FILE_EXTS : ''
                      //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
                      , SEND_USER_NM : CHAT_INFO.SEND_USER_NM
                      , SEND_USER_SHORT_NM : '나'
                      , consultEnd : MSG_INFO.consultEnd
                      , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                  }];

        let msgObj = this.$store.getters['messages/GE_CONTACT_MSG', { talk_contact_id: CHAT_INFO.talk_contact_id }];
        console.log("chatmsg", JSON.stringify(chatmsg));
        console.log("msgObj", msgObj);

        stompUtil.sendMessage(MSG_INFO,CHAT_INFO, chatmsg, msgObj)
          this.CHAT_MSG = '';

        this.getTalkList();
        this.getTalkInfo();
      },

      //상담이력 저장(임시저장,후처리,콜백,)
      saveChat(num){
        let TIME = this.computedDateFormatted2;
        let TIME_ARR = TIME.split("-");
        let TIME_ARR_ARR = TIME_ARR[2].split(" ");
        let TIME_ARR_ARR_ARR = TIME_ARR_ARR[1].split(":");


        if(this.mixin_isEmpty(this.CNSL_NEWTY1)) {
          this.common_alert(`상담유형(대) 선택 해주세요.`, 'info');
          return false;
        }
        if(this.mixin_isEmpty(this.CNSL_NEWTY2)) {
          this.common_alert(`상담유형(중) 선택 해주세요.`, 'info');
          return false;
        }
        if(this.mixin_isEmpty(this.CNSL_NEWTY3)) {
          this.common_alert(`상담유형(소) 선택 해주세요.`, 'info');
          return false;
        }


        apiChat.post("api/chat/main/cnslt-hist/regist",   //api url입력
        {
          CNSL_TYP_CD: this.CNSL_NEWTY1,
          CNSL_TYP_CD_2: this.CNSL_NEWTY2,
          CNSL_TYP_CD_3: this.CNSL_NEWTY3,
          CNSL_TYP_CD_4: this.CNSL_NEWTY4,
          EXEC_RST_CD: num,
          TALK_ANS: this.CHT_CON,
          TALK_QST: this.CHT_CON,
          TALK_CONTACT_ID: this.CLK_CHAT,
          TALK_STAT_CD: 12,
          CALLBACK_YMD: TIME_ARR[0]+TIME_ARR[1]+TIME_ARR_ARR[0],//여기여깅겨ㅣㅇ
          CALLBACK_HH: TIME_ARR_ARR_ARR[0],//여기여기여기
          CALLBACK_MM:TIME_ARR_ARR_ARR[1],//여기여기여기
          CNSL_DIV:'07',//여기여기여기
          CSTMR_TELNO: this.MOVE_TNO,//여기여기여기
          CSTMR_ID: this.CLK_USER,//여기여기여기
          ASP_NEWCUST_KEY: this.CUST,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cnslt-hist/regist',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          if(this.CLK_STAT == '12' && num != '4'){
            this.endTalk();
          } else {
            if(num == '4'){
              this.successSysMsg('콜백이 저장되었습니다.');

              this.getTalkList();

              // this.CHT_NEWRESULT='';
              this.CNSL_NEWTY1='';
              this.CNSL_NEWTY2='';
              this.CNSL_NEWTY3='';
              this.CNSL_NEWTY4='';
              this.CHT_TIT='';
              this.CHT_CON='';
              this.date=(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
              this.timeStep='00:00';
            } else {
              this.successSysMsg('상담이력이 저장되었습니다.');

                this.getTalkHist("Y");
                this.prbmStyle = false
                this.USER_NM = ""
                this.SRCH_CL_CID = ""
                this.TEMP_SRCH_CL_CID = ""
                this.PW_YN
                this.AcntPrdtDtlNmAddAcntNo = []
                this.AGE = ""
                this.SEX = ""
                this.CLNT_NO = ""
                this.isMinor = ""
                this.isForeigner = ""         
                this.CUS_LEVEL = ""
                this.MOVE_TNO = ""    
                this.USER_MEMO = ""
                this.CHK_BL = false

                this.acntClassNm = 'jh-form';
                this.acn_style_check = false;
                this.PW_YN = '';         
                
                

              this.chatDone = false
              this.CnslSave = false; 
              this.toBottomClick ();

              this.getTalkList();

              this.talkHistory=[];

              this.CLK_CUST='';
              this.CLK_CHAT='';
              this.CLK_USER='';
              this.CLK_SENDER='';
              this.CLK_MSGSKIP='';
              this.CLK_STAT='';

              // this.CHT_NEWRESULT='';

              this.USER_NM= '';
              this.MOVE_TNO='';
              this.CHK_BL=false;
              this.USER_MEMO='';

              this.chatHistory=[];

              this.CNSL_NEWDIV= '';
              this.SEARCH_CONT='';

              this.gridScriptBody=[];
              this.SCP_DETTY_NM='';
              this.SCP_DET_TIT='';
              this.SCP_DET_CON='';

              this.USER_NEWID= '';

              this.CNSL_NEWTY1='';
              this.CNSL_NEWTY2='';
              this.CNSL_NEWTY3='';
              this.CNSL_NEWTY4='';
              this.CHT_TIT='';
              this.CHT_CON='';

              this.MSG='';
            }

            this.getTalkList();

            this.talkHistory=[];

            this.CLK_CUST='';
            this.CLK_CHAT='';
            this.CLK_USER='';
            this.CLK_SENDER='';
            this.CLK_MSGSKIP='';
            this.CLK_STAT='';

            // this.CHT_NEWRESULT='';

            this.USER_NM= '';
            this.MOVE_TNO='';
            this.CHK_BL=false;
            this.USER_MEMO='';

            this.chatHistory=[];

            this.CNSL_NEWDIV= '';
            this.SEARCH_CONT='';

            this.gridScriptBody=[];
            this.SCP_DETTY_NM='';
            this.SCP_DET_TIT='';
            this.SCP_DET_CON='';

            this.USER_NEWID= '';

            this.CNSL_NEWTY1='';
            this.CNSL_NEWTY2='';
            this.CNSL_NEWTY3='';
            this.CNSL_NEWTY4='';
            this.CHT_TIT='';
            this.CHT_CON='';

            this.MSG='';
          }
          
        })
        .catch((err) => {
          alert(err);
        })
      },

      //임시저장
      saveChatTemp(TEMP_EXEC_RST_CD){
        if(this.mixin_isEmpty(this.CNSL_NEWTY1)) {
          this.common_alert(`상담유형(대) 선택 해주세요.`, 'info');
          return false;
        }
        if(this.mixin_isEmpty(this.CNSL_NEWTY2)) {
          this.common_alert(`상담유형(중) 선택 해주세요.`, 'info');
          return false;
        }
        if(this.mixin_isEmpty(this.CNSL_NEWTY3)) {
          this.common_alert(`상담유형(소) 선택 해주세요.`, 'info');
          return false;
        }
        if(TEMP_EXEC_RST_CD == 1){
          TEMP_EXEC_RST_CD = "5"
        }else if(TEMP_EXEC_RST_CD == 2){
          TEMP_EXEC_RST_CD = "6"
        }else if(TEMP_EXEC_RST_CD == 4){
          TEMP_EXEC_RST_CD = "7"          
        }

        apiChat.post("api/chat/main/cnslt-hist-tmpr/regist",   //api url입력
        {
          CNSL_TYP_CD: this.CNSL_NEWTY1,
          CNSL_TYP_CD_2: this.CNSL_NEWTY2,
          CNSL_TYP_CD_3: this.CNSL_NEWTY3,
          CNSL_TYP_CD_4: this.CNSL_NEWTY4,
          EXEC_RST_CD: TEMP_EXEC_RST_CD,
          TALK_ANS: this.CHT_CON,
          TALK_QST: this.CHT_CON,
          TALK_CONTACT_ID: this.CLK_CHAT,
          ASP_NEWCUST_KEY: this.CUST,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cnslt-hist-tmpr/regist',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          this.successSysMsg('상담이력이 임시저장되었습니다.');
          this.chatTemp=false;
          this.CnslSave=false; 
        })
        .catch((err) => {
          alert(err);
        })
      },

      checkSocket(){
        if(this.USER_NEWSTAT != 'CHATON'){
          return false;
        }
        apiChat.post("api/chat/main/check/socket",   //api url입력
        {
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/check/socket',
          method:'post'
        },
        {head: {
        }})
        .then((response) => {
          console.log("checkSocket : ", response)
          if(response.data.data[0].CNT < 1){
            this.disconnectAlert()
          }

        })
        .catch((err) => {
          alert(err);
        })
      },      

      //상담저장 확인
      confirmSave(num, saveMsg,type){
        console.log("numnumnumnumnumnum ", num)
        this.EXEC_RST_CD = num;
        this.historySaveType = type;
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: saveMsg,
          iconClass: 'svg-chk-lg',
          type: 'confirm',
          callYes: this.callSaveYes,
          callNo: this.callNo1,
        })
      },

      // 상담 저장 [확인] 클릭
      callSaveYes() {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });

        if(this.historySaveType != "temp"){
          this.saveChat(this.EXEC_RST_CD); // TemPP
        }else {
          this.saveChatTemp(this.EXEC_RST_CD);
        }

      },

      //
      //220530 웹소켓 이벤트 추가
       
      /**
        * websocket connect 
        * @param connectCallback   연결 성공 콜백 
        * @param errorCallback     연결 실패 콜백 ( 재연결 시도 )
        */
      connect() {
          
          let geUserId = this.$store.getters["userStore/GE_USER_ID"];

          //const subScribeUrl = this.baseSocketUrl + "queue/ready/" + geUserId;                //"http://local.hkpalette.com:8443/queue/ready/"
          let subScribeUrl = "/queue/ready/" + geUserId; 

          let chatMessage = {
            chatType: "READY",
            userId: geUserId,
            userName: geUserId,
            userNickname: geUserId
          };
          
          stompUtil.token         = this.$store.state.messages.token;                                //SOCKET 연결 시 사용자 SOCKET Token 세팅 
          stompUtil.userId        = geUserId;
          stompUtil.aspNewCustKey = this.CUST;

          stompUtil.connect(() => {   // 연결성공( 재연결 포함 )
                  
            //this.connected = true;
            console.log("nononon>>>");
            
            stompUtil.subscribe(subScribeUrl, chatMessage); 
            let subList = this.$store.getters['messages/GE_SUBSCRIPTION_LIST'];   // ready uri sub
            console.log("subList!!!!", subList)
            stompUtil.subscribeAfterReconnect(subList);      // 재연결 후 기존 sub 목록을다시 sub 해줌.최초는 대상이 없으므로 무관
            
            this.getTalkers();                    // 웹소켓 연결 후 상담목록 조회 
                  
            ///// 선택된 상담이 잇다면 재조회 (재연결시...)
            ////if( this.store.getters.getContactInfo().talk_contact_id != null && .getters.getContactInfo().talk_contact_id != '' ) {
            /////  eventBus.refreshChatContent(this.store.getters.getContactInfo().talk_contact_id); 
            ////}
          }, 
          (error) => { //연결실패 

            if( error != null) {

              //console.log(error);
              stompUtil.setConnected(false);

              // 재연결 시도 
              setTimeout(this.connect, 3000);
            }
          }
        );
      },




      // async appendLog(sFuncNm, objData){

      //   let date = new Date();

      //   let Y = date.getFullYear();
      //   let M = date.getMonth();
      //   let D = date.getDate();
      //   let H = date.getHours();
      //   let Min = date.getMinutes();
      //   let S = date.getSeconds();

      //   H = H < 10 ? ('0' + H) : H;
      //   Min = Min < 10 ? ('0' + Min) : Min;
      //   S = S < 10 ? ('0' + S) : S;

      //   try {
      //     let data = this.$store.getters["logStore/GE_LOG"];
          
      //     data = data + "시간 : " + Y + ' ' + M + ' ' + D + '  ' + H + ':' + Min + ":" + S + "    |     api ["+sFuncNm+"]:"
      //               + JSON.stringify(objData) + "\n";

      //     this.$store.commit("logStore/MU_LOG", data);
      //   } catch(ex) {
      //     console.log(">>>>apped 하다가 에러남??[" + ex + "]");
      //   }
      // },

      disconnectAlert(){
        this.common_alert("서버와 연결이 끊어졌습니다. 채팅상담메인화면 종료 후 다시 열어주세요.", 'error');
      },






      // 웹소켓 연결종료
      async disconnect() {



          let URLData = RDWP_SERVER_URL;
          let params = {
            // data: {
              station: this.$store.getters['userStore/GE_USER_ROLE'].IPCC_NO,
              type: "end"
            // }
          };

          // try {
          //   await apiChatPost.chatPost(URLData, params)
          //   this.screenValue = "스크린녹화OFF"
          //   this.screenStyle1.color = "black"
          // } catch(apiEx) {
          //   console.log("녹화 종료 중 에러 발생!! - "+apiEx);
          //   // appendLog("rdwtCloseCall", apiEx);
          // }  


        
        if (stompUtil.isConnected()) {
          stompUtil.disconnect();
        }
      },

      /**
       * ready callback 함수  
       */
      readyCallback(message) {
        
        console.log("M110101 readyCallback message.body >>>> " + message.body);

        if (typeof message.body !== "undefined") {

          let recvJson = JSON.parse(message.body);
          let data     = JSON.parse(recvJson.telewebJsonString);
          let uri      = message.headers.destination;
          
          console.log("M110101 readyCallback recvJson.chatType >>>> " + recvJson.chatType);

          if (recvJson.chatType == "READY" && recvJson.chatEvent == "AGENT_READY") {
            let sessionId = recvJson.sessionId;
            this.$store.state.messages.simsessionId = sessionId;        
          }
          
          console.log("M110101 readyCallback data.DATA.length >>>> " + data.DATA.length);

          if (data.DATA != null && data.DATA.length > 0) {

            let i = 0;
            let dataLen = data.DATA.length;

            for (; i < dataLen; i++) {
                        
              let talkUserKey = data.DATA[i].TALK_USER_KEY;
              let userKey = data.DATA[i].user_key; 
              
              console.log("M110101 readyCallback talkUserKey >>>> " + talkUserKey);

              if (dataLen > 0 && ((talkUserKey != null && talkUserKey) || (userKey != null && userKey))) {
                
                // 상담종료 메시지 보여줌. unsubscription ( 필요없음 redis 삭제 되므로.)
                if (recvJson.chatEvent == 'EXPIRED_SESSION_CUST') {
                  
                  data.DATA[i].uri = uri;
                  
                  this.$store.dispatch("messages/AC_SPICE_TALKER", data.DATA[i]).then(() => {
                    this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.CUST});
                  });     // 목록 삭제 (구독해제 포함)

                  //Vue.prototype.store.dispatch('spliceTalker', data.DATA[i]).then(() => {
                  //  Vue.prototype.store.dispatch("changeTalkProcessInfo", {selected_stat : ''});
                  //});     // 목록 삭제 (구독해제 포함)
                }   
                else {
                  
                  console.log("M110101 readyCallback talkUserKey else >>>> ");
                      
                  // data.DATA[i].TALK_STAT_NM = "대기중";
                  data.DATA[i].TALK_STAT_NM = this.mixin_get_talk_statnm(data.DATA[i].TALK_STAT_CD);
                  data.DATA[i].USER_ID = recvJson.userId;
                  data.DATA[i].AUTO_GREETING_YN = data.DATA[i].TALK_STAT_CD == '11' || data.DATA[i].TALK_STAT_CD == '13'? 'N' : 'Y';

                  this.$store.dispatch("messages/AC_UN_SHIFT_TALKER", data.DATA[i]).then(() => {
                    this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.CUST});
                  }); 

                  //Vue.prototype.store.dispatch('unshiftTalker', data.DATA[i]).then(() => {
                  //  Vue.prototype.store.dispatch("changeTalkProcessInfo", {selected_stat : ''});
                  //});

                  this.getTalkers();      //신규 메시지를 받으면 새로고침
                  this.getTalkList();
                }
              }
            }
          }
        }
      },

      /**
       *  inout callback 함수  
       */   
      inoutCallback(message) {
            
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 13");

        console.log("Redis on!!");

        if (typeof (message.body) !== "undefined") {
 
          let recvJson = JSON.parse(message.body);
          let data = JSON.parse(recvJson.telewebJsonString);
          let uri = message.headers.destination;
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   ", JSON.stringify(data.DATA[0]));
                 
          if (data.DATA.length > 0 && data.DATA[0].user_key != null && data.DATA[0].user_key != '') {
 
            data.DATA[0].uri = uri;
 
            if (data.DATA[0].SNDRCV_CD == null || data.DATA[0].SNDRCV_CD == '') {
              data.DATA[0].sndrcv_cd = 'rcv';
            }
 
            // 자신이 보낸 메시지는 스킵 처리 한다.( 구독자 모두에게 다시 전송 되므로..)
            if (data.DATA[0].SNDRCV_CD == 'SND' && data.DATA[0].USER_ID == this.$store.getters["messages/GE_USER_ID"]) {   //Vue.prototype.store.getters.getUserId()
              return;
            }
                     
            // 제3자 채팅
            if (data.DATA[0].SNDRCV_CD == 'TRAN') {
              data.DATA[0].content = data.DATA[0].message;
              this.common_alert(data.DATA[0].message, 'error');
            } 
                     
            // 무응답은 error_msg 가 메시지 내용임.
            console.log("obj.inoutCallback >> " + 
            recvJson.attachment + '-' + 
            recvJson.org_cont_id  + '-' + 
            data.DATA[0].attachment + '-' + 
            data.DATA[0].org_cont_id  + '-' +
            recvJson.chatEvent  );
            if (recvJson.chatEvent == 'NO_CUSTOMER_RESPONSE' || recvJson.chatEvent == 'EXPIRED_AGENT_SESSION') {
              data.DATA[0].content = data.HEADER.ERROR_MSG;
              data.DATA[0].sndrcv_cd = 'snd';
              data.DATA[0].USER_NAME = 'SYSTEM';
            }

            if (typeof data.DATA[0].content == "object" || Array.isArray(data.DATA[0].content)){
              data.DATA[0].content = JSON.stringify(data.DATA[0].content);
            }
 
            let msg = this.$store.getters["messages/GE_MSG_OBJ"](data.DATA[0]);                                                             // Vue.prototype.store.getters.getMsgObj(data.DATA[0]);
            let msgObj = this.$store.getters["messages/GE_CONTACT_MSG"]({ talk_contact_id: data.DATA[0].TALK_CONTACT_ID });                 //Vue.prototype.store.getters.getContactMsg
                     
            console.log("this.CLK_CHAT : ", this.CLK_CHAT)
            console.log("this.CLK_CHAT : ", this.CLK_CHAT)
            console.log("this.CLK_CHAT : ", this.CLK_CHAT)
            console.log("this.CLK_CHAT : ", this.CLK_CHAT)
            console.log("this.CLK_CHAT : ", this.CLK_CHAT)
            console.log("this.CLK_CHAT : ", this.CLK_CHAT)
            console.log("this.CLK_CHAT : ", this.CLK_CHAT)
            console.log("this.CLK_CHAT : ", this.CLK_CHAT)
            console.log("this.CLK_CHAT : ", this.CLK_CHAT)
            if(data.DATA[0].TALK_CONTACT_ID == this.CLK_CHAT){
              console.log("in")
              console.log("in")
              console.log("in")
              console.log("in")
              console.log("in")
              console.log("in")
              console.log("in")
              this.updateIsReadTalkAll("Y")
            }

            // 상담종료 메시지 보여줌. unsubscription ( 필요없음 redis 삭제 되므로.)
            if (recvJson.chatEvent == 'EXPIRED_SESSION_CUST') {
 
              // 상담중 완료
              let talkerObj = _.find(this.$store.getters["messages/GE_TALK_LIST"], { 'TALK_CONTACT_ID': data.DATA[0].TALK_CONTACT_ID });    //Vue.prototype.store.getters.getTalkList()
              
              console.log("obj.EXPIRED_SESSION_CUST >> " + talkerObj + '-' + data.DATA[0].TALK_CONTACT_ID);
              if (talkerObj != null && talkerObj != '' && data.DATA[0].TALK_CONTACT_ID != null) {     // 이미 상담 시작됨.
                
                talkerObj.TALK_STAT_CD = data.DATA[0].TALK_STAT_CD
                //talkerObj.TALK_STAT_NM = this.getTalkStatName(data.DATA[0].TALK_STAT_CD);
                talkerObj.TALK_STAT_NM = this.mixin_get_talk_statnm(data.DATA[0].TALK_STAT_CD);
 
                msg.msg_notice = "상담이 종료되었습니다. 상담이력을 저장해 주시기 바랍니다.";  

                this.$store.dispatch("messages/AC_SUB_TALKER", data.DATA[0]).then(() => {
                  this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO" , {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.CUST});
                });     // 구독해제

                //Vue.prototype.store.dispatch('AC_SUB_TALKER', data.DATA[0]).then(() => {
                //  Vue.prototype.store.dispatch("AC_CHANGE_TALK_PROCESS_INFO" , {selected_stat : ''});
                //});     // 구독해제

              } else {    // 대기중         
                             
                this.$store.dispatch("messages/AC_SPICE_TALKER", data.DATA[0]).then(() => {
                  this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO" , {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.CUST});
                });     // // 대기중 완료 목록 삭제 (구독해제 포함)    

                //Vue.prototype.store.dispatch('spliceTalker', data.DATA[0]).then(() => {
                //  Vue.prototype.store.dispatch("changeTalkProcessInfo", {selected_stat : ''});
                //});     // // 대기중 완료 목록 삭제 (구독해제 포함)    
              }
                         
              // 현재 선택 채팅이 변경 된 경우만 변경 처리  this.$store.state.messages.talkContactInfo.talk_contact_id
              if( data.DATA[0].TALK_CONTACT_ID == this.$store.state.messages.talkContactInfo.talk_contact_id) {
                this.changeChatInfo();

              }
              if(data.DATA[0].user_key == this.CLK_USER){
                this.clickDone();

              }
              
              this.CLK_STAT = data.DATA[0].TALK_STAT_CD;
              window.focus();
            }
            else if (recvJson.chatEvent == 'EXPIRED_AGENT_SESSION') {         // 전달완료 (목록에서 삭제 )

              this.$store.dispatch("messages/AC_SPICE_TALKER",data.DATA[0]).then(() => {
                this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.CUST});
 
                if( data.DATA[0].TALK_CONTACT_ID == this.$store.state.messages.talkContactInfo.talk_contact_id) {
                  this.changeChatInfo();
                }
              });     // 목록 삭제 (구독해제 포함)    

              //Vue.prototype.store.dispatch('spliceTalker', data.DATA[0]).then(() => {
              //  Vue.prototype.store.dispatch("AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.getAspCustKey()});
              //  if( data.DATA[0].TALK_CONTACT_ID == Vue.prototype.store.state.messages.talkContactInfo.talk_contact_id) {
              //    eventBus.changeChatInfo(()=>{});
              //  }
              //});     // 목록 삭제 (구독해제 포함)
                         
              return;
            }    

            this.$store.dispatch("messages/AC_USER_CHAT_MESSAGE", { "msg": msg, "msgObj": msgObj, ASP_NEWCUST_KEY : this.CUST})
            //Vue.prototype.store.dispatch("userchatMessage", { "msg": msg, "msgObj": msgObj });
                     
          }
        }
      },
      
      /****************************************************************************************************************************************************
         * 챗 Stomp변수 / 함수 정보 END 
       ****************************************************************************************************************************************************
       ***************************************************************************************************************************************************/

      /****************************************************************************************************************************************************
         * 챗 변수 / 함수 정보 START
       ***************************************************************************************************************************************************/
      

      /** 챗 사용자정보 세팅 */
      setChatUserInfo() {
        
        apiChat.post("api/stomp/chat/userinfo", {
          USER_ID: this.$store.getters['userStore/GE_USER_ID'],
          USER_NM: this.$store.getters['userStore/GE_USER_NAME']
        })
        .then((ret) => {

          // 서버 error 체크 
          if(ret.data.error != null && ret.data.error != '') {

              if(ret.data.error == '-999') {
                this.showAlertWarn( '챗 상담 사용자 정보 Error(Talk Token)');    
              }
          } 
          else {
            
            this.$store.state.messages.token = ret.data.token;
            this.$store.state.messages.userId = ret.data.id;
            this.$store.state.messages.userNm = ret.data.name;
            this.$store.state.messages.aspCustKey = ret.data.aspCustKey;
            
            this.setTalkEnv();  // timeout 정보 
            this.connect();     // ready websocket 연결 
          }
        });
      },

      /** 상담설정 관련 정보 get */ 
      setTalkEnv() {

        apiChat.post("api/chat/common-vue/env/inqry", {
          ASP_NEWCUST_KEY : this.CUST
        })
        .then((ret) => {

          // 서버 error 체크 
          if(ret.data.error != null && ret.data.error != '') {

              if(ret.data.error == '-999') {
                this.showAlertWarn( '챗 상담 사용자 정보 Error(Talk Token)');    
              }
          } 
          else {
            
            let retObj = Object.values(ret.data.data);

            if(retObj != null && retObj.length > 0) {
               this.$store.state.readyTimeout  = retObj[0].RETRY_READY_MAX_TIMEOUT;
            }

            if(retObj != null && retObj.length > 0) {
              this.$store.state.messages.blobYn  = retObj[0].BLOB_YN;
            }  
          }
        });
      },

      changeChatInfo() {

        this.getTalkMsg((ret) => {
             
          if(ret != null && ret != '')   {
            let talkContactInfo = {
              talk_contact_id : ret.TALK_CONTACT_ID,
              talk_user_key   : ret.TALK_USER_KEY,
              talk_sender_key : ret.TALK_SENDER_KEY,
              call_typ_cd     : ret.CALL_TYP_CD,
              user_id			: ret.USER_ID
            };

            this.callTypCd = ret.CALL_TYP_CD;
                  
            if( stompUtil.stompClient == null || stompUtil.stompClient == '')	{
              stompUtil.connect(() => {}, 
                          (error) => {
                              if( error != null) {
                                  //console.log(error);
                                  //this.connected = false;
                              }
                          }, talkContactInfo);
            }
                  
            // 상담이력폼 초기화 
            ///// Arthur.Kim if(this.cnslSaveFrame && this.$refs.talkHistoryForm != null) {
            ///// Arthur.Kim   this.$refs.talkHistoryForm.initForm(this.talkContactId);
            ///// Arthur.Kim }  

            this.msg = "";  // 초기화 
          }

          next();

            });
      },

      /** 채팅 클릭시 채팅정보 검색 */
      getTalkers() {

        apiChat.post("api/chat/main/cnslt/inqire", {
          TYPE: 'ready;talking;callback;post',
          SORT_BY: 'TALK_DIST_DT',
          DECENDING: 'ASC',
          TALK_USER_KEY: '',
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
        })
        .then((ret) => {

          // 서버 error 체크 
          if(ret.data.error != null && ret.data.error != '') {

              if(ret.data.error == '-999') {
                this.showAlertWarn( '상세 목록 조회 Error');    
              }
          } 
          else {
            
            // this.$store.dispatch("messages/AC_SPICE_TALKER", data.DATA[i])
            if( ret.data.code >= 0) {

              this.$store.dispatch("messages/AC_INIT_TALK_LIST", ret.data.data).then(() => {
                  this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : this.selected_stat, userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.CUST});
              });
            }
          }  
        });
      },
      
      //220530 웹소켓 이벤트 추가
      //

      //추가
      sortClick: function (i) { 
          switch(i){
            case "0":
                let icon = this.sortIcon_0;
                if(icon == 'mdi-arrow-up'){
                    this.sortIcon_0='mdi-arrow-down';
                    this.decending = "DESC";
                }else{
                    this.sortIcon_0='mdi-arrow-up';
                    this.decending = "ASC";
                }     
                this.sortBy = "TALK_TIME";           
              break;
            case "1":
                let icon1 = this.sortIcon_1;
                if(icon1 == 'mdi-arrow-up'){
                    this.sortIcon_1='mdi-arrow-down';
                    this.decending = "DESC";
                }else{
                    this.sortIcon_1='mdi-arrow-up';
                    this.decending = "ASC";
                }
                this.sortBy = "TALK_DIST_DT";                       
              break;
          }

          this.getTalkList();                    // 웹소켓 연결 후 상담목록 조회
      },
      isActiveRow(item){
        console.log("item.index  ", item.index)
        console.log("item.UNTACT_ID  ", item.UNTACT_ID)
        console.log("this.LinkData[item.index].UNTACT_ID  ", this.LinkData[item.index].UNTACT_ID)
        return item.UNTACT_ID== this.LinkData[item.index].UNTACT_ID? 'is-active':'';
      },




      
      async clickTemp(){

        console.log("getTtalkTalkInfo")

        await apiChat.post("api/chat/main/cnslt-hist-tmpr/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CUST,
          TALK_CONTACT_ID: this.CLK_CHAT,
          TALK_USER_KEY: this.CLK_USER,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cnslt-cn/inqire',
          method:'post'
        },
        {head: {
          "ASYNC": true,
          
        }})
        .then((response) => {
          

          // response.data.data;
          console.log("zzzzzzzzzzzzzzzzzzzzzzzz정보>>>>>>>>",response.data.data);

          if(response.data.data[0].CNSL_TYP_CD != ''){
            // this.CNSL_NEWTY1 = response.data.data[0].CNSL_TYP_CD
            // this.CNSL_NEWTY2 = response.data.data[0].CNSL_TYP_CD_2
            // this.CNSL_NEWTY3 = response.data.data[0].CNSL_TYP_CD_3
            this.CHT_CON = response.data.data[0].TALK_ANS
            this.CHT_NEWRESULT = response.data.data[0].EXEC_RST_CD
            this.changeCallType(response.data.data[0].CNSL_TYP_CD_3)
          }
          
          // CNSL_TYP_CD: this.CNSL_NEWTY1,
          // CNSL_TYP_CD_2: this.CNSL_NEWTY2,
          // CNSL_TYP_CD_3: this.CNSL_NEWTY3,
          // CNSL_TYP_CD_4: this.CNSL_NEWTY4,
          // EXEC_RST_CD: '',
          // TALK_ANS: this.CHT_CON,
          // TALK_QST: this.CHT_CON,
          
          
          this.chatTemp = true;
          this.CnslSave = true;
          this.toBottomClick ();

          return true
        }).catch((err) => {
          alert(err);
        })














        
        // this.chatTemp = true;
        // this.CnslSave = true;
        // this.toBottomClick ();
      },
      clickTempCancel: function (){
        this.chatTemp = false;
        this.CnslSave = false;  
        this.toBottomClick ();  
        this.initCnsl();
      },
      // clickTempSave: function (){
      //   this.chatTemp = false;
      //   this.CnslSave = false;
      //   this.toBottomClick ();
      //   this.confirmSave('','상담을 임시저장하시겠습니까?');
      // },
      async clickDone(){    
        
        

        console.log("getTtalkTalkInfo")

        await apiChat.post("api/chat/main/cnslt-hist-tmpr/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.CUST,
          TALK_CONTACT_ID: this.CLK_CHAT,
          TALK_USER_KEY: this.CLK_USER,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.CUST,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          action:'api/chat/main/cnslt-cn/inqire',
          method:'post'
        },
        {head: {
          "ASYNC": true,
          
        }})
        .then((response) => {
          

          // response.data.data;
          console.log("zzzzzzzzzzzzzzzzzzzzzzzz정보>>>>>>>>",response.data.data);

          if(response.data.data[0].CNSL_TYP_CD != ''){
            // this.CNSL_NEWTY1 = response.data.data[0].CNSL_TYP_CD
            // this.CNSL_NEWTY2 = response.data.data[0].CNSL_TYP_CD_2
            // this.CNSL_NEWTY3 = response.data.data[0].CNSL_TYP_CD_3
            this.CHT_CON = response.data.data[0].TALK_ANS
            if(response.data.data[0].EXEC_RST_CD == 5){
              response.data.data[0].EXEC_RST_CD = "1"
            }else if(response.data.data[0].EXEC_RST_CD == 6){
              response.data.data[0].EXEC_RST_CD = "2"
            }else if(response.data.data[0].EXEC_RST_CD == 7){
              response.data.data[0].EXEC_RST_CD = "4"
            }
            this.CHT_NEWRESULT = response.data.data[0].EXEC_RST_CD
            this.changeCallType(response.data.data[0].CNSL_TYP_CD_3)
          }
          
          // CNSL_TYP_CD: this.CNSL_NEWTY1,
          // CNSL_TYP_CD_2: this.CNSL_NEWTY2,
          // CNSL_TYP_CD_3: this.CNSL_NEWTY3,
          // CNSL_TYP_CD_4: this.CNSL_NEWTY4,
          // EXEC_RST_CD: '',
          // TALK_ANS: this.CHT_CON,
          // TALK_QST: this.CHT_CON,
          
          
          this.chatDone = true; 
          this.CnslSave = true;  
          this.toBottomClick ();
          return true
        }).catch((err) => {
          alert(err);
        })        
        // this.chatDone = true; 
        // this.CnslSave = true;  
        // this.toBottomClick ();
      },
      clickDoneCancel: function (){
        this.chatDone = false; 
        this.CnslSave = false; 
        this.toBottomClick ();  
        this.initCnsl(); 
      },
      // clickDoneSave: function (){
      //   this.ChatDone = false; 
      //   this.CnslSave = false; 
      //   this.toBottomClick ();
      // },
      clickTransfer: function (){
        this.getUserSelect();
        this.chatTrans = true;
        this.CnslSave = true;
        this.toBottomClick ();
      },
      clickTransferCancel: function (){
        this.chatTrans = false;
        this.CnslSave = false;
        this.toBottomClick ();
        this.initCnsl();
      },
      clickTransferSave: function (){
        this.chatTrans = false;
        this.CnslSave = false;
        this.toBottomClick ();
      },

      toBottomClick() {
        const element = document.getElementById("scrollMe");       
        setTimeout(() => { 
          element.scrollIntoView({behavior: "smooth"});
          this.ToBottomButton = false;  
        }, 500);
      },     
      onScroll (e) {
          var scrollTop = e.target.scrollTop;
          var innerHeight = e.target.clientHeight;
          var scrollHeight = e.target.scrollHeight;
          if(scrollTop + innerHeight >= scrollHeight -70){
              this.ToBottomButton = false;
          }else{
              this.ToBottomButton = true;
          }
      },
      showToBottom (){            
        var container = document.querySelector("#scroll-target");
        var innerHeight = container.clientHeight;
        var scrollHeight = container.scrollHeight;
        if(innerHeight < scrollHeight){
            this.ToBottomButton = true;
        }else{
            this.ToBottomButton = false;
        }
      },
   
      rowClick: function (item, row) { 

        if(this.selectedId===item.index){
          row.select(false);
          this.selectedId = item.SCRIPT_TIT;
          this.scriptDetail = false;
        }else{
          row.select(true);
          this.selectedId = item.index;
          this.scriptId = item.SCRIPT_ID;
          this.scriptDetail = true;
          this.setScriptDetail(item);
        }
        
      },
      rowClick2: function (item, row) { 
        console.log("row  ", row)
        row.select(true)
        // if(this.selectedId===item.index){
        //   row.select(false);
        //   this.selectedId = item.BTN_NM;
        // }else{
        //   row.select(true);
        //   this.selectedId = item.index;
        // }        
      },      
      rowHistoryClick: function (item, row) { 
        //this.CNSL_HIST_ID = item.JOBBY_CNSL_HST_ID;
        if(this.selectedId===item.index){
          row.select(false);
          row.expand(false);
          this.selectedId=item.name;
        }else{
          row.select(true);
          this.selectedId=item.index;
          row.expand(true);
        }
      },        
      onUpdateExpanded: function(expanded) {
        console.log("onUpdateExpanded", expanded);
        this.$nextTick(() => {
          console.log(this.dataTable3Instance.getExpandedData()); // 확장한 데이터 가져오기
        });
      },

      /**
       * 이미지 전체보기 창 닫기 
       */
      closeDialogTotalImage : function() {
          this.dialogTotalImage = false;
      } ,

      /**
       * 장문 전체보기 창 닫기 
       */
      closeDialogTotalMsg : function() {
          this.dialogTotalMsg = false;
      } ,

      /**
       * 메시지 전체 보기 
       */
      setViewAllMsg: function(org_cont_id)  {

          //teleApi.callRestApi({ method: 'post', action: "api/chat/main/lngt-text-inqire/"+org_cont_id }
          teleApi.callRestApi({ method: 'post', action: "api/chat/main/lngt-text/inqire/"+org_cont_id }
          , ret => {

            if(ret.data.code < 0) {
              alert(ret.data.error);
              return;
            }

            let retObj = Object.values(ret.data.data);
            if(retObj != null && retObj.length > 0)  {
                this.msg_all = retObj[0].TEXT;
            }

            this.dialogTotalMsg = true;
          });
      },



    },

    computed: {
      chatMsgtLen: function() {
        return iconv.encode(he.decode(this.CHAT_MSG), "euc-kr").length;
      },      
      getProcessInfo: function(){
        this.processInfo = this.$store.getters['messages/GE_PROCESS_INFO'];

        console.log("process::: " + this.processInfo.toString());
        console.log(this.processInfo);

        return this.processInfo;
      },
      dateRangeText () {
        var newStartDate = this.dates[0];
        var newEndDate = this.dates[1];
        return newStartDate + ' ~ ' + newEndDate;
        // return this.dates.join(' ~ ')
      },
      computedDateFormatted(){
        return this.formatDate(this.date)
      },
      computedDateFormatted2() {
        return `${this.date} ${this.timeStep}`
      },
      chatListIng(){
        return this.chatList.filter(arr => arr.type === 'ing' );
      },
      chatListDone(){
        return this.chatList.filter(arr => arr.type === 'done' );
      },
      chatListAfter(){
        return this.chatList.filter(arr => arr.type === 'after'  || arr.type === 'after countdown');
      },
      chatListWait(){
        return this.chatList.filter(arr => arr.type === 'wait' );
      },
      chatListReseve(){
        return this.chatList.filter(arr => arr.statChat === true );
      },

      //추가
      rowNumScript() {       
        return this.ScriptData.map(
            (ScriptData, index) => ({
            ...ScriptData,
            index: index + 1
        }))
      },
      content() {
        const selectedRow = this.ScriptList[0];
        return selectedRow ? selectedRow.SCRIPT_RMK : "선택 스크립트가 없습니다.";
      },
      subject() {
        const selectedRow = this.ScriptList[0];
        return selectedRow ? selectedRow.SCRIPT_TIT : "선택 스크립트가 없습니다.";
      },
      rowNumLink() {
        return this.LinkData.map(
            (LinkData, index) => ({
            ...LinkData,
            index: index + 1
        }))
      },
      rowNumHistory() {
           console.log("rowNum:::");
           console.log(this.chatHistory);
           console.log(this.HistoryData);
        return this.chatHistory.map(
            (chatHistory, index) => ({
            ...chatHistory,
            index: index + 1
        }))
      },

      
      dataTable3Instance: function() {
        return this.$refs.dataTable3;
      },

      newCommandDesc: function(){
        if(this.NEW_COMMAND == null || this.NEW_COMMAND == ""){
          this.new_command_desc = "";
        }else if(this.new_command_desc != null && this.new_command_desc != ""){
          this.new_command_desc = "("+this.new_command_desc+")";
        }

        return this.new_command_desc;
      },

      addCommandDesc: function(){
        if(this.ADD_COMMAND == null || this.ADD_COMMAND == ""){
          this.add_command_desc = "";
          console.log("in22!!");
        }else if(this.add_command_desc != null && this.add_command_desc != ""){
          this.add_command_desc = "("+this.add_command_desc+")";
          console.log("in333!!");
        }

        return this.add_command_desc;
      }
    },

    created() {

      /****************************************************************************************************************************************************
       * 공통 변수 / 함수 정보 START 
       ***************************************************************************************************************************************************/ 
      
      /** 현재시간 추출 (1/10000 초)  */
      setInterval(() => {            
        this.nowTime = moment();
      }, 10000);

      /****************************************************************************************************************************************************
       * 공통 변수 / 함수 정보 END 
       ****************************************************************************************************************************************************
       ***************************************************************************************************************************************************/

      eventBus.$on('checkSocket', () => {
        this.checkSocket();
      });            
      eventBus.$on('toBottom', () => {
        console.log("toBottom");
        this.toBottomClick();
      });      
      /**
       * 이벤트 호출
       * Stomp Disconnect  
       */ 
      eventBus.$on('chatMainClose', () => {
        this.changeUserStatChatOff(true);
      });

      /**
       * 이벤트 호출
       * Stomp Disconnect  
       */ 
      eventBus.$on('busStompDisconnect', () => {
        this.disconnect();
      });

      /**
       * 상담원 대기 구독 메시지 인입 
       */
      eventBus.$on('busReadyCallback', (message) => {
        this.readyCallback(message);
        this.getTalkList();
        this.toBottomClick();        

      });

      /**
       * 채팅 메시지 인입 
       */
      eventBus.$on('busInoutCallback', (message) => {
        this.inoutCallback(message);
        this.getTalkList();
        this.getTalkInfo();
        this.toBottomClick();        

      });

      /**
       * 채팅 계좌인증인증 결과 수신
       */
      eventBus.$on('busCertCallback', (CUSTOMER_NM) => {
        console.log("Cert Callback!!!!", CUSTOMER_NM);

        this.successAcn(CUSTOMER_NM);

        if(this.USER_NM == CUSTOMER_NM)
        this.getUserAcn();
        this.toBottomClick();        

      });        
      /**
       * 채팅 계좌인증 오류
       */
      eventBus.$on('busCertErrCallback', (CUSTOMER_NM, ERROR_CNT) => {
        console.log("busCertErrCallback!!!!", ERROR_CNT);

        this.failAcn(CUSTOMER_NM, ERROR_CNT); 

      });           
      /**
       * 채팅 로그인 인증 결과 수신
       */
      eventBus.$on('busLoginCallback', (TALK_USER_KEY) => {
        console.log("busLoginCallback Callback!!!!");

        this.successLogin(TALK_USER_KEY);
        // this.selectCustID()
        this.toBottomClick();        

      });       
      /**
       * 채팅 메시지 전송 
       */
      eventBus.$on('userchatMessage', (message) => {
        console.log("message><><><", message);
        this.$store.dispatch("messages/AC_USER_CHAT_MESSAGE", message);
        //chatStore.userchatMessage(message);
        this.toBottomClick();        
      });
      
      this.dataTableOptions.headers = this.ScriptHeaders;
      //this.dataTableOptions.items = this.rowNumScript;
      this.dataTableOptions2.headers = this.LinkHeaders;
      this.dataTableOptions2.items = this.LinkData;
      this.dataTableOptions3.headers = this.HistoryHeaders;
      //this.dataTableOptions3.items = this.rowNumHistory;
    },
    beforeUnmount() {
      window.removeEventListener('beforeunload', this.disconnect());
    },
    async mounted() {
      console.log("10102 mounted")

      window.addEventListener('beforeunload', function(event) {
        this.$store.commit("authStore/MU_CHAT_MODE", false);
      })



      let codeName1 = ["TALK030_NOCACHE"];
      let common_code1 = await this.mixin_common_code_get_all(codeName1, "Y");
      this.USER_STAT = common_code1[0].group_value;
      console.log("this.USER_STAT  ", this.USER_STAT)
      console.log("this.common_code1  ", common_code1)
      let codeName2 = ["TALK006"];
      let common_code2 = await this.mixin_common_code_get_all(codeName2, "Y");
      this.TALK_STAT = [...this.TALK_STAT, ...common_code2[0].group_value];
      
      let codeName3 = ["PLT001"];
      let common_code3 = await this.mixin_common_code_get_all(codeName3, "Y");
      this.CNSL_TY = [...this.CNSL_TY, ...common_code3[0].group_value];

      let codeName4 = ["TALK005"];
      let common_code4 = await this.mixin_common_code_get_all(codeName4, "Y");
      this.CHT_RESULT = common_code4[0].group_value;
      this.CHT_RESULT.splice(2, 1);
      console.log("this.CHT_RESULT  ", this.CHT_RESULT)

      this.CHT_NEWRESULT = this.CHT_RESULT[0].CD;

      // this.dropItemsResult.unshift({ title: "완료"});

      // this.ifCustInfo.CLNT_NO = "00384568";
      window.addEventListener ('unload', this.disconnect());              //this.calculateWindowWidth();
      window.addEventListener("keydown", this.setAvailableRefresh); 
      this.setChatUserInfo();
      this.getTalkList();
      // this.toBottomClick();
      this.initChatListCount();
    },

    watch: {
    }
  };
</script>


<style></style>
